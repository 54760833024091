import React from 'react'
import "./Details.css";
import Work from "../../Assets/Howthiswork.png"
import DetailTab from './DetailTab';
import { NavLink } from "react-router-dom";

function DetailMain() {
  const Heading = {
    color: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
     marginTop: -40
  };
  const company_name = {
    color: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    marginTop: -12
  };
  const button = {
    backgroundColor: " #FFA32E",
    marginBottom: 5
  };
  const others={
    color: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 14
  };
  const head={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 900,
  };
  const Buy={
    backgroundColor: "#FFA32E",
    marginBottom: 5,
    color: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 900,
  };
  const btn_text={
    color: "#fff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 900,
  };
  const round_btn={
    height: 100,
    width: 10,
    marginTop: -80,
    color: "#fff",
   
  }
  return (
    <div>
      <div className="row ">
        <div className="col-md-12 details ">
          <div className="details_one d-flex flex-column justify-content-around p-5">
          <NavLink
          exact
          to="/projects"
          style={{color: "#000", textDecoration: "none"}}
        >
          <button className="btn btn" style={round_btn}><i className="fas fa-chevron-left fa-3x"></i></button>
          </NavLink>
            <div>
              <h1 style={Heading}>Machine Learning 101</h1>
              <h4 style={company_name}>By Languify</h4>
            </div>
            <div>
              <h5 style={others}>Next batch starts from 1st october.</h5>
            </div>
            <div>
              <h6 style={others}>
                *This project has three levels and to earn a certificate <br />{" "}
                you're required to complete all three projects.
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ backgroundColor: "#fff" }}>
        <div className="col-md-8">
          <DetailTab />
        </div>
        <div className="col-md-4">
          <div className="d-flex flex-column p-5">
            <h4 style={head}>How this works?</h4>
            <img src={Work} alt="how_it_work" />
            <h6 className='mt-4'>Next batch starts from 1st october.</h6>
            <h4>This project includes:</h4>
            <ul>
              <li>Online video lessons</li>
              <li>Assignments</li>
              <li>12 articles</li>
              <li>15 exercises</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailMain
