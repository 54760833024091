import React, { useEffect, useState } from 'react'
import "../../App.css";

import DashboardIcon from '@material-ui/icons/Dashboard';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import DescriptionIcon from '@mui/icons-material/Description';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import WorkIcon from '@mui/icons-material/Work';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import SchoolIcon from '@mui/icons-material/School';
import API from '../../Routes/api';
import Swal from 'sweetalert2';

function Sidebar() {
    const [ResumeBuilderState,setResumeBuilderState]=useState({title:"",icon:"",link:"",})
    const [MenuStatus,setMenuStatus]=useState(false);
    const [ProfileStatus,setProfileStatus]=useState(false);
    useEffect(()=>{
        ResumeBuildercall()
        checkSidebar()
    },[])
    const checkSidebar=async()=>{
        await API.get('auth/user').then(res=>{
            if(res.data.data[0].other_details.password_status===0){
                setMenuStatus(true)
            }else if(res.data.data[0].other_details.profile_status===0){
                setMenuStatus(true)
                setProfileStatus(true)
            }else{
                setMenuStatus(false)
            }
        })
      }     
    const ResumeBuildercall = async() =>{
        var dataObject;
        await API.get('resume/check').then(res=>{
            
            if(res.data.data === false){ 
                dataObject = {
                    title: "Resume Builder",
                    icon: < SchoolIcon /> ,
                    link: "/resume",
                }
            }else{
                    dataObject ={title:"",icon:"",link:""}
            }
        }).catch(err=>{
            if(err.response.status===401){
                window.location.href=process.env.REACT_APP_HOME_URL
            }
        })
        //console.log(dataObject)
        setResumeBuilderState(dataObject)
    }

    const ResumeBuilder = ResumeBuilderState;   
    function navigate(href, newTab) {
        var a = document.createElement('a');
        a.href = href;
        if (newTab) {
           a.setAttribute('target', '_blank');
        }
        a.click();
     }
    function redirectUser(value){
        if(MenuStatus === false){
            if(value.title === "Resume Builder"){
                //window.open('/resume', '_blank' )
                API.get('resume/user').then(res=>{  
                    console.log(res.data.data.resume_redirection_url)          
                    if(res.data.data.profile_pic_status === 200){ 
                        navigate(res.data.data.resume_redirection_url,true)
                    }else if(res.data.data.profile_pic_status === 404){
                        localStorage.setItem('redirect_to_resume',res.data.data.profile_pic_set_status)
                        localStorage.setItem('redirect_to_resume_url',res.data.data.resume_redirection_url)
                        Swal.fire({
                            title: "Profile Picture not Set",
                            text: res.data.data.profile_pic_message,
                            icon: 'warning',
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            window.location.href="/profile";
                        })
                    }
                });
            }else{
                window.location.pathname = value.link
            }
        }else{
            if(ProfileStatus === true){
                var err_message = "Please Update your Profile to Continue!"
            }else{
                var err_message = "Please Update your Password to Continue!"
            }
            Swal.fire({
                title: "Incomplete Profile",
                text: err_message,
                icon: 'warning',
                confirmButtonText: 'OK'
            })
        }
    }

    
    
    
    const Sidebardata = [
        {
            title: "Dashboard",
            icon: < DashboardIcon / > ,
            link: "/dashboard",
        },  
        { 
            title: "My Projects",
            icon: < ImportContactsIcon / > ,
            link: "/projects",
        },  
        {
            title: "Queries",
            icon: < HelpCenterIcon / > ,
            link: "/queries",
        }, 
        {
            title: "Internships",
            icon: < WorkIcon / > ,
            link: "/intern",
        },    
        {
            title: "Certificate",
            icon: < CardMembershipOutlinedIcon / > ,
            link: "/certificate",
        },
        {
            title: "LMS",
            icon: < SchoolIcon /> ,
            link: "/lms",
        },    
        ResumeBuilder
    ]
    
    return (
        <div className="Sidebar">
            <ul className="SidebarList">
            {Sidebardata.map((val, key)=>
            {
                
                return (
                    val?.title !=='' && val?.title !== undefined?
                    <li className="row" id={window.location.pathname === val.link ? "active" : ""} key={key} onClick={()=>redirectUser(val)}  >
                    
                        <div id="icon">{val.icon}</div> 
                        <div id="title">
                            {val.title}
                        </div>
                    </li>
                    :''
                );
            }
            )}
            </ul>
        </div>
        );
    
}

export default Sidebar
