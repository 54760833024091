import React, { useEffect,useState } from "react";
import $ from "jquery";
import swal from "sweetalert2";
import "./Resume_builder.css";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import Logo from "../../Assets/img/1Stop_logo_New_Png.png";
import Blue from "../../Assets/img/blue.jpeg";
import Brown from "../../Assets/img/brown.jpeg";
import Orange from "../../Assets/img/orange.jpeg";
import Profile from "../../Assets/img/profile_image.png";
import bullet from "../../Assets/img/plain_white_badge.png";
import star from "../../Assets/img/star.png";
import Address from "../../Assets/img/207-2072812_office-address-symbol-png-location-icon-png-white.png";
import Number from "../../Assets/img/phone-icon-white-png-6.jpg";
import Email from "../../Assets/img/fd4ca36a.png";
import Experience from "../../Assets/img/Icon-1.png";
import Dot from "../../Assets/img/blue-dot-hi.png";
import Education from "../../Assets/img/Icon-2.png";
import Project from "../../Assets/img/icon-4.png";
import API from '../../Routes/api';
import moment from 'moment';
import { useIsRTL } from "react-bootstrap/esm/ThemeProvider";
import Swal from "sweetalert2";
import getCroppedImg from "../Profile/cropImage";

function ResumeContent() {
    const [user,setUsers]               =   useState([])
    const [skillStore,setSkillStore]    =   useState();
    const [internships,setInternships]  =   useState([]);
    const [projects,setProjects]        =   useState([]);
    const [skills,setSkills]            =   useState([]);
    const [imgData, setImgData]         =   useState(null);
    const [skillRepeater,setSkillRepeater] = useState([]);
    const [skillRepeaterIndex,setSkillRepeaterIndex] = useState(0);
    const [template, setTemplate] = useState();
    const [step,setStep] = useState();
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage]           = useState(null)
    useEffect(() => {
        fetchUsers();
        loadInputLength();
        addSkillRepeater();
        checkProfilePic();
    }, [])
    const checkProfilePic = () => {
        API.get('auth/user').then(res=>{
            console.log()
            if(res.data.data[0].other_details.profile_pic===null || res.data.data[0].other_details.profile_pic === undefined){
                Swal.fire({
                    title: "Profile Picture Required",
                    text: "You have not uploaded your Profile Pic, which will be used as the Image for Resume, Please Upload Image and Try Again",
                    icon: 'Warning',
                    confirmButtonText: 'Proceed to Upload'
                }).then((result) => {
                    window.location.href="/profile";
                })
            }
        })
    }
    const fetchUsers=()=>{
        API.get('resume/user').then(res=>{
            setUsers(res.data.data);
            setInternships(res.data.data.internships)
            setProjects(res.data.data.projects)
            setSkills(res.data.data.skills)
            setTemplate(res.data.data.template_id)
            setStep(res.data.data.step)
            triggerLinkClick(res.data.data.step);
            passSkillStateForGeneration(res.data.data.skills)
            setTemplateOnLoad(res.data.data.template_id);
            loadCroppedImage(res.data.data.profile_picture,JSON.parse(res.data.data.picture_attributes));             
            //setImgData(process.env.REACT_APP_PUBLIC_USER_URL+res.data.data[0].other_details.profile_pic);
        })
       
    }  

    const loadCroppedImage = async(profile_pic,pixel_details)=>{
        const croppedImage = await getCroppedImg(profile_pic,pixel_details);
        console.log('done', { croppedImage })
        setCroppedImage(croppedImage)
    }

    const loadInputLength = () => {
        $(".calculateLength").each(function(){
            var countid = $(this).data('countid')
            var valueLength =$(this).val().length !== undefined && $(this).val().length !== null ? $(this).val().length : 0;
            $("#"+countid).text(valueLength)
        })
    }

    const calculateInputLength = (e) => {
        document.getElementById(e.target.getAttribute("data-countid")).innerText=e.target.value.length   
    }

    const addSkillRepeater = (e) =>{
      //  console.log(generateSkillHTML())
        var html = generateSkillHTML();
        setSkillRepeater(
            prevState => [
                ...prevState,
                html
            ]
        )
      ////  console.log(skillRepeater)
    }
 
    const handleAchievementShow = () =>{
        $(".achivements_div_p").css({"display":"block"})
    }
    const handleInput = (e) =>{
        setUsers(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        );
    }

    const handleObjective = (e) =>{
        setUsers(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        );
    }

    const handleInternshipExperience = (e) =>{
        let internship_Array = [...internships]
        internship_Array[e.target.getAttribute('data-index_id')]={...internship_Array[e.target.getAttribute('data-index_id')],[e.target.name]:e.target.value}
        setInternships(internship_Array);
    }
    
    const handleEducationDescription   = (e) =>{
        setUsers(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        );
    }

    const handleSkills   = (e) =>{
        var skills_title = '';
        var skills_level = '';
        if(e.target.name === 'skills_level'){
            skills_title = document.getElementById("skills_title_id_"+e.target.getAttribute('data-index')).value
            skills_level = parseInt(e.target.value);
        }else if(e.target.name === 'skills_title'){
            skills_title = parseInt(e.target.value);
            skills_level = document.getElementById("skills_level_id_"+e.target.getAttribute('data-index')).value;
        }
       
        if(skills_level!=='' && skills_title!== NaN){
            var skills_data = {"skills_level":skills_level,"skills_title":skills_title};
            setSkills(
                prevState => [
                    ...prevState,
                    skills_data
                ]
            );
        }
    }
    
    function getSkillsStar(skills_level){
        var htmlArr =[];
        for(var i=1;i<=skills_level;i++){
            htmlArr.push(appendSkillsStar())
        }
        return htmlArr;
    }

    function appendSkillsStar(){
        return (<span className="skill-rating">&#9733;</span>)
        //return (<img src={star} className="skill-rating"alt="" width="10px"/>)
    }

    const handleProjects   = (e) =>{
        var selected_project = e.target.getAttribute('data-project_title');
        let project_Array = [...projects]
        if(e.currentTarget.checked === true){
            project_Array[e.target.getAttribute('data-project_index')]['projects_worked_on']=[...project_Array[e.target.getAttribute('data-project_index')]['projects_worked_on'],selected_project]
        }else if(e.currentTarget.checked === false){
            var splice_array = project_Array[e.target.getAttribute('data-project_index')]['projects_worked_on'];
            const index = splice_array.indexOf(e.target.getAttribute('data-project_title'));
            if (index > -1) { // only splice array when item is found
                splice_array.splice(index,1)
            }
           
            project_Array[e.target.getAttribute('data-project_index')]['projects_worked_on']= splice_array;
        }
        setProjects(project_Array);
       
    }
  //  console.log(projects)
    const handleAchievements   = (e) =>{
        setUsers(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        );
        handleAchievementShow()
    }

    const setTemplateOnLoad = (template_id) =>{
        if(template_id){
            document.getElementById("template_"+template_id).classList.add("active");
            document.getElementById("htmlDiv").classList.add("theme-"+template_id);
        }
    }

    
    const handleTemplate = (e) =>{
        var value = e.target.value;
        if(template){
            document.getElementById("template_"+template).classList.remove("active");
            document.getElementById("htmlDiv").classList.remove("theme-"+template);
            document.getElementById("template_"+value).classList.add("active");
            document.getElementById("htmlDiv").classList.add("theme-"+value);
            setTemplate(value);
        }else{
            document.getElementById("template_"+value).classList.add("active");
            document.getElementById("htmlDiv").classList.add("theme-"+value);
            setTemplate(value);
        }
    }
    const passSkillStateForGeneration = (skilldata) => {
        var skillRepeaterArray = [];
        if(skilldata.length>0){
            skilldata.map((data,index)=>{
                skillRepeaterArray.push(generateSkillHTMLfromState(data,index))
            })
            setSkillRepeater(skillRepeaterArray)
        }
    }
    const generateSkillHTMLfromState = (data,stateIndex) => {
        var index = stateIndex
        // var index = parseInt(presentIndex)+1;
        setSkillRepeaterIndex(index);
        return(
            {
                index:index,
                html:<div className="row js_skills_clone_div" id={"skills_repeater_div_"+index}>
                        <div class="col-6">
                            <input type="text" name={"skills_title"} className="js_input form-control js_skills_title" id={"skills_title_id_"+index} data-index={index} data-key="title" maxlength="20" onChange={handleSkills} value={data.skills_title}/>
                        </div>
                        <div className="col-5">
                            <select name={"skills_level"} className="js_input form-control js_skills_level" id={"skills_level_id_"+index} data-key="level" data-index={index}onChange={handleSkills} value={data.skills_level}>
                                <option value="">select</option>
                                <option value="2">Beginner</option>
                                <option value="3">Intermediate</option>
                                <option value="4">Advanced</option>
                                <option value="5">Expert</option>
                            </select>
                        </div>
                        <div className="col-1">
                            <label className="text-danger js_skill_remove remove_appended_skill" title="Remove" data-repeater_index={index} onClick={(e)=>removeAppendedSkill(e)}>
                                <i className="fa fa-trash"></i>
                            </label>                    
                        </div>
                    </div>
            });
    }


    const generateSkillHTML = () => {
        var presentIndex=skillRepeaterIndex;
        var index = parseInt(presentIndex)+1;
        setSkillRepeaterIndex(index);
        return(
            {
                index:index,
                html:<div className="row js_skills_clone_div" id={"skills_repeater_div_"+index}>
                        <div class="col-6">
                            <input type="text" name={"skills_title"} className="js_input form-control js_skills_title" id={"skills_title_id_"+index} data-index={index} data-key="title" maxlength="20" onChange={handleSkills}/>
                        </div>
                        <div className="col-5">
                            <select name={"skills_level"} className="js_input form-control js_skills_level" id={"skills_level_id_"+index} data-key="level" data-index={index}onChange={handleSkills}>
                                <option value="">select</option>
                                <option value="2">Beginner</option>
                                <option value="3">Intermediate</option>
                                <option value="4">Advanced</option>
                                <option value="5">Expert</option>
                            </select>
                        </div>
                        <div className="col-1">
                            <label className="text-danger js_skill_remove remove_appended_skill" title="Remove" data-repeater_index={index} onClick={(e)=>removeAppendedSkill(e)}>
                                <i className="fa fa-trash"></i>
                            </label>                    
                        </div>
                    </div>
            });
    }

    const removeAppendedSkill = (e) =>{
        e.preventDefault();
        var selectedRepeaterIndex=parseInt(e.target.parentNode.getAttribute("data-repeater_index"));
        setSkillRepeater(skillRepeater=>skillRepeater.filter(item => item.index !== selectedRepeaterIndex))
        var skillArray = skills
        skillArray.splice(selectedRepeaterIndex, 1);
        setSkills(skillArray);
        
    }

    const triggerLinkClick = (stepToClick) => {
        if(stepToClick===1){
            $("#profile").css({'opacity':'1','display':'block'})
            $("#qualifications").css({'opacity':'0','display':'none'})
            $("#resumetemplate").css({'opacity':'0','display':'none'})
            $(".progress-bar").css({'width':"15%"})
        }else if(stepToClick===2){
            $("#profile").css({'opacity':'0','display':'none'})
            $("#qualifications").css({'opacity':'1','display':'block'})
            $("#resumetemplate").css({'opacity':'0','display':'none'})
            $(".progress-bar").css({'width':"47%"})
        }else if(stepToClick===3){
            $("#profile").css({'opacity':'0','display':'none'})
            $("#qualifications").css({'opacity':'0','display':'none'})
            $("#resumetemplate").css({'opacity':'1','display':'block'})
            $(".progress-bar").css({'width':"100%"})
        }else{
            $("#profile").css({'opacity':'1','display':'block'})
            $("#qualifications").css({'opacity':'0','display':'none'})
            $("#resumetemplate").css({'opacity':'0','display':'none'})
            $(".progress-bar").css({'width':"15%"})
        }
    }

    const handlePageClick = (e) =>{
        var this_page = e.target.getAttribute("data-this_page")
        var previous_page = e.target.getAttribute("data-previous_page")
        var next_page = e.target.getAttribute("data-next_page")
        var progress = e.target.getAttribute("data-progress")+"%";
        var status = false;
        switch(e.target.value){
            case "Next"     :   if(this_page === 'profile'){
                                    status = handleProfileSave()
                                }else if(this_page === 'qualifications'){
                                    //status = true;
                                    status = handleQualificationsSave();
                                }else if(this_page ==='resumetemplate'){
                                    //status = true;
                                    status=handleResumeTemplateSave();
                                }
                                if(status===true){
                                    $("#"+next_page).css({'opacity':'1','display':'block'})
                                    $("#"+this_page).css({'opacity':'0','display':'none'})
                                    $(".progress-bar").css({'width':progress})
                                }
                                break;
            case "Previous" :   if(this_page === 'profile'){
                                    handleProfileSave()
                                }else if(this_page === 'qualifications'){
                                    status = true;
                                    handleQualificationsSave();
                                }else if(this_page ==='resumetemplate'){
                                    status = true;
                                    handleResumeTemplateSave();
                                }
                                if(status===true){
                                    $("#"+previous_page).css({'opacity':'1','display':'block'})
                                    $("#"+this_page).css({'opacity':'0','display':'none'})
                                    $(".progress-bar").css({'width':progress})
                                }                                
                                break;
            case "Finish"   :   handleResumeTemplateSave();
                                break;
            default     :   alert('Invalid Selection');
        }
    }

    const handleProfileSave = () => {
        //e.preventDefault()
        var userdata= {};
        let process = "profile";
        var saveStatus = true;
        userdata['process']=process;
        userdata['user_id']=user.user_id;
        userdata['student_id']=user.student_id;
        userdata['name']=user.name;
        userdata['email']=user.email;
        userdata['phone']=user.phone;
        userdata['address']=user.address;
        const formData = new FormData();
        formData.append('data',JSON.stringify(userdata));
        API.post('resume/user/steps',formData).then(res=>{
            if(res.data.code===200){
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {                    ;
                    return saveStatus = true;
                })
            }
            saveStatus = res.data.code===200?true:'';
            return saveStatus;
        });
        return saveStatus;
    }

    const handleQualificationsSave = () => {
        let process = "qualifications";
        var userdata= {};
        var saveStatus = true;
        userdata['process']=process;
        userdata['user_id']=user.user_id;
        userdata['internships']=internships;
        userdata['projects']=projects;
        userdata['skills']=skills;
        userdata['college_qualification']=user.college_qualification;
        userdata['college_year']=user.college_year;
        userdata['college_name']=user.college_name;
        userdata['college_city']=user.college_city;
        userdata['edu_from_date']=user.edu_from_date;
        userdata['edu_to_date']=user.edu_to_date;
        userdata['objective']=user.objective;
        userdata['edu_description_one']=user.edu_description_one;
        userdata['edu_description_two']=user.edu_description_two;
        userdata['achievement_one']=user.achievement_one;
        userdata['achievement_two']=user.achievement_two;
        const formData = new FormData();
        formData.append('data',JSON.stringify(userdata));
        API.post('resume/user/steps',formData).then(res=>{
            if(res.data.code===200){
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {                    ;
                    return saveStatus = true;
                })
            }
            saveStatus = res.data.code===200?true:'';
            return saveStatus;
        });
        return saveStatus;
    }
    const handleResumeTemplateSave = () => {
        let process = "resumeTemplate";
        var userdata= {};
        var saveStatus = true;
        userdata['process']=process;
        userdata['user_id']=user.user_id;
        userdata['template_id']=template;
        userdata['declare']=user.declare;
        const formData = new FormData();
        formData.append('data',JSON.stringify(userdata));
        API.post('resume/user/steps',formData).then(res=>{
            if(res.data.code===200){
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {                    ;
                    return saveStatus = true;
                })
            }
            saveStatus = res.data.code===200?true:'';
            return saveStatus;
        });
        handleSaveGenerateResume()
        return saveStatus;
    }

    const handleSaveGenerateResume = () => {
        let process = "generateResume";
        var userdata= {};
        var saveStatus = true;
        userdata['process']=process;
        userdata['user_id']=user.user_id;
        const formData = new FormData();
        formData.append('data',JSON.stringify(userdata));
        API.post('resume/generate',formData).then(res=>{
            if(res.data.code===200){
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {                    
                    return saveStatus = true;
                })
            }
            saveStatus = res.data.code===200?true:'';
            return saveStatus;
        });
    }

    
  return (
    <div>
    <div className="page-content-wrapper">
        <div className="page-content">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-6 position-relative"></div>
                    <div className="col-6">
                        <a href={user.resume_file} id="js_save_pdf"  type="button" className="btn btn-primary pull-right float-end  mt-3 " disabled={ user.resume_file!=='' && user.resume_file===null ? true:false } download>
                            <i className="fa fa-save"></i> Save As PDF
                        </a>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-5 text-center p-0 mt-1 mb-2 resume-builder-left">
                        <div className="card px-0 pt-2 pb-0 mb-3">
                            <h2 id="heading">Resume Builder</h2>
                            <p style={{ fontSize: 13 }}>
                                Fill all form fields to go to next step
                            </p>
                            <form action="https://www.1stop.ai/dashboard/save-resume" className="panel" id="msform" method="post">                                
                                <input type="hidden" name="student_id" id="student_id" value="12718" />
                                <input type="hidden" name="user_id" id="user_id" value="12718" />
                                <input type="hidden" name="step" id="step" value="1" />
                                <input type="hidden" id="saved_step" value="3" />
                                {/*
                                <!-- progressbar --> */}
                                <ul id="progressbar">
                                    <li className="active" id="personal">
                                        <strong>Personal</strong>
                                    </li>
                                    <li className="active" id="academic">
                                        <strong>Academic</strong>
                                    </li>
                                    <li className="active" id="template">
                                        <strong>Template</strong>
                                    </li>
                                </ul>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ width: "15%"}}></div>
                                </div>{" "}
                                <br />
                               
                                <fieldset style={{ opacity: 1 }} id="profile">
                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7" style={{ display: "flex" }}>
                                                <h2 className="fs-title" style={{ marginRight: "5px" }}>
                                                    Personal Information:
                                                </h2>
                                            </div>

                                            <div className="col-5">
                                                <h2 className="steps">Step 1/3</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 js_validation">
                                                <label className="fieldlabels form-label">
                                                    Full Name:<span style={{ color: "red" }}>*</span>
                                                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Go to profile page to edit" aria-label="Go to profile page to edit">
                                                        <i className="fa fa-question-circle"></i>
                                                    </a>
                                                </label>
                                                <input type="text" name="name" id="name" className="js_input form-control" value={user.name} readonly="" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 js_validation">
                                                <label className="fieldlabels form-label">
                                                    Email:<span style={{ color: "red" }}>*</span>
                                                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Go to profile page to edit" aria-label="Go to profile page to edit">
                                                        <i className="fa fa-question-circle"></i>
                                                    </a>
                                                </label>
                                                <input type="email" name="email" id="email" className="js_input form-control" value={user.email} readonly="" />
                                            </div>
                                            <div className="col-6 js_validation">
                                                <label className="fieldlabels form-label">
                                                    Phone Number:
                                                    <span style={{ color: "red" }}>*</span>
                                                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Go to profile page to edit" aria-label="Go to profile page to edit">
                                                        <i className="fa fa-question-circle"></i>
                                                    </a>
                                                </label>
                                                <input type="text" name="phone" id="phone" className="js_input form-control" value={user.phone} readonly="" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 js_validation">
                                                <label className="fieldlabels form-label">
                                                    Address:<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <textarea name="address" id="address" rows="3" className="js_input_text form-control mb-0 calculateLength"  onblur="textChange(this)" maxlength="250" onLoad={calculateInputLength} data-countid='address_count' onKeyUp={calculateInputLength} onChange={handleInput} value={user.address}/>
                                                <div className="pull-right float-end ">
                                                    <span id="address_count" className="current value_counters "></span>
                                                    <span className="maximum value_counters">/ 250</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="button" className="js-save-btn action-button" data-progress="47" data-this_page="profile" data-next_page="qualifications" value="Next" onClick={handlePageClick}/>
                                </fieldset>
                                <fieldset style={{ opacity: 0, display: "none" , position: "relative" , }} id="qualifications">
                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7" style={{ display: "flex" }}>
                                                <h2 className="fs-title" style={{ marginRight: "5px" }}>
                                                    Objective/Key Competences:
                                                </h2>
                                            </div>
                                            <div className="col-5">
                                                <h2 className="steps">Step 2/3</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 js_validation">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label className="fieldlabels form-label">
                                                            Objective:
                                                            <span style={{ color: "red" }}>*</span>
                                                        </label>
                                                        <button type="button" className="btn btn-warning pull-right float-end " data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ padding: "4px 10px" }}>
                                                            Tips
                                                        </button>
                                                    </div>
                                                </div>
                                                <textarea name="objective" id="objective" rows="3" className="js_input_text form-control mb-0 calculateLength"  maxlength="250"  data-countid='objective_count' onKeyUp={calculateInputLength} onChange={handleObjective} value={user.objective}/>
                                                <div className="pull-right float-end ">
                                                    <span id="objective_count" className="current value_counters"></span>
                                                    <span className="maximum value_counters">/ 250</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7" style={{ display: "flex" }}>
                                                <h2 className="fs-title" style={{ marginRight: "5px" }}>
                                                    Internship Experience:
                                                </h2>
                                            </div>
                                            <div className="col-5">
                                                <button type="button" className="btn btn-warning pull-right float-end " data-bs-toggle="modal" data-bs-target="#exampleModalintern" style={{padding: "4px 10px" }}>Tips</button>
                                            </div>
                                        </div>
                                        {internships?.map((data,index)=>{
                                            return(
                                                <div className="js_internship" data-key="0">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="fieldlabels form-label">
                                                                Company/Organisation Name:
                                                                <span style={{ color: "red" }}>*</span>
                                                                <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Contact support to edit" aria-label="Contact support to edit">
                                                                    <i className="fa fa-question-circle"></i>
                                                                </a>
                                                            </label>
                                                            <input type="text" name="company_name" id="company_name_0" value={data.company_name} readonly="" />
                                                            <input type="hidden" name="internship[enrollment_id][0]" id="internship_enrollment_id_0" value="13178" />
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="fieldlabels form-label">
                                                                Internship/Job Profile:
                                                                <span style={{ color: "red" }}>*</span>
                                                                <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Contact support to edit" aria-label="Contact support to edit">
                                                                    <i className="fa fa-question-circle"></i>
                                                                </a>
                                                            </label>
                                                            <input type="text" placeholder="Ex: Web Development" name="course_title" id="course_title_0" value={data.course_title} readonly="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <label className="fieldlabels form-label">
                                                                From:<span style={{ color: "red" }}>*</span>
                                                                <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Contact support to edit" aria-label="Contact support to edit">
                                                                    <i className="fa fa-question-circle"></i>
                                                                </a>
                                                            </label>
                                                            <input type="text" placeholder="dd-mm-yyyy" name="batch_start[0]" id="internship_batch_start_0" value={moment(data.internship_start_date).format('DD/MM/YYYY')} readonly="" />
                                                            <input type="hidden" id="internship_batch_start_format_0" value="Jan-2022" />
                                                        </div>
                                                        <div className="col-6">
                                                            <label className="fieldlabels form-label">
                                                                To:<span style={{ color: "red" }}>*</span>
                                                                <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Contact support to edit" aria-label="Contact support to edit">
                                                                    <i className="fa fa-question-circle"></i>
                                                                </a>
                                                            </label>
                                                            <input type="text" placeholder="dd-mm-yyyy" name="batch_end[0]" id="internship_batch_end_0" value={moment(data.internship_end_date).format('DD/MM/YYYY')} readonly="" />
                                                            <input type="hidden" id="internship_batch_end_format_0" value="Mar-2022" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <label className="fieldlabels form-label">Description:</label>{" "}
                                                            <textarea rows="3" name="internship_description" className="js_input_text form-control mb-0 calculateLength" maxlength="120" data-countid='internship_job_description' data-index_id={index}  onKeyUp={calculateInputLength} onChange={handleInternshipExperience} value={data.internship_description}/>
                                                            <div className="internship_description_0_count pull-right float-end ">
                                                                <span id="internship_job_description" className="current value_counters">87</span>
                                                                <span className="maximum value_counters">/ 120</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row internship_roles_0 js_validation">
                                                        <div id="internship_roles_0" className="mb-3">
                                                            <div className="col-12">
                                                                <label className="fieldlabels form-label">
                                                                    Roles &amp; Responsiblities:
                                                                    <span style={{ color: "red" }}>*</span>
                                                                </label>
                                                                <span className="fw-light fs-6">
                                                                    &nbsp;At least one mandatory!
                                                                </span>
                                                            </div>
                                                            <div className="col-12 js_roles_clone_0">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <input type="text" placeholder="max 65 letters/point" name="internship_roles_and_responsibilities_one" className="js_input form-control js_role mb-0 calculateLength" data-index_id={index}  maxlength="65" data-countid='internship_job_roles_one' value={data.internship_roles_and_responsibilities_one}   onChange={handleInternshipExperience} onKeyUp={calculateInputLength} />
                                                                        <div className="internship_roles_0_0_count pull-right float-end ">
                                                                            <span id="internship_job_roles_one" className="current value_counters"></span>
                                                                            <span className="maximum value_counters">/ 65</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 js_roles_clone_0">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <input type="text" placeholder="max 65 letters/point" name="internship_roles_and_responsibilities_two" id="internship_roles_0_1" className="js_input form-control js_role mb-0 calculateLength"  maxlength="65" onkeyup="updateCount(&#39;internship_roles_0_1&#39;, this.value)" data-countid='internship_job_roles_two' data-index_id={index} value={data.internship_roles_and_responsibilities_two}   onChange={handleInternshipExperience} onKeyUp={calculateInputLength} />
                                                                        <div className="internship_roles_0_1_count pull-right float-end ">
                                                                            <span id="internship_job_roles_two" className="current value_counters"></span>
                                                                            <span className="maximum value_counters">/ 65</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            )})}
                                    </div>

                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7" style={{ display: "flex" }}>
                                                <h2 className="fs-title" style={{ marginRight: "5px" }}>
                                                    Education &amp; Qualifications:
                                                </h2>
                                            </div>
                                            <div className="col-5">
                                                <button type="button" className="btn btn-warning pull-right float-end " data-bs-toggle="modal" data-bs-target="#exampleModaledu" style={{padding: "4px 10px" }}>
                                                    Tips
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 js_validation">
                                                <label className="fieldlabels form-label">
                                                    Highest Qualification:
                                                    <span style={{ color: "red" }}>*</span>
                                                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Go to profile page to edit" aria-label="Go to profile page to edit">
                                                        <i className="fa fa-question-circle"></i>
                                                    </a>{" "}
                                                </label>
                                                <input type="text" placeholder="Ex: B.Tech-CSE" name="grad_subject" id="grad_subject" value={user.college_qualification} className="js_input form-control" readonly="" />
                                            </div>

                                            <div className="col-6">
                                                <label className="fieldlabels form-label">
                                                    Year:
                                                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Go to profile page to edit" aria-label="Go to profile page to edit">
                                                        <i className="fa fa-question-circle"></i>
                                                    </a>{" "}
                                                </label>
                                                <input type="text" placeholder="year" name="grad_year" id="grad_year" value={user.college_year} className="js_input form-control" readonly="" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 js_validation">
                                                <label className="fieldlabels form-label">
                                                    College Name:
                                                    <span style={{ color: "red" }}>*</span>
                                                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Go to profile page to edit" aria-label="Go to profile page to edit">
                                                        <i className="fa fa-question-circle"></i>
                                                    </a>{" "}
                                                </label>
                                                <input type="text" placeholder="Ex: College Name" name="college_name" id="college_name" value={user.college_name} className="js_input form-control" readonly="" />
                                            </div>
                                            <div className="col-6 js_validation">
                                                <label className="fieldlabels form-label">
                                                    College City:
                                                    <span style={{ color: "red" }}>*</span>
                                                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="right" title="" className="" data-bs-original-title="Go to profile page to edit" aria-label="Go to profile page to edit">
                                                        <i className="fa fa-question-circle"></i>
                                                    </a>{" "}
                                                </label>
                                                <input type="text" placeholder="Ex: College City" name="college_city" id="college_city" value={user.college_city} className="js_input form-control" readonly="" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 js_validation date" data-date-format="d-M-yyyy">
                                                <label className="fieldlabels form-label">
                                                    From:<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input type="date" placeholder="dd-mm-yyyy" name="edu_from_date" id="edu_from_date" value={user.edu_from_date} className="date_change" onChange={handleInput}/>
                                                <input type="hidden" id="edu_from_date_format" value="Jul-2019" className="js_input form-control" />
                                            </div>
                                            <div className="col-6 js_validation date" data-date-format="d-M-yyyy">
                                                <label className="fieldlabels form-label">
                                                    To:<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input type="date" placeholder="dd-mm-yyyy" name="edu_to_date" id="edu_to_date" value={user.edu_to_date} className="date_change" onChange={handleInput}/>
                                                <input type="hidden" id="edu_to_date_format" value="Jun-2023" className="js_input form-control" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-10">
                                                <label className="fieldlabels form-label">
                                                    Description:
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <span className="fw-light fs-6">
                                                    &nbsp;At least one mandatory!
                                                </span>
                                            </div>
                                        </div>
                                        <div className="js_validation mb-3">
                                            <div id="edu_description">
                                                <div className="row edu_desc_clone_div">
                                                    <div className="col-12">
                                                        <input type="text" placeholder="Ex-current value_counters CGPA/Percentage - 9.2/96%" name="edu_description_one"  className="js_input form-control edu_description mb-0 calculateLength" id="edu_description_0" maxlength="65" onkeyup="updateCount(&#39;edu_description_0&#39;, this.value)" data-countid='education_qualification_description_one' onKeyUp={calculateInputLength} onChange={handleEducationDescription} value={user.edu_description_one}/>
                                                        <div className="edu_description_0_count pull-right float-end ">
                                                            <span id="education_qualification_description_one" className="current value_counters">59</span>
                                                            <span className="maximum value_counters">/ 65</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row edu_desc_clone_div">
                                                    <div className="col-12">
                                                        <input type="text" placeholder="Ex-I was the vice-president of Rotaract Club" name="edu_description_two"  className="js_input form-control edu_description mb-0 calculateLength" id="edu_description_1" maxlength="65" onkeyup="updateCount(&#39;edu_description_1&#39;, this.value)" data-countid='education_qualification_description_two' onKeyUp={calculateInputLength} onChange={handleEducationDescription} value={user.edu_description_two}/>
                                                        <div className="edu_description_1_count pull-right float-end ">
                                                            <span id="education_qualification_description_two" className="current value_counters">63</span>
                                                            <span className="maximum value_counters">/ 65</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7" style={{ display: "flex" }}>
                                                <h2 className="fs-title" style={{ marginRight: "5px" }}>  Skills: </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <label className="fieldlabels form-label">Technical Skills:</label>
                                            </div>
                                            <div className="col-6">
                                                <label className="fieldlabels form-label">Level: </label>
                                            </div>
                                        </div>
                                        <input type="hidden" value={skillRepeaterIndex} id="skills_repeater_counter"/>
                                        <div id="skills_container">        
                                            {skillRepeater.map((val)=>{
                                                return val.html
                                            })}
                                        </div>
                                    </div>
                                    <a href="javascript:void(0);" className="js_add_skills fs-5 fw-dark" data-limit="2"  onClick={addSkillRepeater}> + Add More</a>

                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-9" style={{ display: "flex" }}>
                                                <h2 className="fs-title" style={{ marginRight: "5px" }}>
                                                    Projects:
                                                </h2>
                                                <span className="fw-light fs-6">
                                                    Select at least one project to add in Resume
                                                </span>
                                            </div>
                                            <div className="col-3">
                                                <button type="button" className="btn btn-warning pull-right float-end " data-bs-toggle="modal" data-bs-target="#exampleModalpro" style={{ padding: "4px 10px" }}>
                                                    Tips
                                                </button>
                                            </div>
                                        </div>
                                        {   projects?.map((data,project_index)=>{
                                            return(
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label className="fieldlabels form-label">
                                                            {data.course_title} by {data.company_name}
                                                            ({moment(data.start_date).format('DD-MM-YYYY')} to {moment(data.end_date).format('DD/MM/YYYY')})
                                                        </label>
                                                    </div>
                                                    <div className="col-12 js_projects_cat">
                                                        <ul className="js_projects">
                                                            {data.project_details?.map((project,index)=>{
                                                                return(
                                                                    <li className="fieldlabels form-label">
                                                                        
                                                                        <input type="checkbox" name="projects[]" value="61" className="checkbox js_project_id_61 js_checkbox" data-project_index={project_index} data-index_id={index} data-project_id={project.id} onChange={handleProjects} data-project_title={project.project_title} checked={data.projects_worked_on?.includes(project.project_title)===true?'checked':''}/>
                                                                        <label for="project_id_61" className="display-inline">
                                                                            {project.project_title}
                                                                        </label>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7" style={{ display: "flex" }}>
                                                <h2 className="fs-title" style={{ marginRight: "5px" }}>
                                                    Achievements:
                                                </h2>
                                            </div>
                                            <div className="col-5">
                                                <button type="button" className="btn btn-warning pull-right float-end "
                                                    data-bs-toggle="modal" data-bs-target="#exampleModalachi" style={{
                                                    padding: "4px 10px" }}>
                                                    Tips
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="fieldlabels form-label">
                                                    Your Achievements:
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row js_achievements_clone_div">
                                            <div className="col-12">
                                                <input type="text" name="achievement_one" className="js_input form-control js_achievements mb-0 calculateLength"id="achievements_0" maxlength="65" placeholder="Ex-Won the first prize in coding competition ( IIT Bomaby )" onkeyup="updateCount(&#39;achievements_0&#39;, this.value)" data-countid='achievement_description_one' onKeyUp={calculateInputLength} value={user.achievement_one} onChange={handleAchievements}/>
                                                <div className="achievements_0_count pull-right float-end ">
                                                    <span id="achievement_description_one" className="current value_counters">0</span>
                                                    <span className="maximum value_counters">/ 65</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row js_achievements_clone_div">
                                            <div className="col-12">
                                                <input type="text" name="achievement_two" className="js_input form-control js_achievements mb-0 calculateLength" id="achievements_1" maxlength="65" placeholder="Ex-I won the title of Campus Ambassador out of 6 students - 1STOP" onkeyup="updateCount(&#39;achievements_1&#39;, this.value)" data-countid='achievement_description_two' onKeyUp={calculateInputLength} value={user.achievement_two} onChange={handleAchievements}/>
                                                <div className="achievements_1_count pull-right float-end ">
                                                    <span id="achievement_description_two" className="current value_counters">0</span>
                                                    <span className="maximum value_counters">/ 65</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <input type="button" name="next" data-progress="100" data-this_page="qualifications" data-next_page="resumetemplate" className="js-save-btn action-button" value="Next" onClick={handlePageClick}/>
                                    <input type="button" name="previous" data-progress="15" data-this_page="qualifications" data-previous_page="profile"  className="js-prev-btn action-button-previous" value="Previous" onClick={handlePageClick}/>
                                </fieldset>
                                <fieldset style={{ display: "none" , position: "relative" , opacity: 0, }} id="resumetemplate">
                                    <div className="form-card">
                                        <div className="row">
                                            <div className="col-7">
                                                <h2 className="fs-title">Templates:</h2>
                                            </div>
                                            <div className="col-5">
                                                <h2 className="steps">Step 3/3</h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <a href="javascript:void(0);" className="resumetemplate js_template" id="template_1">
                                                    <label>
                                                        <img src={Blue} alt="" width="80%" />
                                                        <input type="radio" name="template_id" value="1"  className="js_template_id hidden" onClick={handleTemplate}/>
                                                    </label>
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a href="javascript:void(0);" className="resumetemplate js_template " id="template_2">
                                                    <label>
                                                        <img src={Brown} alt="" width="80%" />
                                                        <input type="radio" name="template_id" value="2" className="js_template_id hidden"  onClick={handleTemplate}/>
                                                    </label>
                                                </a>
                                            </div>
                                            <div className="col-4">
                                                <a href="javascript:void(0);" className="resumetemplate js_template " id="template_3">
                                                    <label>
                                                        <img src={Orange} alt="" width="80%" />
                                                        <input type="radio" name="template_id" value="3" className="js_template_id hidden" onClick={handleTemplate} />
                                                    </label>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12 js_validation" style={{ textAlign: "left" , margin: "20px"}}>
                                            <input type="checkbox" name="declare" id="declare" style={{ width: "auto" ,
                                                marginRight: "5px" }} checked={user.checked===0 || user.checked===null?false:true}/>
                                            <label for="declare">
                                                I here by declare that all of above information is
                                                true to my knowledge.
                                            </label>
                                        </div>
                                    </div>

                                    <input type="button" name="next" data-progress="100" data-this_page="resumetemplate" data-next_page="SaveGenerateResume" className="js-save-btn action-button" value="Finish" onClick={handlePageClick}/>
                                    <input type="button" name="previous" data-progress="47" data-this_page="resumetemplate" data-previous_page="qualifications" className="js-prev-btn action-button-previous" value="Previous" onClick={handlePageClick}/>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-7 text-center p-0 mt-1 mb-2">
                        <div className="card px-0 pb-0 mb-3">
                            <div className="table-responsive">
                                <table id="htmlDiv" className="table table-borderless theme-1" //
                                    style={{fontFamily: "Helvetica, Arial, sans-serif" }}>
                                    <tbody style={{ textAlign: "left" }}>
                                        <tr>
                                            <td width="30%" className="para theme-back" style={{ color: "rgb(255, 255, 255)" , paddingLeft: "20px" , backgroundColor: " rgb(2, 32, 108)" , }}>
                                                <div className="proimg profile_picture_p" id="preview-crop-image">
                                                    <img src={croppedImage===null?user.profile_picture:croppedImage} />
                                                </div>
                                                <div>
                                                    <h5 style={{ fontWeight: 800 }}>
                                                        <img src={Address} alt="" width="15px" />{" "}
                                                        Address
                                                    </h5>
                                                    <div>
                                                        <p className="address_p">{user.address}</p>
                                                    </div>
                                                    <div>
                                                        <h5 style={{ fontWeight: 800 }}>
                                                            <img src={Number} alt="" width="20px" /> Phone
                                                            Number
                                                        </h5>
                                                        <p className="phone_p">{user.phone}</p>
                                                    </div>
                                                    <div>
                                                        <h5 style={{ fontWeight: "800" }}>
                                                            <img src={Email} alt="" width="25px" /> Email
                                                            ID
                                                        </h5>
                                                        <p className="email_p">
                                                            {user.email}
                                                        </p>
                                                    </div>
                                                    <div className="achivements_div_p" style={{ display: "none" }}>
                                                        <h5 style={{ fontWeight: 800 }}>
                                                            <img src="./img/431-4310666_achievements-achievement-icon-white-png.png" alt="" width="18px" />{" "}
                                                            Achievements
                                                        </h5>
                                                        <p  style={{width: "170px"}}><img src={bullet} alt="" width="10px" style={{ marginRight: "2px" }} /> {user.achievement_one}</p>
                                                        <p  style={{width: "170px"}}><img src={bullet} alt="" width="10px" style={{ marginRight: "2px" }} /> {user.achievement_two}</p>
                                                        
                                                    </div>
                                                    <div className="skills_div_p">
                                                        <h5 style={{ color: "#ffffff" , fontWeight: 800 }}>
                                                            <i className="fa fa-cogs"></i> Skills
                                                        </h5>
                                                        <ul className="skills_p" style={{ paddingTop: "10px" }}>
                                                            <table width="100%" border="0">
                                                                <tbody>                                                                    
                                                                    { 
                                                                        skills!== null && skills.length>0?skills.map((value)=>{
                                                                            
                                                                            return( 
                                                                                <tr>
                                                                                    <td width="10">
                                                                                        <img src={bullet} alt="" width="10px" style={{ marginRight: "5px" }} />
                                                                                    </td>
                                                                                    <td width="170">
                                                                                        <span className="skills_title_0_p skill_title">
                                                                                            {value.skills_title}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td  style={{ paddingTop: "0px",textAlign:"center",verticalAlign:"middle" }}>
                                                                                        <span className="skills_level_0_p skill_title" style={{ paddingLeft: "8px" }}>
                                                                                            {getSkillsStar(value.skills_level)}                                                                                    
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>    
                                                                            )
                                                                        }):''
                                                                    }   

                                                                </tbody>
                                                            </table>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style={{ paddingLeft: "20px" , width: "70%" , paddingTop: "20px" , paddingBottom: "0px" , }}>
                                                <div>
                                                    <div>
                                                        <h2 className="theme-font" style={{ fontWeight: 800 }}>
                                                            <span className="name_p"> {user.name} </span>
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <p className="objective_p">                                                            
                                                            {user.objective}
                                                        </p>
                                                        ------------------------------------------------
                                                    </div>
                                                    <div>
                                                        <h5 className="theme-font" style={{ fontWeight: 800 }}>
                                                            <img src={Experience} alt="" className="icon-head theme-icon-bg" width="20px" style={{ backgroundColor: "rgb(2, 32, 108)" , }} />
                                                            Internship Experience
                                                        </h5>
                                                        {internships?.map((data)=>{
                                                            return(
                                                                <div className="js_internship_p">
                                                                    <div className="intern-content" style={{ borderLeft: "3px dotted grey" , marginLeft: "16px" , }}>
                                                                        <div className="d-flex">
                                                                            <div className="date_p">
                                                                                {" "}
                                                                                <span style={{ marginLeft: "-21px" }}>
                                                                                    {" "}
                                                                                    <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />
                                                                                </span>
                                                                                <p style={{ width: "60px" , marginTop: "-16px" , }} className="grey-text">
                                                                                    {" "}
                                                                                    {moment(data.internship_start_date).format('MMM-YYYY')} to {moment(data.internship_end_date).format('MMM-YYYY')}
                                                                                </p>
                                                                            </div>
                                                                            <div className="details">
                                                                                <p style={{ color: "black" }} className="subheader">
                                                                                    <b>
                                                                                        {data.course_title}
                                                                                    </b>{" "}
                                                                                    by
                                                                                    <span className="theme-font">
                                                                                        {data.company_name}
                                                                                    </span>
                                                                                </p>
                                                                                <p className="internship_description_0_p inter_about">
                                                                                    {data.internship_description}
                                                                                </p>
                                                                                <p className="js_roles_title_0 subheader">
                                                                                    <b>Roles &amp; Responsiblity</b>
                                                                                </p>
                                                                                <ul className="internship_roles_0_p">
                                                                                    <li className="remove-list-style">
                                                                                        {" "}
                                                                                        <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />{" "}
                                                                                        <span className="internship_roles_0_0_p inter_about">
                                                                                            {data.internship_roles_and_responsibilities_one}
                                                                                        </span>{" "}
                                                                                    </li>
                                                                                    <li className="remove-list-style">
                                                                                        {" "}
                                                                                        <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />{"  "}
                                                                                        <span className="internship_roles_0_1_p inter_about">
                                                                                            {data.internship_roles_and_responsibilities_two}
                                                                                        </span>{" "}
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        {/* ------------------------------------------------ */}
                                                    </div>
                                                    <div>
                                                        <h5 className="theme-font" style={{ fontWeight: 800 }}>
                                                            <img src={Education} alt="" className="icon-head theme-icon-bg" width="20px" style={{ backgroundColor: "rgb(2, 32, 108)" , }} />
                                                            Education &amp; Qualifications
                                                        </h5>
                                                        <div className="education-content" style={{
                                                            borderLeft: "3px dotted grey" , marginLeft: "16px" , }}>
                                                            <div className="d-flex">
                                                                <div className="date_p">
                                                                    <span style={{ marginLeft: "-21px" }}>
                                                                        <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />
                                                                    </span>
                                                                    <p style={{ width: "60px" , marginTop: "-16px" , }} className="grey-text">
                                                                        <span className="edu_from_date_p">
                                                                            {moment(user.edu_from_date).format('MMM-YYYY')}                                                                
                                                                        </span>{" "}
                                                                        to
                                                                        <span className="edu_to_date_p">
                                                                            {moment(user.edu_to_date).format('MMM-YYYY')}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="education-details">
                                                                    <h6 className="grad_subject_p inter_about"> {user.college_qualification} </h6>
                                                                    <span className="college_name_p inter_about"> {user.college_name} </span>
                                                                    <span className="college_city_p inter_about"> {user.college_city} </span>
                                                                    <br />
                                                                    <p className="edu_description_div_p subheader">
                                                                        <b>Description:</b>
                                                                    </p>
                                                                    <ul className="edu_description_p inter_about">
                                                                        <li className="remove-list-style">
                                                                            <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />
                                                                            <span className="edu_description_0_p"> {user.edu_description_one} </span>{" "}
                                                                        </li>
                                                                        <li className="remove-list-style">
                                                                            <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />
                                                                            <span className="edu_description_1_p"> {user.edu_description_two} </span>{" "}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* ------------------------------------------------ */}
                                                    </div>
                                                    <div>
                                                        <h5 className="theme-font" style={{ fontWeight: 800 }}>
                                                            <img src={Project} alt="" className="icon-head theme-icon-bg" width="20px" style={{ backgroundColor: "rgb(2, 32, 108)" , }} />
                                                            Projects
                                                        </h5>
                                                        {projects?.map((project)=>{
                                                            return(
                                                                <div className="projects_p">
                                                                    <div className="intern-content" style={{ borderLeft: "3px dotted grey" , marginLeft: "16px" , }}>
                                                                        <div className="d-flex">
                                                                            <div className="date_p">
                                                                                {" "}
                                                                                <span style={{ marginLeft: "-21px" }} className="grey-text">
                                                                                    {" "}
                                                                                    <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />
                                                                                </span>
                                                                                <p style={{ width: "60px" , marginTop: "-16px" ,}}>
                                                                                {moment(project.start_date).format('MMM-YYYY')} to {moment(project.end_date).format('MMM-YYYY')}
                                                                                </p>
                                                                            </div>
                                                                            <ul className="details">
                                                                                <li className="remove-list-style">
                                                                                    <p style={{ color: "black" }} className="subheader">
                                                                                        {" "}
                                                                                        <b>  {project.course_title} </b>{" "}
                                                                                        by{" "}
                                                                                        <span className="theme-font"> {project.company_name} </span>
                                                                                    </p>
                                                                                    <ul className="spacing">
                                                                                        {project.projects_worked_on?.map((value)=>{
                                                                                            return(
                                                                                                <li className="remove-list-style">{" "}
                                                                                                    <img src={Dot} alt="" width="10px" style={{ marginRight: "5px" }} />
                                                                                                    <span className="projects_61_p inter_about">{value}</span>{" "}
                                                                                                </li>          
                                                                                            )
                                                                                        })}
                                                                                                                                                                  
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Tips
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>
                        1 Describe yourself in short and strong terms by keeping both
                        vacancy and Job/Internship title in mind. Do not use one liners,
                        but try to be as concise as possible.
                    </p>
                    <p>
                        2 Create a good profile by mentioning each of the following at
                        the very least; achievements, qualities, ambitions, goals, and
                        what are you looking for.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade" id="exampleModalintern" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Tips
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>
                        1 Use a correct Internship title for the Internship or Job you
                        are applying for.
                    </p>
                    <p>
                        2 In description add the project you have worked on during the
                        internship and technology which you have used.
                    </p>
                    <p>
                        3 In roles and responsibilities add competences which you
                        developed.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade" id="exampleModaledu" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Tips
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>
                        1 Do not mention primary and secondary schools unless they are
                        your latest education.
                    </p>
                    <p>
                        2 If you are pursuing a course(Eg: BTech-Cse), it can still add
                        a value to your resume.
                    </p>
                    <p>
                        3 Add a description for your course to explain what it entailed
                        to potential employers.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade" id="exampleModalpro" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Tips
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>
                        1 Select the respective domain/projects which you are confident
                        about
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div className="modal fade" id="exampleModalachi" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Tips
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>1 Try to write each Achievements within 60 words.</p>
                    <p>
                        2 At the end of the each achievements mentioned the
                        place/organisation name where you have achieved it (Eg: won 2nd
                        price in hackathon- IIT Bombay)
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
  );
}

export default ResumeContent;
