import React, { useEffect, useState } from 'react';

//import { Form } from 'react-bootstrap';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InterProjectMain from './InternProject/InterProjectMain';
import API from '../../Routes/api';


function InternInprogress() {

  const [course,setCourse] = useState([]);
  const [internships,setInternships]=useState([]);
  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,
  }

  useEffect(() => {
      initiateData();
  }, [])

  const initiateData=async()=>{
      await API.get('course').then(res=>{
          setCourse(res.data.data)  ;
      })
      API.get('internships?course_id=0').then(res=>{
        setInternships(res.data.data);
      })
  }
  const handleCourseChange = async(e) =>{
    var course_id=e.target.value;
    await API.get('internships?course_id='+course_id).then(res=>{
      setInternships(res.data.data);
    })
  }

  return <div>
          <div className="row">
              <div className="col-md-8"><h4 style={heading}>Internship Project</h4></div>
                <div className="col-md-4">
                  <Box sx={{ minWidth: 120 ,maxWidth: 350, marginBottom: 0 }}>
                    <FormControl fullWidth>
                      {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        Domain
                      </InputLabel> */}
                      <NativeSelect
                        defaultValue={30}
                        inputProps={{
                          name: 'mentor',
                          id: 'uncontrolled-native',
                        }}
                        onChange={handleCourseChange}
                      >
                      <option value="0">Select Domain</option>
                      {
                        course.map((val)=>
                        <option value={val.id}>{val.display_title}</option>
                        )
                      }
                      </NativeSelect>
                    </FormControl>
                  </Box>
            </div>
          </div>
          <div className="row p-0">
            <div className="col-md-12">
              {
                internships.length>0
                ?
                internships.map((val) =>
                <InterProjectMain
                  id={val.id}
                  internship_couse_id={val.internship_couse_id}
                  submission_instruction_url={val.submission_instruction_url}
                  batch_id = {val.internship_batch_id}
                  batch_title = {val.title}
                  internship_project = {val.project_details}
                  sub={val.display_title}
                  start={val.internship_start_date}
                  end={val.internship_end_date}
                  enrollment_id={val.course_enrollment_id}
                />)
                :                
                <div className="row">
                  <div className='card' style={{Height:"200px !important"}}>
                    <div className="col-md-12" style={{padding:"70px 0",textAlign:'center'}}>
                      <h5>No Internship Projects Found</h5>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>;
}

export default InternInprogress;
