import React from "react";
import Image from "../../Assets/logo.png";

function TrendingCard(props) {
  function loadProjectDetails(course_slug){
    var URL = process.env.REACT_APP_GUEST_SITE_URL+"project/"+course_slug;
    window.open(URL, '_blank');
  }
  const defaultImage=async(e)=>{
    e.target.onerror = null;
    e.target.src = Image;
    e.target.style= "height: 27px; width: 60px; margin-right: 10px;margin-top:10px";
  }
  const head = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#000",
    fontSize: 20
  };
  const sub = {
    marginTop: -10,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    color: "#220c72"
  };
  return (
    
      <div>
        <a href={process.env.REACT_APP_GUEST_SITE_URL+"project/"+props.course_slug} rel="noreferrer" target="_blank">
          <div className="d-flex flex-row" style={{ paddingBottom: 20 }}>
            <img
              src={props.icon}
              alt="trending"
              style={{ height: 60, width: 60, marginRight: 10 }}
              onError={defaultImage}
            />
            <div>
              <p style={head}>{props.course}</p>
              <h6 style={sub}>{props.company_name}</h6>
            </div>
          </div>
        </a>
      </div>
    
  );
}

export default TrendingCard;
