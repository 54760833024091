import React,{useEffect,useState} from 'react'

import QueriesDashboard from '../Queries/QueriesDashboard';
import Sidebar from '../Sidebar/Sidebar'
import RowOne from './RowOne'
import RowThree from './RowThree'
import RowTwo from './RowTwo';
import StartModal from '../Modal/StartModal';
import TeleModal from '../Modal/TeleModal';
import OfferLetter from './OfferLetter';

// import QueriesDashboard from './../Queries/QueriesDashboard';

function DashboardMain() {

    const [user,setUsers]               =   useState([])

    useEffect(() => {
      var userToken=localStorage.getItem("token");
      if(!userToken){
        setToken(userToken);
      }
    }, [])

    const setToken=(userToken)=>{
      const url = window.location.search;
      const param = new URLSearchParams(url);
      const token = param.get('token')
      if(token!==undefined){
        localStorage.removeItem("token");
      }
      if(userToken === undefined || userToken === undefined ){
        localStorage.setItem("token",token); 
      }else if(userToken!==token){
        localStorage.removeItem("token",token);
        localStorage.setItem("token",token); 
      }
    }      


    const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,
    marginLeft: 20
    
      }
    return (
      <div className="row m-0 p-0">
      <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
    <Sidebar />
    </div>
    <div className="col-10 col-lg-10 col-md-11 col-xs-11">
      <h4 style={heading}>Dashboard</h4>
      <div className="row m-0 p-0">
        <div className="col-md-8">
        <div className="row m-0 p-0">
          <div className="col-md-12 mb-2">
          <RowOne />
          </div>
          <div className="col-md-12 mb-2">
          {/* <QueriesDashboard /> */}
          <OfferLetter />
          </div>
        </div>
        </div>
        <div className="col-md-4 mb-2">
        <RowThree />
        </div>
        <StartModal/>
        <TeleModal/>
      </div>
    </div>
    </div> 
      
    )
}

export default DashboardMain

