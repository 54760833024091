import React from 'react'
import Sidebar from './../Sidebar/Sidebar';
import ResumeContent from './ResumeContent';

function ResumeMain() {
    const heading={
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        paddingTop: 20,
    }
    return (
        <div className="row">
        {/* <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
      <Sidebar />
      </div> */}
      <div className="col-12">
      <h4 style={heading}><ResumeContent/> </h4>
      </div>
      </div>
    )
}

export default ResumeMain
