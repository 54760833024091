import React from 'react'
import Leaderboard from './Leaderboard'
import Progress from './Progress'

function RowOne() {
    return (
        <div>
            <Progress />
            {/* <Leaderboard /> */}
        </div>
    )
}

export default RowOne
