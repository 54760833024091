import React,{useState,useEffect} from "react";
import { Dropdown, Form } from "react-bootstrap";
import Image from "../../Assets/logo.png";
import { ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Status from "./Status";
import "../../App.css";
import StatusModal from "./StatusModal";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import API from '../../Routes/api';
import moment from 'moment';

function ProjectsProgress(props) {
  const [MyProjectData,setProjectData]               =   useState([])
  
  useEffect(() => {
    fetchMyProjects();
  }, [])

  const fetchMyProjects=async()=>{
    await API.get('projects').then(res=>{
        setProjectData(res.data.data)  ;
    })
  }          

  const defaultImage=async(e)=>{
    e.target.onerror = null;
    e.target.src = Image;
    e.target.style= "height: 40px; width: 100px; margin-right: 10px;margin-top:10px";
  }
  
  const head = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
  };
  const list = {
    fontFamily: "Montserrat",
    fontStyle: "bold",
    fontWeight: 400,
  };
  const border = {
    borderLeft: "5px solid black",
    padding: "8px",
    marginLeft: "5px",
  };

  const now = 60;
  const bar = {
    height: 10,
    fontSize: 8,
    width: 250,
    marginBottom: 20,
  };
  const para = {
    height: "90%",
    padding: 10,
    paddingBottom: 0,
  };
  const cont = {
    padding: 12,
    backgroundColor: "#fff",
    margin: 10,
    marginLeft: 0,
    borderRadius: 10,
    width: "100%",
    height: "100%",
    paddingBottom: 40,
  };
  // const head={
  //   fontWeight: "bold",
  //   marginBottom: 5,
  // };
  const sub = {
    fontWeight: 500,
  };
  return (
    MyProjectData.map((val,index) =>
    <div>
      <div className="accordion p-0" id={"accordion_"+index}>
        <div className="accordion-item">
          <h2 className="accordion-header" id={"heading_"+index}>
            <button
              className="accordion-button collapsed"
              type="button"
              data-toggle="collapse"
              data-target={"#collapse_"+index}
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              <div className="d-flex flex-row" style={{ paddingBottom: 20 }}>
                <img
                  src={val.banner_image_link}
                  alt="trending"
                  className="trenImg"
                  style={{ height: 100, width: 100, marginRight: 10 }}
                  onError={defaultImage}
                />
                <div>
                  <h4 style={head} className="project_head"> 
                  {" "}
                  {val.display_title}{" "}
                  <span style={{ color: "#0047AB" }}>by {val.company_name}</span>{" "}
                  </h4>
                  <ProgressBar
                    now={val.project_progress}
                    label={`${val.project_progress}%`}
                    style={bar}
                    className="d-flex flex-column dash_bar"
                  />
                  <div>
                    <h6 className="calendar_project">
                  <span className="calendar_icon">
                  <CalendarTodayIcon />
                  </span>    
                  {val.project_start_date === null?'TBU': moment(val.project_start_date).format('Do MMMM')} - {val.project_start_date === null?'TBU':moment(val.project_end_date).format('Do MMMM')}
                    </h6>
                  </div>
                </div>
              </div>
            </button>
          </h2>
          <div
            id={"collapse_"+index}
            className={index === 0 ?"accordion-collapse collapse show ":"accordion-collapse collapse"}
            aria-labelledby={"heading_"+index}
            data-parent={"#accordion_"+index}
          >
            <div className="accordion-body">
              <div style={cont}>
                <Form>
                  <div className="row" style={para}>
                    {val.project_details.map((data) => {
                         return(                      
                        <div className="col-md-4 mb-4">
                          <Status
                            head={data.project_title}
                            id={data.id}
                            batch_id={val.internship_batch_id}
                            enrollment_id={val.course_enrollment_id}
                            submission_date={data.submission_date}
                            submission_instruction_url={data.submission_instruction_url}
                            score={data.project_files_arr?.score}
                            subdate={data.project_files_arr?.created_at}
                            file_data={data.project_files_arr}
                            projectstatus={data.project_files_arr?.status}
                            approvalmess={data.project_files_arr?.remarks}
                          />
                          {/* <StatusModal
                            name="Approved"
                            status="Your project has been rejected"
                          /> */}
                        </div>                      
                      )}
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  );
}

export default ProjectsProgress;
