import React from 'react'
import { Link } from 'react-router-dom';

function Projects() {
    const head = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        color:"#000"
      };
      const list = {
        fontFamily:"Montserrat",
        fontStyle:"bold",
        fontWeight:400
      };
      const border = {
        borderLeft:"5px solid black",
        
      };
      const box = {
        fontFamily: "Montserrat",
        padding: 10,
        background: "#007BBC4D",
        color:'#000',
        textDecoration: 'none',
        marginRight:10,
        marginLeft:30,
        marginBottom:10
    };
    return (

        <div>
              <div className="accordion" id="accordionExample">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            <div className="d-flex flex-row" style={{ paddingBottom: 20 }}>
            
            <div>
            <h4 style={head}> <i className="fa fa-circle" aria-hidden="true" style={{color: '#FF9308'}}></i> <b>Project 1</b></h4>
            
            </div>
                
               
            </div>
            </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
             <ul style={{listStyle:'none'}}>
                 <li style={list}>
                     <ul style={border}>
                        <li>Online Video Lesson</li>
                        <li>Assignments</li>
                        <li>12 article</li>
                        <li>15 exercises</li>
                        <b>Final Assignment</b>
                     </ul>                    
                 </li> 
             </ul>
             <Link to="/" style={box} ><b>Start Learning</b></Link>                
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            <div className="d-flex flex-row" style={{ paddingBottom: 20 }}>
            
            <div>
            <h4 style={head}> <i className="fa fa-circle" aria-hidden="true" style={{color: '#FF9308'}}></i> <b>Project 2</b></h4>
            
            </div>
            </div>
            </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            <ul style={{listStyle:'none'}}>
                 <li style={list}>
                     <ul style={border}>
                        <li>Online Video Lesson</li>
                        <li>Assignments</li>
                        <li>12 article</li>
                        <li>15 exercises</li>
                        <b>Final Assignment</b>
                     </ul>
                     
                 </li>                 
             </ul>
             <Link to="/" style={box} ><b>Start Learning</b></Link>                
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            <div className="d-flex flex-row" style={{ paddingBottom: 20 }}>
            
            <div>
            <h4 style={head}> <i className="fa fa-circle" aria-hidden="true" style={{color: '#FF9308'}}></i> <b>Project 3</b></h4>
            
            </div>
                
               
            </div>
            </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            <ul style={{listStyle:'none'}}>
                 <li style={list}>
                     <ul style={border}>
                        <li>Online Video Lesson</li>
                        <li>Assignments</li>
                        <li>12 article</li>
                        <li>15 exercises</li>
                        <b>Final Assignment</b>
                     </ul>
                     
                 </li>                 
             </ul>
             <Link to="/" style={box} ><b>Start Learning</b></Link>                

            </div>            </div>
        </div>
        </div> 
        </div>
        
    )
}

export default Projects
