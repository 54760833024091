import React from "react";
import { ProgressBar } from 'react-bootstrap';
import "../../App.css"
import Image from "../../Assets/logo.png"

function Bar(prop) {
  const defaultImage=async(e)=>{
    e.target.onerror = null;
    e.target.src = Image;
    e.target.style= "height: 40px; width: 100px; margin-right: 5px";
  }

  const pic = {
    // paddingRight: 20,
    marginRight: 5,
    height: "100px",
    width: "100px",
  };
  const course = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
  };
  const bar = {
    height: 10,
    fontSize: 8,
    // width: 250,
  };

  return (
    <div className="d-flex flex-row align-items-center" style={{ width: "100%", padding: 8 }}>
      <img src={prop.image} alt="icon" style={pic} onError={defaultImage} className="dash_image"/>
      <div className="col-md-10">
        <div>
          <h4 style={course} className="dash_heading">{prop.heading}</h4>
        </div>
        
        <ProgressBar
          now={prop.now}
          label={`${prop.now}%`}
          style={bar}
          className="d-flex flex-column dash_bar"
          id="progress_dash"
        />
      </div>
    </div>
  );
}

export default Bar;
