import React,{useState,useEffect} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import API from '../../Routes/api';

export default function Transaction() {
  const [transactions,setTransactions]               =  useState([])
  useEffect(() => {
    fetchTransactions();
    
}, [])

const fetchTransactions=async()=>{
    await API.get('auth/user/transactions').then(res=>{
        setTransactions(res.data.data)  ;
    })
}      
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow style={{fontWeight:900}}>
            <TableCell>College Partner</TableCell>
            <TableCell align="center">Course Name</TableCell>
            <TableCell align="center">Duration</TableCell>
            <TableCell align="center">Payment Status</TableCell>
            <TableCell align="center">Transaction No.</TableCell>
            <TableCell align="center">Transaction Date</TableCell>
            <TableCell align="center">Amount (in INR)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((val) => (
            <TableRow
              key={val.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {val.college_name}
              </TableCell>
              <TableCell align="center">{val.display_title}</TableCell>
              <TableCell align="center">{val.start_date}/{val.end_date}</TableCell>
              <TableCell align="center">{val.payment_status===1?'Paid':'Pending'}</TableCell>
              <TableCell align="center">{val.payment_id}</TableCell>
              <TableCell align="center">{val.txn_date}</TableCell>
              <TableCell align="center">{parseFloat(val.total_tax)+parseFloat(val.total_amount_received)+parseFloat(val.payment_gateway_fees)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
