import { Tabs } from 'react-bootstrap';
import React from 'react'
// import DonutGraph from './DonutGraph';
// import DonutGraph1 from './DonutGraph1';
import Home from './Home';
import ProjectsProgress from './ProjectsProgress';
import Completed from './Completed';

function Tab() {
    const cont={
        backgroundColor: "#fff",
    margin: 10,
    borderRadius: 10,
    // padding: 10,
    // height: 200,
    };
    const tab={
        fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    };
    const heading = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 800,
        paddingBottom: 5,
        fontSize: 20,
      };
    return (
        <div style={cont} className="d-flex flex-column">
        {/* <div style={heading}>
        <p>Projects</p>
      </div> */}
      <div className="row m-0 p-0">
        <div className="col-md-12">
        <ProjectsProgress/>
        </div>
      </div>
            {/* <Tabs defaultActiveKey="Home" id="uncontrolled-tab-example" className="mb-3 " style={tab}>
  <Tab eventKey="Home" title="Home" >
    <Home />
    <div>
    <div style={heading}>
        <p>Job Opening</p>
      </div>
      <div className="row">
        <div className="col-md-12 text-center">
          <h3 className='mt-3'>
            Comming Soon
          </h3>
        </div>
      </div>
    </div>
  
  </Tab>
  <Tab eventKey="In Progress" title="In Progress">
  <ProjectsProgress/>
  </Tab>


</Tabs> */}
        </div>
    )
}

export default Tab
