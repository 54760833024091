import React from 'react';
import Signup_Image from "../../Assets/Signup.png"
import FormRow from '../Extra/FormRow';
import Google from "../../Assets/Google.png"
import Facebook from "../../Assets/Facebook.png";
import { Link } from 'react-router-dom';

function Signup() {
    const login = {
        // height: " 90%",
        width: "100%"
    };
    const cont = {
        // backgroundColor: "#000",
        // height: "90%",
        justifyContent: "center",
        alignItem: "center",
        
    };
    const inn = {
        padding: "13%",
        justifyContent: "center",
        alignItem: "center",
        // paddingTop: "40%",
        alignSelf: "center"
    };
    const btn = {
        width: "100%",
        marginBottom: 10
    };
    const auto = {
        margin: 10
    };
    const intro = {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "normal",
    };
    const heading = {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 900,
    };
    
    return (
        <div  id="login">
            <div className="container-fluid row" style={{padding: 0}}>
                <div className=" col-md-8">
                    <img src={Signup_Image} className="img-fluid" alt="Login" style={login} />
                </div>
                <div className="col-md-4 d-flex flex-column justify-content-center align-item-center"   >
                    <div className="container-fluid " style={cont}>
                        <div style={inn} >
                            <h2 style={heading}>Welcome aboard Student!</h2>
                            <p style={intro}>Join community of amazing learners from all over India.</p>
                            <div className="form-row">

                                <FormRow
                                    type="text"
                                    placeholder="Type your name"
                                />
                                <FormRow
                                    type="text"
                                    placeholder="Type your email"
                                />
                                <FormRow
                                    type="text"
                                    placeholder="Type your password"
                                />
                                <FormRow
                                    type="text"
                                    placeholder="Confirm your password"
                                />
                                <button type="button" className="btn btn-primary" style={btn}>Signup</button>
                            </div>
                            <p style={intro}>New here? Create a new account!<Link to="/"> Click here!</Link></p>
                            <p style={intro}>You can also signup with:</p>

                            <div className="d-flex flex-row justify-content-center" >
                                <img src={Google} alt="Google" style={auto} />
                                <img src={Facebook} alt="Google" style={auto} />
                            </div>
                            <p>Note: For better experience kindly view the application in Chrome.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Signup
