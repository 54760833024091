import React from 'react'

function WorkshopMain() {
    return (
        <div>
            WorkshopMain
        </div>
    )
}

export default WorkshopMain
