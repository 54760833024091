import React,{useEffect} from "react";
import "../../../App.css";

function VideoPlayer(props) {
  return (
    <div>
       {/* <video width="250" controls id="videoPlayer" controlsList="nodownload" onContextMenu={(e)=> e.preventDefault()}>
        <source id="videoSource" />
      </video> */}
      <iframe allow="autoplay" className="video-player-container spotlightr" allowFullScreen="true" allowtransparency="true" style={{height: '500px', width:'100%'}}   frameborder="0" scrolling="no" name="videoPlayer" id="videoPlayer" onContextMenu={(e)=> e.preventDefault()}> </iframe>
    </div>
  );
}

export default VideoPlayer;
