import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import moment from 'moment';
import Swal from 'sweetalert2';
import API from '../../Routes/api';

function MyVerticallyCenteredModal(props) {
  const handleTelegramPopupClose = async() =>{
    await API.post('telegram/update').then(res=>{
      window.location.href="/dashboard";
    })
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Body>
        <h5 className="text-center mb-3">
          Please join to Get further updates regarding your Live Project's
          schedule and Internship on <b>Telegram Group</b>
        </h5>

        <div className="text-center mt-4">
          <p>
            <b>{props.projectData?.internship_course_name} </b>by <span>{props.projectData?.company_name}</span> <br />
            <a href={props.projectData?.telegram_link} target="_blank" style={{color: "blue", textDecoration: "underline"}}>Click here </a> to join the Telegram link
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column text-center bt-0">
      <div>

        <Button onClick={handleTelegramPopupClose} style={{fontSize: "16px"}}>Close</Button>
      </div>
      <div>

          <p>In case you opted for more than one domain then join all the <br /> telegram group before you click on the close tab</p>
      </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function TeleModal() {
  const [modalShow, setModalShow] = useState(false);
  const [projectBatchData,setProjectBatchData]=useState([]);
  useEffect(()=>{
    checkModalLoad()
  },[])


  const checkModalLoad=async()=>{
    await API.get('telegram/check').then(res=>{
        if(res.data.data.tl_popup_status===0 && res.data.data.telegram_link!==null){
          setProjectBatchData(res.data.data);
          setModalShow(true)
        }else{
          setModalShow(false)
        }
    })
  }     


  
  return (
    <>
      {/* <Button variant="primary" onClick={() => setModalShow(true)}>
        Launch vertically centered modal
      </Button> */}

      <MyVerticallyCenteredModal
        projectData = {projectBatchData}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
