import React from "react";
import VideoPlayer from "../Extra/VideoPlayer";
// import MyNotes from './MyNotes';
import QueriesDashboard from "../Queries/QueriesDashboard";
import Calander from "./Calander";
import MyNotes from "./MyNotes";

function RowThree() {
  return (
    <div>
      <div style={{ marginBottom: 20, marginTop: 12 }}>
        <VideoPlayer />
      </div>
      {/* <div>
          <MyNotes />
        </div> */}
      <Calander style={{ marginTop: 25, marginBottom: 10 }} />
    </div>
  );
}

export default RowThree;
