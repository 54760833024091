import React,{useState,useEffect,useSetState} from 'react';
import Sidebar from './../Sidebar/Sidebar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import QABox from '../Extra/QABox';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import API from '../../Routes/api';
import { Button,Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import Drop from './../Queries/Drop';
import CourseDrop from './CourseDrop';
import ProgramDrop from './ProgramDrop';
import { textAlign } from '@mui/system';
import NotFound from "../../Assets/not-found.png";

function QueriesMain() {
  
  const [course,setCourse]                  = useState([]);
  const [projects,setProjects]              = useState([]);
  const [disabled, setDisable]              = useState(false);
  const [mentor,setMentor]                  = useState([]);
  const [queries,setQueries]                = useState([]);
  const [query,getSetQuery]                 = useState([]);
  const [project,getSetProject]             = useState([]);
  const [selectCourse,setSelectedCourse]    = useState();
  const [selectProject,setSelectedProject]  = useState();
  const [page, setPage]                     = useState();
  const [rowsPerPage, setRowsPerPage]       = useState();
  const [paginationLink, setPaginationLink] = useState([]);
  const [previousPage,setPreviousPage]      = useState();
  const [presentPage,setPresentPage]        = useState();
  const [nextPage,setNextPage]              = useState();
  const [firstPage,setFirstPage]            = useState();
  const [lastPage,setLastPage]              = useState();
  const [pageToOpen,setPageToOpen]          = useState();
  const [open, setOpen]                     = useState(false);
  const [paginationData,setPaginationData]  = useState([]);
 
  const mycard = {
    height: "250px",
    textAlign:"center",
    
  }
  const notFoundImage = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "10%",
    paddingTop:"30px"
  }

  useEffect(() => {
      setDisable(true);
      initiateData();
  }, [])
 
 
  useEffect(() => {
    window.addEventListener('keypress', function(event){
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("ask_question").click();
      }
    })
  }, []);
  const initiateData=async()=>{
      await API.get('course').then(res=>{
          setCourse(res.data.data);
          //setProjects(res.data.data[0].project_details)
      })
      API.get('queries?course_id=0').then(res=>{
        setQueries(res.data.data.data);
        setPaginationData(res.data.data)
        setPaginationLink(res.data.data.links)
        setRowsPerPage(res.data.data.per_page)
        setPresentPage(res.data.data.current_page)
        if(res.data.data.prev_page_url!==null){
          setPreviousPage(parseInt(res.data.data.current_page)-1)
        }else{
          setPreviousPage(null)
        }
        if(res.data.data.next_page_url!==null){
          setNextPage(parseInt(res.data.data.current_page)+1)
        }else{
          setNextPage(null)
        }
        setFirstPage(1)
        setLastPage(res.data.data.last_page)
      })
  }
  
  const handleCourseChange = async(e) =>{
    var course_id=e.target.value;
    await API.get('course/projects?course_id='+course_id).then(res=>{
      setProjects(res.data.data)
    })
    if(e.target.id === "course_filter_queries_id"){
      await API.get('queries?course_id='+course_id).then(res=>{
        setQueries(res.data.data);
        setPaginationData(res.data.data)
        setPaginationLink(res.data.data.links)
        setRowsPerPage(res.data.data.per_page)
        setPresentPage(res.data.data.current_page)
        if(res.data.data.prev_page_url!==null){
          setPreviousPage(parseInt(res.data.data.current_page)-1)
        }else{
          setPreviousPage(null)
        }
        if(res.data.data.next_page_url!==null){
          setNextPage(parseInt(res.data.data.current_page)+1)
        }else{
          setNextPage(null)
        }
        setFirstPage(1)
        setLastPage(res.data.data.last_page)
      })
      //triggerTextbox(course_id)
    }
    getSetQuery({
      ...query,
      course_id:course_id
    })
    setSelectedCourse(course_id);
  }

  const handleProjectChange = async(e) =>{
    var project_id=e.target.value;
    await API.get('course/projects/mentor?project_id='+project_id).then(res=>{
      setMentor(res.data.data)
    })
    
    triggerTextboxProject(project_id)
    setSelectedProject(project_id);
    getSetQuery({
      ...query,
      project_id:project_id
    })
  }

  const triggerTextbox = async(course_id) =>{
    if(course_id===0){
      setDisable(true)
    }else{
      setDisable(false)
    }
  }

  const triggerTextboxProject = async(project_id) =>{
    if(project_id===0){
      setDisable(true)
    }else{
      setDisable(false)
    }
  }

  const triggerValidation = async(e) => {
    var message ='';
    if(selectCourse === undefined || selectCourse === 0 || selectCourse === ''){
      message = 'You have to select a domain to add your Query';
    }else if(selectProject === undefined || selectProject === 0 || selectProject === ''){
      message = 'You have to select a project to add your Query';
    }
    
    if(disabled){
      Swal.fire({
        title: "Not Allowed",
        text: message,
        icon: 'warning',
        confirmButtonText: 'OK'
      })
    }
  }

  const handleQueryChange =async(e) => {
    getSetQuery({
      ...query,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim()
    });
  
  }
  const handleQuerySubmit = async(e) => {
    e.preventDefault()
    if(disabled){
      triggerValidation()
    }else if(query.query === undefined || query.query === ''){
      Swal.fire({
        title: "Not Allowed",
        text: "Your Question can't be Empty! Please Add a Question!",
        icon: 'warning',
        confirmButtonText: 'OK'
      })
    }else{
      updateQueryState();
      API.post('queries/ask',query).then(res=>{
        if(res.data.code===200){
            Swal.fire({
                title: res.data.status,
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                window.location.href="/queries";
            })
        }
      });
    }
  }
  
  function updateQueryState(){
    let new_course_id=document.getElementById("course_id").value;
    let new_project_id=document.getElementById("project_id").value; 
    getSetQuery({
      ...query,
      course_id:new_course_id,
      project_id:new_project_id
    })
    setMentor('');
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    document.getElementById('recordsPerPage').value=event.target.value;
    if(event.target.value!==null && event.target.value!==undefined){
      handleProjectsPagination();
    }
  };


  const handlePaginationClick = (page_no) => {
    setPageToOpen(page_no);
    if(page_no!== null && page_no !== undefined){
      handleProjectsPagination();
    }
  }

  const handleProjectsPagination = () => {
    var project_id = document.getElementById("project_id").value;
    var course_id = document.getElementById("course_id").value;
    var project_id_search_string = project_id !== '' && project_id !== undefined ? '&project_id='+project_id : '';
    var course_id_search_string = course_id !== '' && course_id !== undefined ? '&course_id='+course_id : '';
    var project_per_page_variable_string = rowsPerPage !== '' && rowsPerPage !== undefined ? '&per_page='+rowsPerPage:'';
    var project_page_to_open_variable_string = pageToOpen !== '' && pageToOpen !== undefined ? '&page='+pageToOpen:'';
    var search_string =  project_id_search_string+course_id_search_string+project_per_page_variable_string+project_page_to_open_variable_string;
    API.get('queries?'+search_string).then(res=>{
      setQueries(res.data.data.data);
      setPaginationData(res.data.data)
      setPaginationLink(res.data.data.links)
      setRowsPerPage(res.data.data.per_page)
      setPresentPage(res.data.data.current_page)
      if(res.data.data.prev_page_url!==null){
        setPreviousPage(parseInt(res.data.data.current_page)-1)
      }else{
        setPreviousPage(null)
      }
      if(res.data.data.next_page_url!==null){
        setNextPage(parseInt(res.data.data.current_page)+1)
      }else{
        setNextPage(null)
      }
      setFirstPage(1)
      setLastPage(res.data.data.last_page)
    })
  }
  const handleQueriesFilter = () =>{
    var project_id = document.getElementById("project_filter_queries_id").value;
    var course_id = document.getElementById("course_filter_queries_id").value;
    var project_id_search_string = project_id !== '' && project_id !== undefined ? '&project_id='+project_id : '';
    var course_id_search_string = course_id !== '' && course_id !== undefined ? '&course_id='+course_id : '';
    var project_per_page_variable_string = rowsPerPage !== '' && rowsPerPage !== undefined ? '&per_page='+rowsPerPage:'';
    var project_page_to_open_variable_string = pageToOpen !== '' && pageToOpen !== undefined ? '&page='+pageToOpen:'';
    var search_string =  project_id_search_string+course_id_search_string+project_per_page_variable_string+project_page_to_open_variable_string;
    API.get('queries?'+search_string).then(res=>{
      setQueries(res.data.data.data);
      setPaginationData(res.data.data)
      setPaginationLink(res.data.data.links)
      setRowsPerPage(res.data.data.per_page)
      setPresentPage(res.data.data.current_page)
      if(res.data.data.prev_page_url!==null){
        setPreviousPage(parseInt(res.data.data.current_page)-1)
      }else{
        setPreviousPage(null)
      }
      if(res.data.data.next_page_url!==null){
        setNextPage(parseInt(res.data.data.current_page)+1)
      }else{
        setNextPage(null)
      }
      setFirstPage(1)
      setLastPage(res.data.data.last_page)
    })
  }

  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,  
  }

  const cont={
    margin: 10,
  }
  
  return  <div className="row p-0" >
            <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
              <Sidebar />
            </div>
            <div className="col-10 col-lg-10 col-md-11 col-xs-11">
              <div className="row">
                <div className="col-md-6"><h4 style={heading}>Queries<small style={{fontSize:"15px"}}>(Only project related queries needed to be asked)</small></h4></div>
                <div className="col-md-2">
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Domain
                    </InputLabel>
                    <NativeSelect
                      defaultValue={30}
                      inputProps={{
                        name: 'course',
                        id: 'course_filter_id',
                      }}
                      onChange={handleCourseChange}
                    >
                      <option value="0">All Batches</option>
                    {
                      course.map((val)=>
                      <option value={val.internship_course_id}>{val.internship_batch_title}</option>
                      )
                    }
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className='col-md-2'>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Project
                    </InputLabel>
                    <NativeSelect
                      defaultValue={30}
                      inputProps={{
                        name: 'mentor',
                        id: 'project_filter_id',
                      }}
                      onChange={handleProjectChange}
                    >
                    
                      <option value="0">All Projects</option>
                      {
                        projects.map((val)=>
                        <option value={val.id}>{val.project_title}</option>
                        )
                      }
                      
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col-md-2">
                  <TextField
                    id="standard-read-only-input"
                    label="Mentor"
                    defaultValue="Mentor"
                    value={mentor.name}                    
                    readOnly
                    variant="standard"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div style={cont} className='text-center'>
                    <Form>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">                  
                        <Form.Control type="text" placeholder="Type Your Queries" size="lg" name='query' onChange={handleQueryChange}  readOnly={disabled} onClick={triggerValidation}/>
                        <Form.Control type="hidden" id="project_id" value={selectProject}/>
                        <Form.Control type="hidden" id="course_id" value={selectCourse}/>
                      </Form.Group>
                      <Button variant="primary" id="ask_question"size="lg" onClick={handleQuerySubmit}>
                        Ask Question
                      </Button>
                    </Form>
                    
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <h4 style={heading}>Search Query Database</h4>
                    </div>
                    <div className="col-md-3 mt-2 d-flex justify-content-center">
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                          Domain
                        </InputLabel>
                        <NativeSelect
                          defaultValue={30}
                          inputProps={{
                            name: 'course',
                            id: 'course_filter_queries_id',
                          }}
                          onChange={handleCourseChange}
                        >
                          <option value="0">All Batches</option>
                        {
                          course.map((val)=>
                          <option value={val.internship_course_id}>{val.internship_batch_title}</option>
                          )
                        }
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <div className="col-md-3 mt-2 d-flex justify-content-center">
                      <FormControl fullWidth>
                        <InputLabel variant="standard" htmlFor="uncontrolled-native">
                          Project
                        </InputLabel>
                        <NativeSelect
                          defaultValue={30}
                          inputProps={{
                            name: 'mentor',
                            id: 'project_filter_queries_id',
                          }}
                          onChange={handleProjectChange}
                        >
                        
                          <option value="0">All Projects</option>
                          {
                            projects.map((val)=>
                            <option value={val.id}>{val.project_title}</option>
                            )
                          }
                          
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <div className="col-md-1 mt-2">
                      <Button style={{backgroundColor: "#FFA000", borderColor: "#FFA000", borderRadius: 10}} onClick={handleQueriesFilter}>Find</Button>
                    </div>
                    <div className="col-md-1 mt-2">
                      <Button style={{borderRadius: 10}}>Reset</Button>
                    </div>
                  </div>
                  <div className='card' style={{Height:"200px !important"}}>
                    {
                      
                      queries.length>0?queries.map((val) => 
                        
                        <QABox
                          eveKey={val.id}
                          ques={val.question}
                          ans={val.answer!==null?val.answer:''}
                          name={val.name}
                          course={val.course_title}
                          date={moment(val.created_at).format('DD/MM/YYYY')}
                        />
                      
                      ):
                      <div className="card" style={mycard} >
                        {/* <img style={notFoundImage} src={NotFound}/> */}
                        <p>No Queries Found!</p>
                      </div>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7" style={{"padding": "0px!important"}}> 
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className="page-item">
                          <a className="page-link" href="#" {...previousPage===null?'disabled':''} onClick={() => handlePaginationClick(previousPage)}>Previous</a>
                        </li>
                        {
                          paginationData.links?.map((data)=>{                  
                            if(data.label !== "pagination.previous" && data.label !== "pagination.next")
                              return(
                                <li className={data.active === true?"page-item active":"page-item"}><a className="page-link" href="#" onClick={() => handlePaginationClick(data.label)}>{data.label}</a></li>
                              );                  
                          })
                        }
                        <li className="page-item">
                          <a className="page-link" href="#" {...nextPage===null?'disabled':''} onClick={() => handlePaginationClick(nextPage)}>Next</a>
                        </li>                
                      </ul>
                    </nav>
                  </div>       
                  <div className="col-md-5" style={{"padding": "0px!important"}}> 
                    <div className="">
                      <div id="pagination" className=""> 
                        <span className="pull-left marginRight-md"></span>
                        <input type="hidden" id="totalCount" value=""></input>
                        <input type="hidden" id="sort_column" value=""></input>
                        <input type="hidden" id="sort_order" value=""></input>
                        <div className="row">
                          <div className="col-md-6 text-center">
                            <span className="pull-left pagination-count ">
                              <small>Showing</small>
                              <b>&nbsp;{paginationData.from !== ''?paginationData.from:0}&nbsp;</b>
                              <small>to</small>
                              <b>&nbsp;{paginationData.to!==''?paginationData.to:0}&nbsp;</b> 
                              <small>out of</small> 
                              <b>&nbsp;{paginationData.total!==''?paginationData.total:0}&nbsp;</b>
                            </span>
                          </div>
                          <div className="col-md-6 text-end">
                            <div className="pull-right pagination-count ui-checkbox ui-checkbox-primary">
                              <span className="totalCount">
                                Total<b>&nbsp;{paginationData.total!==''?paginationData.total:0}&nbsp;</b>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div> 
}

export default QueriesMain;
