import React,{ useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import StatusModal from './StatusModal';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import API from '../../Routes/api';
import Swal from 'sweetalert2';
import moment from 'moment';
import ProgressBar from './ProgressBar';

function Status(props) {
  const [lgShow, setLgShow] = useState(false);
  const [instructionModal,setinstructionModal]=useState(false);
  const [progressPercentage,setProgressPercentage]=useState(0);
  const [progressBarDisplay,setProgressBarDisplay] = useState("none")

  const getStatus = (statuscode) =>{
    switch(statuscode){
      case 1  : return 'Submitted';
                
      case 2  : return 'Pending';
                
      case 3  : return 'Approved';
                
      case 4  : return 'In Progress';
                
      case 5  : return 'Rejected';

      case 6  : return 'Resubmitted';
                
      default : return 'Not Submitted';
    }
  }
  const triggerFileUpload = async(project_id) => {
    
    //e.preventDefault()
    const formData = new FormData();
    formData.append("project_file", document.getElementById("project_image_"+project_id).files[0]);
    formData.append('project_id',document.getElementById("project_"+project_id).value);
    formData.append('project_enrollment_id',document.getElementById("project_enrollment_id_"+project_id).value);
    if(document.getElementById("project_files_upload_id_"+project_id)){
      formData.append('project_files_upload_id',document.getElementById("project_files_upload_id_"+project_id).value);
    }
    formData.append('project_batch_id',document.getElementById("project_batch_id_"+project_id).value);
    formData.append('project_type','capstone');
    const options = {
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor( (loaded * 100) / total )
        console.log( `${loaded}kb of ${total}kb | ${percent}%` );
        setProgressPercentage(percent);
        setProgressBarDisplay("block")
        if( percent < 100 ){
          this.setState({ uploadPercentage: percent })
        }
      }
    }
    API.post('projects/upload',formData,options).then(res=>{
        if(res.data.code===200){
            Swal.fire({
                title: res.data.status,
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                window.location.href="/projects";
            })
        }
    }).catch(err=>{
      if(err.code==='ERR_BAD_REQUEST'){
          Swal.fire({
              title: "Unsupported File Format",
              text: err.response.data.message.response.original.project_file[0],
              icon: 'warning',
              confirmButtonText: 'OK'
          }).then((result) => {
            window.location.href="/projects";
          })
      } 
  }); 
  };
  const progressBar ={
    display:"none"
  }
  const head={
    fontWeight: "bold",
    marginBottom: 5,
    height: "60px",
  };
  const sub = {
    fontWeight: 500,
  };
  const cont = {
    fontSize: "14px",
  };
  return (
    <div style={cont}>
      <h6 style={head} className="project_status">{props.head}</h6>
      <p style={sub}>{props.sub}</p>
      <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
        Submission date : {props.submission_date !=='tbu'? moment(props.submission_date).format('DD/MM/YYYY'):'To be Updated'}{" "}
      </h6>
      
        {props.submission_instruction_url!==null?<a style={{fontSize: "14px", marginBottom: "14px" }}href={props.submission_instruction_url} target="_blank" download>View Submission Instruction<DownloadIcon/></a>:''}
      
           {props.file_data?.project_id === props.id &&  props.file_data?.file!==null?
              props.file_data?.allow_reupload===0?
              <div>
                <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                  Upload Project: <br /> Project Uploaded
                </h6>
                {props.file_data?.status === 6 || props.file_data?.project_file_reuploaded === true ?
                  <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                    Resubmitted On : {moment(props.file_data?.updated_at).format('Do MMMM')}
                  </h6>
                :
                  <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                    Submitted On : {moment(props.file_data?.created_at).format('Do MMMM')}
                  </h6>                  
                }
              </div>
              :
              <div>
                <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                  Reupload Project: <br /> <input type="file" id={"project_image_"+props.id} className="mt-2" onChange={()=>triggerFileUpload(props.id)}  accept="application/msword, application/vnd.ms-powerpoint, application/pdf, application/zip, application/vnd.rar image/*"></input>
                  <input type="hidden" id={"project_"+props.id} value={props.id} ></input>
                  <input type="hidden" id={"project_enrollment_id_"+props.id} value={props.enrollment_id} ></input>
                  <input type="hidden" id={"project_files_upload_id_"+props.id} value={props.file_data?.id} ></input>
                  <input type="hidden" id={"project_batch_id_"+props.id} value={props.batch_id} ></input>
                </h6>
                <ProgressBar completed={progressPercentage} display={progressBarDisplay}/>
                <p  style={{ fontSize: "10px", marginBottom: "14px" }}>Max Upload File Size: <b>100MB</b></p>
                <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                  Last Submitted On : {moment(props.file_data?.created_at).format('Do MMMM')}
                </h6>
              </div>  
          :
            <div>
              <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                Upload Project: <br /> <input type="file" id={"project_image_"+props.id} className="mt-2" onChange={()=>triggerFileUpload(props.id)}  accept="application/msword, application/vnd.ms-powerpoint, application/pdf, application/zip, application/vnd.rar image/*" ></input>
                <input type="hidden" id={"project_"+props.id} value={props.id} ></input>
                <input type="hidden" id={"project_enrollment_id_"+props.id} value={props.enrollment_id} ></input>
                <input type="hidden" id={"project_batch_id_"+props.id} value={props.batch_id} ></input>
              </h6>
              <ProgressBar completed={progressPercentage} display={progressBarDisplay}/>
              <p  style={{ fontSize: "10px", marginBottom: "14px" }}>Max Upload File Size: <b>100MB</b></p>
              <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                Submitted On : N/A
              </h6>
            </div>  
          }
      
      {
        props.file_data?.project_id === props.id ?
          <h6 style={{ fontSize: "14px", marginBottom: "14px",  }}>
            View Project
            <span style={{marginLeft: "15px", color: "blue"}}>              
              
                {/* <a href={process.env.REACT_APP_PUBLIC_FILE_URL+props.file_data?.file} target="_blank"  download><DownloadIcon/></a> */}
                <a href={props.file_data?.project_file_link} target="_blank"  download><DownloadIcon/></a>
            </span> 
          </h6>
        :
          <h6 style={{ fontSize: "14px", marginBottom: "14px",  }}>
            View Project 
            <span style={{marginLeft: "15px", color: "blue"}}>              
              N/A
            </span> 
          </h6>
       }

      <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
        {/* {console.log(props.file_data?.score)} */}
        Score : {props.score!=='' && props.score!==null && props.score!==undefined?props.score+' out of 10':'To be Updated'}
      </h6>
       
      <Link onClick={() => setLgShow(true)}>Status : {getStatus(props.projectstatus)} {props.approvalmess!== undefined && props.approvalmess !== '' && props.approvalmess !== null ?<ChatBubbleOutlineIcon sx={{color:"blue"}}/>:''}</Link>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Remark
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ><div dangerouslySetInnerHTML={{__html : props.approvalmess? props.approvalmess:'No Remarks added!'}}/> </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={instructionModal}
        onHide={() => setinstructionModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Submission Instruction
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.submission_instruction? props.submission_instruction:'Submission Instruction Not Set!'} </Modal.Body>
      </Modal>
    </div>
  );
}

export default Status;
