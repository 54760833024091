import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonIcon from '@mui/icons-material/Person';
import React,{useEffect, useState} from "react"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ScheduleDrop from '../Dashboard/ScheduleDrop';
import CourseDrop from './../Queries/CourseDrop';
import ProgramDrop from './../Queries/ProgramDrop';
import moment from 'moment';
import Swal from 'sweetalert2';
import API from '../../Routes/api';


function MyVerticallyCenteredModal(props) {
    const [password_details,setPassword] =  useState([])

    const handlePassword = (e)=>{
        setPassword(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        )
    }

    const handlePasswordResetModal = (e) => {
        e.preventDefault();
        API.put('auth/user/password/update?password_status=1',password_details).then(res=>{
            if(res.data.code===200){
                Swal.fire({
                    title: res.data.status,
                    text: res.data.message,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    window.location.reload();
                });
            }      
        }).catch(err=>{
            if(err.code==='ERR_BAD_REQUEST'){
                Swal.fire({
                    title: err.response.data.status,
                    text: err.response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            } 
        }); 
    };
    const form = {
        padding:12,  
    }
    const btn ={
        marginTop: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
  
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <h4 style={{color:'#000'}}>Update your Password</h4>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group">
                <label for="inputAddress">Old Password</label>
                <input type="password" className="form-control" name="old_password" onChange={handlePassword} />
            </div>
            <div className="form-group">
                <label for="inputAddress">New Password</label>
                <input type="password" className="form-control"   name="password" onChange={handlePassword}/>
            </div>
            <div className="form-group">
                <label for="inputAddress">Confirm Password</label>
                <input type="password" className="form-control"  name="password_confirmation" onChange={handlePassword} />
            </div>                                                     
        </Modal.Body>
        <div className="d-flex justify-content-center text-center">
            <Modal.Footer>
                <Button onClick={handlePasswordResetModal}>Change Password</Button>
            </Modal.Footer>
        </div>
    </Modal>
  );
}

export default function PasswordModal() {
  const [modalShow, setModalShow] = useState(false);

  useEffect(()=>{
    checkModalLoad()
  },[])
  
  const checkModalLoad=async()=>{
    await API.get('auth/user').then(res=>{
        if(res.data.data[0].other_details.password_status===0){
            document.getElementById('password_panel').click()
          //setModalShow(true)
        }else{
          setModalShow(false)
        }
    })
  }     

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

