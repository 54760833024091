import React from "react";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import API from '../../Routes/api';

function OfferLetter() {
    const [domains,setDomains]                = React.useState([]);
    const [certificate,setCertificate]        = React.useState([]);
    const [course,setCourse]        = React.useState('0');
    const [showButton,setShowButton] =React.useState('block');
    React.useEffect(() => {
        fetchStudentDomains();
    }, [])
      
    const handleChange = (event) => {
        console.log(event)
        getCertificate(event.target.value);
        
    };
    
    const downloadFile = () => {
        window.open(certificate?.file , '_blank').focus()
    }

    const getCertificateMessageHtml = () => {
        if(certificate?.message !== ''){
            return (
                <div>
                    <div style={{marginTop: 20}} >
                        <p>
                        {certificate?.message}
                        </p>
                    </div>                    
                </div>
            )        
        }        
    }
    const getCertificateFileHtml =()=>{
        if(certificate?.file !== '' ){
            return (
                <div>
                    <div className="d-flex justify-content-center mb-4" style={{display:{showButton}}}>
                        <button className="btn" style={down} onClick={()=>downloadFile()}>
                            Download
                        </button>
                    </div>
                </div>
            )
        }
    }
    const fetchStudentDomains = async() =>{    
        await API.get('domains').then(res=>{
            setDomains(res.data.data);
        })
    }
    
    const getCertificate = async(course_id) => {
        setCourse(course_id)
        if(course_id!=='0'){        
            await API.get('offerletter?course_id='+course_id).then(res=>{
            setCertificate(res.data.data);
            if(res.data.data?.file !== '' && res.data.data?.file !== undefined && res.data.data?.file !== null){
                setShowButton = "block";
            }else{
                setShowButton = "none";
            }
            getCertificateMessageHtml();
            getCertificateFileHtml();
        })        
    }
    }
    
    const contain = {
        backgroundColor: "#f8f9fa",
        // height: "50%",
        // height: 210,
        borderRadius: 10,
        
        // border: "1px solid #000",
    };
    const heading = {
        // marginBottom: 20,
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 18,
        marginTop: 2,
        marginLeft: 10

    };
    const down = {
        backgroundColor: "rgb(255, 160, 0)",
    };
    const warn = {
        backgroundColor: "#fff",
        color: "red",
        padding: 10,
        borderRadius: 10,
        boxShadow: "#000",
    };
    const headStyle ={
        backgroundColor: "#3E7DC7",
        padding: "5px 5px 5px 5px",
        color: "#fff",
        borderRadius: 10,
    
    }
    const innerContain={
        paddingLeft: 30,
        padding: 25,
        paddingTop: 10,
    }
    return (
        <div style={contain}>
            <div style={headStyle}>
                <div>
                    <h2 style={heading}> Internship Offer Letter </h2>
                </div>
            </div>
            <div style={innerContain}>
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Domain
                </InputLabel>
                <NativeSelect
                    defaultValue={30}
                    inputProps={{
                    name: 'course',
                    id: 'course_filter_id',
                    }}
                    onChange={handleChange}
                >
                    <option value="0">Select domain</option>
                    {
                        domains.map((val)=>
                            <option value={val.id}>{val.display_title}</option>
                        )
                    }
                </NativeSelect>
            </FormControl>
            
            <div>{course === '' || course === '0' ? 'Please Choose a Domain' : "" } {certificate?.message !== "" && certificate?.message !== undefined && certificate?.message !== null ? getCertificateMessageHtml() : ""} { certificate?.file !== "" && certificate?.file !== undefined && certificate?.file !== null ?  getCertificateFileHtml() : ""}</div>
             
            {/* <div style={warn} className="d-flex align-content-center">
                <ReportProblemIcon />{" "}
                <p>Please clear your outstanding payment</p>
            </div> */}
            </div>
        </div>
    );
}

export default OfferLetter;
