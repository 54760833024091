import React, { useEffect, useState } from 'react'
import TeleModal from '../Components/Modal/TeleModal';
import StartModal from '../Components/Modal/StartModal';

export function checkStartPopup() {
    
}

export function checkTelegramPopup() {
    return ;
}

export function handelRightClick(event) {
    if(process.env.REACT_APP_DISABLE_CONTEXT_CLICK){
        event.preventDefault();
    }
}