import React from 'react';
import Sidebar from './../../Sidebar/Sidebar';
import InternCertificate from './InternCertificate';

function CertificateMain() {
  const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,  
  }
  return  <div className="row p-0">
            <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
              <Sidebar />
            </div>
            <div className="col-10 col-lg-10 col-md-11 col-xs-11">
              <h4 style={heading}>Certificate</h4>
              <InternCertificate />
            </div>
          </div> ;
}

export default CertificateMain;
