import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Refund from "../../Assets/Refund and Cancellation Policy-Inmovidu.pdf";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

function ScheduleTable() {
  return (
    <div>
        <Table striped bordered hover size="sm">
  <thead>
    <tr>
      <th>Date & Time</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>

    <tr>
      <td>24-Apr-2022 6:00pm</td>
      <td><a  href='https://meet.google.com/eqe-xgih-eji' target="_blank" rel="noreferrer">
         Join
        </a></td>
    </tr>
    <tr>
      <td >24-Apr-2022 6:00pm</td>
      <td><a href="https://meet.google.com/eqe-xgih-eji" target="_blank" rel="noreferrer">
        Join
        </a></td>
    </tr>
    <tr>
      <td >24-Apr-2022 6:00pm</td>
      <td><a href="https://meet.google.com/eqe-xgih-eji" target="_blank" rel="noreferrer">
        Join
        </a></td>
    </tr>
  </tbody>
</Table>
    </div>
  )
}

export default ScheduleTable