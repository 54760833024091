import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Accordion } from "react-bootstrap";

function QABox(props) {
  const contain = {
    // backgroundColor: "#fff",
    // margin: 10,
    borderRadius: 0,
    // padding: 10,
    // height: "90%",
  };
  const dot = {
    color: "rgb(255, 105, 6)",
    marginRight: 10,
  };
  const answer = {
    color: "#707070",
  };
  const highlight = {
    fontStyle: "bold",
  };
  return (
    <div>
      <div >
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey={props.eveKey} style={contain}>
            <Accordion.Header><h5>{props.ques}</h5></Accordion.Header>
            <Accordion.Body>
              <h5 style={answer}>
                <div dangerouslySetInnerHTML={{__html : props.ans}}/>
              </h5>
              <div className="row m-0">
              <div className="col-md-4 d-flex text-center justify-content-center">
                <h5 style={highlight}><span style={{textDecoration: "underline"}}>Name:</span> {props.name}</h5>
              </div>
              <div className="col-md-4 d-flex text-center justify-content-center">
                <h5 style={highlight}><span style={{textDecoration: "underline"}}>Domain:</span> {props.course}</h5>
              </div>
              <div className="col-md-4 d-flex text-center justify-content-center">
                <h5 style={highlight}><span style={{textDecoration: "underline"}}>Date:</span> {props.date}</h5>
              </div>
            </div>
            </Accordion.Body>
            
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default QABox;
