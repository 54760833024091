import React from 'react';
import { InterSidedata } from './../Data/InternSidedata';
import InterSideCard from './InterSideCard';


function InternSide() {
    const cont = {
        padding: 20,
        backgroundColor: "#fff",
        margin: 10,
        marginLeft: 0,
        borderRadius: 10,
        width: "100%",
        height: "90%",
        
      };
      const p={
          height: "90%"
      }
  return <div style={cont}>
      <div className="overflow-auto scroll" style={p}>
            {InterSidedata.map((val, key)=>
             <InterSideCard 
                 courseName={val.courseName}
                 link={val.link}
             />
            )}
        </div>
  </div>;
}

export default InternSide;
