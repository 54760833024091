import React,{useState} from 'react'
import copy from "copy-to-clipboard";
import { Button, Form } from 'react-bootstrap';

const Clipboard = (props) => {
	const [copyText, setCopyText] = useState();

	const handleCopyText = (e) => {
	setCopyText(e.target.value);
	}
	
	const copyToClipboard = () => {
	copy(props.field);
	// alert(`You have copied "${copyText}"`);
	}
	
	return (
	<div style={{width: "100%"}}>
		{/* <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    <Form.Control as="textarea" value={props.field} onChange={handleCopyText} rows={3} />
  </Form.Group>
  </Form> */}
  <h5 value={props.field} onChange={handleCopyText}>{props.field}</h5>
		<button onClick={copyToClipboard}>
			Copy
		</button>

	
	</div>
	)
}

export default Clipboard;
