import React,{useEffect,useState} from "react";
import Image from "../../Assets/web.png";
import Image1 from "../../Assets/tele.png";
import Image2 from "../../Assets/web.png";
import Bar from "./Bar";
import Progress_Data from "./../Data/Progress_Data";
import { Button } from "react-bootstrap";
import API from '../../Routes/api';
import PlayButton from '../../Assets/redPlay.png'; 
import PauseButton from '../../Assets/redPause.png'; 
import LMS from '../../Assets/lms.png'
import "../../App.css"

function Progress() {
  const [MyProjectData,setProjectData]               =   useState([])
  const [isPlaying,setPlaying]                       =   useState([])
  const [videoPlayPause,setVideoPlayPause]           =   useState(PlayButton)
  const [currentVideoElement,setCurrentVideoElement]  =  useState([])
  useEffect(() => {
    fetchMyProjects();
  }, [])

  const fetchMyProjects=async()=>{
    await API.get('projects').then(res=>{
        setProjectData(res.data.data)  ;
    })
  }     
  const playbutton ={
    float: "right",
    marginRight: 5,
    height: 40,
    width: 40,
  };
  const contain = {
    backgroundColor: "#f8f9fa",
    height: "50%",
    borderRadius: 10,

  };

  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 10
    // marginBottom: 30,
  };
  const pic ={
    height: 40,
    marginRight: 10
  }
  const innerContain= {
    paddingLeft: 30,
    padding: 20,
    paddingTop: 10,
  }
  const headStyle ={
    backgroundColor: "#3E7DC7",
    padding: "5px 5px 5px 5px",
    color: "#fff",
    borderRadius: 10,

}
  const calculatePercentage = (data) => {
    let calculatedValue=0;
    return calculatedValue;
  }
  const triggerCourseVideo =(video_url,element)=>{
    var video = document.getElementById('videoPlayer');
    var source = document.getElementById('source');
    source.setAttribute('src',video_url);
    var isPlaying = video.currentTime > 0 && !video.paused && !video.ended && video.readyState > video.HAVE_CURRENT_DATA;    
    if (!isPlaying  && source.src === video_url) {
      video.load();
      video.play();
      //setVideoPlayPause(PauseButton)
      document.getElementById(element).src = PauseButton;
    }else if(isPlaying && source.src === video_url){
      video.pause();
      //setVideoPlayPause(PlayButton)
      document.getElementById(element).src = PlayButton;
    }
    setCurrentVideoElement(element)
    if(currentVideoElement !== undefined && currentVideoElement !== null && currentVideoElement!==element){
      document.getElementById(currentVideoElement).src = PlayButton;
    }
  }
  const gotoLms = (batchId)=>{
    localStorage.setItem('dashboardToLmsBatchId',batchId);
    window.location.pathname = "/lms";
  }
  
  return (
    <div style={contain} className="rowone_dash">
    <div style={headStyle}>
      <h3 style={heading}>Your Weekly Progress</h3>
    </div>
      <div style={innerContain}>
        {
          MyProjectData.map((val,index) =>
            <div className="row">
              <div className="col-md-9">
                <Bar image={val.banner_image_link} heading={val.display_title} now={val.project_progress} />
              </div>
              <div className="col-md-3" style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                <div className="d-flex">
                  <div style={{marginLeft:"10px"}}>
                    <img src={PlayButton} alt="icon" className="rowone_play" id={"PlayPauseButton_"+index} style={playbutton} title="View Course Guide" onClick={()=>triggerCourseVideo(val.batch_video_url,"PlayPauseButton_"+index)}/>
                  </div>
                  {val.batch_telegram_link?
                  <div style={{marginLeft:"10px"}}>
                    <img src={Image1} alt="telegram " className="rowone_otherbutton" style={pic} onClick={()=>window.open(val.batch_telegram_link)}/>
                  </div>:''}
                  <div className="text-center" style={{marginLeft:"10px"}}>
                    <img src={LMS} alt="LMS " className="rowone_otherbutton" style={pic} onClick={()=>gotoLms(val.internship_batch_id)} title={"Go to "+val.display_title+" Videos"}/>
                  </div>
                </div>
              </div>
            </div>            
          )
        }
        </div>
      </div>
  );
}

export default Progress;
