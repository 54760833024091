import React from 'react';
import { Carousel } from 'react-bootstrap';
import Image1 from "../../Assets/Leaderboard.png"


function Leaderboard() {
    const contain={
        backgroundColor: "#fff",
        marginBottom: 10,
        borderRadius: 10,
        marginTop: 10,
        overflowX: "hidden",
        paddingTop: 0,
        paddingBottom: -80,
    };
    const image={
        height: "60%",
        width: "60%"
    };
    const heading = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 18,
        margin: 15,
        marginBottom: -10
      };
      const carou={
        marginBottom: 0
      };
      const Para={
        background: "transparent" ,
        opacity: 1,
        bottom: "0px",
      }
    return (
        <div style={contain}>
        <h2 style={heading}>Leaderboard</h2>
            {/* <Carousel style={carou} className="text-center">
            
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 1</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 2</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 3</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 4</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 5</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 6</p>
                </div>
                <div>``
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 7</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 8</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 9</p>
                </div>
                <div>
                    <img src={Image1} alt="ima" style={image}/>
                    <p className="legend" style={Para}>Priya Malik <br /> Rank 10</p>
                </div>
            </Carousel> */}
            <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100 "
      src={Image1}
      alt="First slide"
    />
    <Carousel.Caption>
      <h2 style={{color: "#220C72"}}>Abhishek</h2>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Image1}
      alt="Second slide"
    />

    <Carousel.Caption>
    <h2 style={{color: "#220C72"}}>Abhishek</h2>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Image1}
      alt="Third slide"
    />

    <Carousel.Caption>
    <h2 style={{color: "#220C72"}}>Abhishek</h2>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
        </div>
    )
}

export default Leaderboard
