import React from 'react'
import Sidebar from "./../Sidebar/Sidebar";
import ProjectTab from './ProjectTab';
import Trending from "./Trending"

function ProjectMain() {
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,
  };
  return (
    <div className="row">
      <div className="col-2 col-lg-2 col-md-1 col-xs-1 side">
        <Sidebar />
      </div>
      <div className="col-10 col-lg-10 col-md-11 col-xs-11">
        <h4 style={heading}>Projects</h4>
        <div className="row m-0 p-0">
            <div className="col-md-8 p-0">
                <ProjectTab />
            </div>
            <div className="col-md-4">
                <Trending />
            </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectMain
