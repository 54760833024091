import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Link } from "react-router-dom";
import API from '../../../Routes/api'
import { FileDownloadOff } from "@mui/icons-material";

import Swal from "sweetalert2";

function InternCertificate() {
  function renderParticipationCertificate(certificates){
    if(certificates.participation_certificate_link!=null)                             
      return <div className="d-flex"><p style={cer}>Participation Certificate</p><a href={certificates.participation_certificate_link} download  target="_blank"><FileDownloadIcon /></a></div>;
    else
      return <div className="d-flex"><p style={cer}>Participation Certificate</p><Link to=" " style={{ color: "blue" }}><FileDownloadOff onClick={() => alert("Certificate Not Uploaded")} /></Link></div>
  }
  function renderProjectCompletionCertificate(certificates){
    if(certificates.project_certificate_link!=null)                             
      return  <div className="d-flex"><p style={com}>Project completion certificate</p><a href={certificates.project_certificate_link} download  target="_blank"><FileDownloadIcon /></a></div>;
    else
      return <div className="d-flex"><p style={com}>Project Completion Certificate</p><Link to=" " style={{ color: "blue" }}><FileDownloadOff onClick={() => alert("Certificate Not Uploaded")} /></Link></div>
  }
  function renderInternshipCompletionCertificate(certificates){
    if(certificates.internship_certificate_link!=null)                             
      return <div className="d-flex"><p style={inte}>Internship Completion Certificate</p><a href={certificates.internship_certificate_link} download  target="_blank"><FileDownloadIcon/></a></div>;
    else
      return <div className="d-flex"><p style={inte}>Internship Completion Certificate</p><Link to=" " style={{ color: "blue" }}><FileDownloadOff onClick={() => alert("Certificate Not Uploaded")} /></Link></div>
  }

  const [certificates,setCertificates] = useState([]);

  useEffect(()=>{
    fetchCertificates();
  },[])

  const fetchCertificates = async() =>{
    await API.get('certificates').then(res=>{
      setCertificates(res.data.data)  ;
    })
  }
  const para = {
    height: "90%",
    padding: 10,
    paddingBottom: 0,
  };
  const cont = {
    padding: 12,
    backgroundColor: "#fff",
    margin: 10,
    marginLeft: 0,
    borderRadius: 10,
    border:"1px solid black",
    width: "100%",
    height: "90%",
    paddingBottom: 40,
  };
  const cer = {
    marginRight: 78,
  };
  const com = {
    marginRight: 35,
  };
  const inte = {
    marginRight: 10,
  };
  const Imp = { 
    paddingBottom: 0,
    marginBottom: -10,
    marginTop: 10,
  };
  return (
    <div>
      {
        certificates.length > 0?
          certificates.map((val) =>
          <div style={cont}>
            <Form>
              <div className="row" style={para}>
                <div className="col-md-6">
                  <h5>Domain Name</h5>
                  <h6>{val.display_title}</h6>
                </div>
                <div className="col-md-6">
                  <h5>Certificate</h5>
                                                      
                  { val.participation_certificate_link !== '' ? renderParticipationCertificate(val) : ''}
                  
                  
                  { val.project_certificate_link !=='' ? renderProjectCompletionCertificate(val) : ''}
                  
                  
                  { val.internship_certificate_link!== '' ? renderInternshipCompletionCertificate(val) : ''}
                  
                </div>
              </div>
            </Form>
            <div className="text-center" style={Imp}>
              <p style={{color: "#0D6EFD"}}>
                {val.certificate_message}
              </p>
            </div>
          </div>)
        :
        <div className="row" style={para}>
          <div className='card' style={{Height:"200px !important"}}>
            <div className="col-md-12" style={{padding:"70px 0",textAlign:'center'}}>
              <h5>No Course Certificates Found</h5>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default InternCertificate;
