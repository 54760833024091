import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PersonIcon from '@mui/icons-material/Person';
import React,{useEffect, useState} from "react"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ScheduleDrop from '../Dashboard/ScheduleDrop';
import CourseDrop from './../Queries/CourseDrop';
import ProgramDrop from './../Queries/ProgramDrop';
import moment from 'moment';
import Swal from 'sweetalert2';
import API from '../../Routes/api';


function MyVerticallyCenteredModal(props) {
  const [batchData,setBatchData] = useState([]);
  const [defaultBatch,setDefaultBatch]=useState(0);
  useEffect(()=>{
    setBatchData(props.batchData);
  },[])
  const handleDomainChange = async(e) =>{
    var course_id=e.target.value;
    setDefaultBatch(course_id)
    await API.get('batch/all?course_id='+course_id).then(res=>{
      setBatchData(res.data.data);
    })
  }
  const handleDomainBatchSave =(e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('domain_id', document.getElementById("domain_id").value);
    formData.append('batch_id', document.getElementById("batch_id").value);
    API.post('domainbatch/update',formData).then(res=>{
      if(res.data.code===200){
          Swal.fire({
              title: res.data.status,
              text: res.data.message,
              icon: 'success',
              confirmButtonText: 'OK'
          }).then((result) => {
              window.location.href="/dashboard";
          })
      }
    });
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header >
      </Modal.Header>
      <Modal.Body>
      <div className="d-flex justify-content-center" >

        <PersonIcon sx={{ fontSize: 90 }}/>
      </div>
        <p>
         You have made payment of Rs. {props.data?.total_amount_received}/- as per enrollment amount on {moment(props.data?.enrollment_dt).format('DD MMMM YYYY')}.</p>
        <p>Please select your Domain and batch to complete onboarding process.</p>

        <div className="row m-0">
          <div className="col-md-6">
            <Box sx={{ minWidth: 120 , marginTop: 1, marginBottom: 1 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Domain
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    name: 'course',
                    id: 'domain_id',
                  }}
                  onChange={handleDomainChange}
                  value={defaultBatch}
                >
                  <option value="0">Choose you Domain</option>
                {
                  props.domainData?.map((val)=>
                  <option value={val.id}>{val.display_title}</option>
                  )
                }
                  
                </NativeSelect>
              </FormControl>
            </Box>
          </div>
          <div className="col-md-6 mt-2">
            <Box sx={{ minWidth: 120 ,maxWidth: 350, marginBottom: 0 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Batch
                </InputLabel>
                <NativeSelect
                  defaultValue={30}
                  inputProps={{
                    name: 'mentor',
                    id: 'batch_id',
                  }}
                >
                  <option >Choose you Batch</option>
                {
                  batchData?.map((val)=>
                  <option value={val.id}>{val.title}</option>
                  )
                }
                  
                </NativeSelect>
              </FormControl>
            </Box>
          </div>
        </div>

      </Modal.Body>
      <div className="d-flex justify-content-center text-center">
      <Modal.Footer>

        <Button onClick={handleDomainBatchSave}>Submit</Button>
      </Modal.Footer>
      </div>
    </Modal>
  );
}

export default function StartModal() {
  const [modalShow, setModalShow] = useState(false);
  const [projectBatchData,setProjectBatchData]=useState([]);
  const [domain,setDomain]=useState([]);
  const [batch,setBatch]=useState([]);

  useEffect(()=>{
    initiateData()
    checkModalLoad()
  },[])
  
  const initiateData=async()=>{
      await API.get('course/all').then(res=>{
        setDomain(res.data.data)
      })
      await API.get('batch/all').then(res=>{
        setBatch(res.data.data)
      })
  }

  const checkModalLoad=async()=>{
    await API.get('domainbatch/check').then(res=>{
        if(res.data.data.internship_course_id===0 || res.data.data.internship_batch_id===0){
          setProjectBatchData(res.data.data);
          setModalShow(true)
        }else{
          setModalShow(false)
        }
    })
  }     

  return (
    <>
      <MyVerticallyCenteredModal
        show={modalShow}
        data={projectBatchData}
        batchData={batch}
        domainData={domain}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

