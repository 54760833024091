// import React from 'react';
// import { Player } from 'video-react';

// export default props => {
//   return (
//     <Player
//       playsInline
//       poster="/assets/poster.png"
//       src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
//     />
//   );
// };
import React,{useEffect,useState} from 'react';
import "../../App.css"
import API from '../../Routes/api';

function VideoPlayer() {
  const [videoGuide,setVideoGuide] = useState([]);

  useEffect(() => {
    initiateData();
  }, [])

  const initiateData=async()=>{
    var video = document.getElementById('videoPlayer');
    await API.get('dashboard/video-guide').then(res=>{
        setVideoGuide(res.data.data)  ;
    })
    video.load();
  }
  const wrap={
    backgroundColor: "#f8f9fa",
    // padding: 20,
    // borderRadius: 10,
    // paddingTop: 10,
    // paddingBottom: 30
    marginTop: 0,
  borderRadius: 10,

    
};
const heading = {
  // marginBottom: 25  ,
  fontFamily: "Montserrat",
fontStyle: "normal",
fontWeight: "bold",
fontSize: 18,
marginTop: 2,
paddingTop: 0,
marginLeft: 10
}
const headStyle ={
  backgroundColor: "#3E7DC7",
  padding: "5px 5px 5px 5px",
  color: "#fff",
  borderRadius: 10,
  

}
const innerContain={
  paddingLeft: 30,
  padding: 25,
  paddingTop: 10,
}
  return (
    <div style={wrap}>
    <div style={headStyle}>
      <h3 style={heading}>Guide</h3>
    </div>
    <div style={innerContain}>
    <video width="300" controls id="videoPlayer">
        <source id="source" src={videoGuide} type="video/mp4" />
      </video>
    </div>
      
    </div>
  )
}
export default VideoPlayer