import React from 'react'

import Sidebar from '../Sidebar/Sidebar'

import Transaction from './Transaction';

function TransactionMain() {
    const heading={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,
    
      }
    return (
      <div className="row">
      <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
    <Sidebar />
    </div>
    <div className="col-10 col-lg-10 col-md-11 col-xs-11">
      <h4 style={heading}>Transaction</h4>
      <div className="col-md-12">
          <Transaction />
      </div>
     </div>
     </div>
      
    )
}

export default TransactionMain

