import React from "react";
import { Form } from "react-bootstrap";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DownloadIcon from '@mui/icons-material/Download';
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import API from '../../../Routes/api';
import Swal from 'sweetalert2';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ProgressBar from "../../Project/ProgressBar";

function InterProjectMain(props) {
  const [open, setOpen] = React.useState(false);
  const [message,setMessage]=React.useState();
  const [progressPercentage,setProgressPercentage]=React.useState(0);
  const [progressBarDisplay,setProgressBarDisplay] = React.useState("none")
  const handleOpen = (remarkMessage) => {
    var setRemarkMessage = remarkMessage!=='' && remarkMessage!== null && remarkMessage!== undefined ? remarkMessage : 'No Remark Added Yet!' 
    console.log(setRemarkMessage)
    setMessage(setRemarkMessage);
    setOpen(true);
  }
  const handleClose = () => {
    //setMessage('');
    setOpen(false);
  }
  function truncateRemark(string, limit){
    var dots = "...";
    if(string!==undefined && string.length > limit){
      string = string.substring(0,limit) + dots;
    }
    return string;
  }
  const triggerFileUpload = async(project_id) => {
    //e.preventDefault()
    const formData = new FormData();
    formData.append("project_file", document.getElementById("project_image_"+project_id).files[0]);
    formData.append('project_id',document.getElementById("project_"+project_id).value);
    formData.append('project_batch_id',document.getElementById("project_batch_id_"+project_id).value);
    formData.append('project_type','internship');
    if(document.getElementById("project_files_upload_id_"+project_id)){
      formData.append('project_files_upload_id',document.getElementById("project_files_upload_id_"+project_id).value);
    }
    formData.append('project_enrollment_id',document.getElementById("project_enrollment_id_"+project_id).value);
    const options = {
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        let percent = Math.floor( (loaded * 100) / total )
        console.log( `${loaded}kb of ${total}kb | ${percent}%` );
        setProgressPercentage(percent);
        setProgressBarDisplay("block")
        if( percent < 100 ){
          this.setState({ uploadPercentage: percent })
        }
      }
    }
    API.post('projects/upload',formData,options).then(res=>{
        if(res.data.code===200){
            Swal.fire({
                title: res.data.status,
                text: res.data.message,
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {
                window.location.href="/intern";
            })
        }
    }).catch(err=>{
      console.log(err.response.data.message.response.original.project_file[0])
      if(err.code==='ERR_BAD_REQUEST'){
          Swal.fire({
              title: "Unsupported File Format",
              text: err.response.data.message.response.original.project_file[0],
              icon: 'warning',
              confirmButtonText: 'OK'
          }).then((result) => {
            window.location.href="/intern";
          })
      }
    }) 
  };
  const progressBar ={
    display:"none"
  }
  const getStatus = (statuscode) =>{
    switch(statuscode){
      case 1  : return 'Submitted';
                
      case 2  : return 'Pending';
                
      case 3  : return 'Approved';
                
      case 4  : return 'In Progress';
                
      case 5  : return 'Rejected';

      case 6  : return 'Resubmitted';
                
      default : return 'Not Submitted';
    }
  }
  const para = {
    height: "90%",
    padding: 0,
    paddingBottom: 0,
  };
  const cont = {
    padding: 12,
    backgroundColor: "#F2F2F2",
    margin: 10,
    marginLeft: 0,
    borderRadius: 10,
    width: "100%",
    height: "90%",
    paddingBottom: 40,
  };
  const head = {
    fontWeight: "bold",
    marginBottom: 15,
  };
  const sub = {
    fontWeight: 500,
  };
  const sub1 = {
    fontWeight: 500,
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <div >
        <Form>
        
        {
          props.internship_project.map((val)=>{
            return(
              <div style={cont}>
                
                <h5 style={head}>{val.project_title} <small style={{fontSize:'15px'}}>({props.sub})</small></h5>
              
                <div className="row" style={para}>
                  <div className="col-md-4 mb-2">
                    <div className="d-flex">
                      <div>
                        <h6 style={sub}><b>Submission Date :</b>&nbsp;</h6>
                      </div>
                    <div>
                    <h6 style={sub1}>{val.submission_date !=='tbu'? moment(val.submission_date).format('DD/MM/YYYY'):'To be Updated'}{" "}</h6>
                  </div>
                </div>
                {val.submission_instruction_url!==null?<a style={{fontSize: "14px", marginBottom: "14px" }}href={val.submission_instruction_url} target="_blank" download>View Submission Instruction<span style={{marginLeft:"5px"}}><DownloadIcon style={{ color:"blue"}}/></span></a>:''}
                {
                  val.project_files_arr?.project_id === val.id &&  val.project_files_arr?.file!==null?
                  val.project_files_arr?.allow_reupload===0?
                  <div>
                    <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                      <b>Upload Internship Project: </b><br /> Project Uploaded
                    </h6>
                    <h6 style={{ fontSize: "14px", marginBottom: "14px",  }}>               
                      <b>View Project :</b>
                      <span style={{ marginLeft:"5px"}}>              
                        <a href={val.project_files_arr?.project_file_link} target="_blank"  download>  &nbsp; <DownloadIcon  style={{ color:"blue"}}/></a>
                      </span> 
                    </h6>                    
                  </div>
                  :
                  <div>
                    <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                      <b>Reupload Internship Project: </b><br /> <input type="file" id={"project_image_"+props.id} className="mt-2" onChange={()=>triggerFileUpload(props.id)} accept="application/msword, application/vnd.ms-powerpoint, application/pdf, application/zip, application/vnd.rar image/*"></input>
                      <input type="hidden" id={"project_"+props.id} value={props.id} ></input>
                      <input type="hidden" id={"project_enrollment_id_"+props.id} value={props.enrollment_id} ></input>
                      <input type="hidden" id={"project_files_upload_id_"+props.id} value={val.project_files_arr?.id} ></input>
                      <input type="hidden" id={"project_batch_id_"+props.id} value={props.batch_id} ></input>
                    </h6>
                    <ProgressBar completed={progressPercentage} display={progressBarDisplay}/>
                    <p  style={{ fontSize: "10px", marginBottom: "14px" }}>Max Upload File Size: <b>100MB</b></p>
                    <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                    <b> Last Submitted On :</b> {moment(val.project_files_arr?.created_at).format('Do MMMM')}
                    </h6>
                  </div>  
                  :
                    <div>
                      <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                        <b>Upload Internship Projects :</b>{" "} <br /> 
                        <input type="file" id={"project_image_"+val.id} className="mt-2" onChange={()=>triggerFileUpload(val.id)}  accept="application/msword, application/vnd.ms-powerpoint, application/pdf, application/zip, application/vnd.rar image/*"></input>
                        <input type="hidden" id={"project_"+val.id} value={val.id} ></input>
                        <input type="hidden" id={"project_enrollment_id_"+val.id} value={props.enrollment_id} ></input>
                        <input type="hidden" id={"project_batch_id_"+val.id} value={props.batch_id} ></input>
                      </h6>
                      <ProgressBar completed={progressPercentage} display={progressBarDisplay}/>
                      <p  style={{ fontSize: "10px", marginBottom: "14px" }}>Max Upload File Size: <b>100MB</b></p>
                    </div>  
                  }
              </div>
              <div className="col-md-8 mb-2" style={{paddingLeft:"10px"}}>
                <div>
                  <div>
                    <h6>
                      <span>
                      <CalendarTodayIcon />
                      </span>  
                      {}  
                      {moment(props.start).format('DD MMM')} - {moment(props.end).format('DD MMM')}  
                    </h6>
                  </div>
                  <div>
                  {val.project_files_arr?.status ?
                    <h6 style={{ fontSize: "14px" }}>
                      <b>Status :</b> {getStatus(val.project_files_arr?.status)}
                    </h6>
                    :
                    ''
                  }
                  </div>
                  {
                  val.project_files_arr?.score!=='' && val.project_files_arr?.score!==null && val.project_files_arr?.score!==undefined?
                    <h6 style={{ fontSize: "14px", marginBottom: "5px" }}>
                      <b>Score :</b> {val.project_files_arr?.score!=='' && val.project_files_arr?.score!==null && val.project_files_arr?.score!==undefined?val.project_files_arr?.score+' out of 10':'To be Updated'}
                    </h6>
                    :
                    ''
                  }
                  
                  {
                    val.project_files_arr?.status !== 6 ? 
                      val.project_files_arr?.created_at !=='' && val.project_files_arr?.created_at !==null && val.project_files_arr?.created_at !== undefined?
                        <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                          <b>Submitted On :</b> {val.project_files_arr?.created_at !=='' && val.project_files_arr?.created_at !==null && val.project_files_arr?.created_at !== undefined ?moment(val.project_files_arr?.created_at).format('DD/MM/YYYY'):'N/A'}
                        </h6>
                        :
                        ''
                      :
                        val.project_files_arr?.updated_at !=='' && val.project_files_arr?.updated_at !==null && val.project_files_arr?.updated_at !== undefined?
                          <h6 style={{ fontSize: "14px", marginBottom: "14px" }}>
                            <b>Resubmitted On :</b> {val.project_files_arr?.updated_at !=='' && val.project_files_arr?.updated_at !==null && val.project_files_arr?.updated_at !== undefined ?moment(val.project_files_arr?.updated_at).format('DD/MM/YYYY'):'N/A'}
                          </h6>
                          :
                          ''
                  }
                  {val.project_files_arr?.remarks !=='' && val.project_files_arr?.remarks !==null && val.project_files_arr?.remarks !== undefined?
                    <h6 style={{ fontSize: "14px" }}>
                      <b>Remark :</b> <div  onClick={()=>handleOpen(val.project_files_arr?.remarks)} >{truncateRemark(val.project_files_arr?.remarks.replace(/<[^>]+>/g, ''),20) }</div>
                    </h6>
                    :
                    ''
                  }
                </div>
              </div>
            </div>
            </div>
          )
        })}
        </Form>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                View Remark
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }} dangerouslySetInnerHTML={{__html : message}}>
              </Typography>
            </Box>
          </Modal>
      </div>
    </div>
  );
}

export default InterProjectMain;
