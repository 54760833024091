import React from 'react'
import LMSaccor from './LMSaccor';
import ProgramDrop from './../../Queries/ProgramDrop';

function LMSsidebar() {
  return (
    <div>
        <div className="row">
            <div className="col-md-12">
                <ProgramDrop />
            </div>
            
        </div>
        <LMSaccor />
    </div>
  )
}

export default LMSsidebar