import React from 'react';
import { Link } from 'react-router-dom';
import QueriesDashaccordion from './QueriesDashaccordion';

function QueriesDashboard() {

       const contain = {
    backgroundColor: "#fff",
    // height: "50%",
    // height: 210,
    borderRadius: 10,
    paddingLeft: 30,
    padding: 25,
    paddingTop: 10
  };
  const icon = {
    backgroundColor: "rgba(62, 125, 199, 0.5)",
    borderRadius: 15,
    marginRight: 15,
    padding: 2
  };
  const heading = {
    marginBottom: 20,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
    marginTop: 2,
  };
  const text={
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 15
  }
  return (
    <div style={contain} className="rowone_dash">
      <div className='d-flex justify-content-between'>
      <h2 style={heading}> Queries </h2>
      <Link to="/queries" style={{textDecoration: "none"}}>More..</Link>
      </div>
      <QueriesDashaccordion />
    </div>
  );
}

export default QueriesDashboard;
