const ProgressBar = (props) => {
    const { bgcolor, completed } = props;
  
    const containerStyles = {
        display:props.display,
        height: 13,
        width: '100%',
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        margin: 0
    }
  
    const fillerStyles = {
        fontSize: "10px",
        height: '100%',
        width: `${completed}%`,
        backgroundColor: "#1907F9",
        borderRadius: 'inherit',
        textAlign: 'center'
    }
  
    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <span style={labelStyles}>{`${completed}%`}</span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;