import React from "react";
import Cart from "../../Assets/Cart.png";

function CartDetails() {
  const contain = {
    backgroundColor: "#fff",
    marginRight: 20,
    padding: 25,
    borderRadius: 15,
  };
  const cart_img = {
    padding: 10,
  };
  const course_name = {
    marginTop: 15,
  };
  const del = {
    color: "red",
    marginRight: 20,
  };
  const icons = {
    paddingTop: 20,
  };
  const btn = {
    height: 40,
    width: 280,
    backgroundColor: "#FF6906",
    marginTop: 10,
    color: "#fff"
  };
  const column2 = {
    marginLeft: 40,
    padding: 20,
    // marginRight: 40,
  };
  return (
    <div style={contain}>
      <h5>1 Project in Cart</h5>
      <div className="row">
        <div className="col-md-8 border" >
          <div className="d-flex flex-row justify-content-between ">
            <div className="d-flex flex-row ">
              <img
                src={Cart}
                alt="cart_image"
                height="150"
                width="150"
                style={cart_img}
              />
              <div className="d-flex flex-column ">
                <div>
                  <h5 style={course_name}>Artificial Inteligence</h5>
                  <h6>By Languify</h6>
                </div>
                <div style={icons}>
                  <i className="fas fa-trash-alt" style={del}>
                    {" "}
                    Remove
                  </i>
                  <i className="far fa-bookmark "> Save for Later</i>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h4 style={{ color: "#3E7DC7", paddingTop: 50 }}>Rs. 2999/-</h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 border" style={column2}>
          <h6>Total: </h6>
          <div className="d-flex flex-row justify-content-between">
            <h6>Project Total:</h6>
            <h4>Rs. 2999/-</h4>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <h6>Taxes:</h6>
            <h6>Rs. 500/-</h6>
          </div>
          <hr />
          <div className="d-flex flex-row justify-content-between">
            <h5>Grand Total:</h5>
            <h5>Rs. 3499/-</h5>
          </div>
          <button className="btn btn " style={btn}>
            <h5 >Checkout</h5>{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartDetails;
