import React from 'react'
import { Tabs,Tab} from 'react-bootstrap';
import Projects from './Projects';
import Instructor from './Instructor';
import Inst from "../../Assets/Instructor.png"
import Inst1 from "../../Assets/Instructor2.png"
import Inst2 from "../../Assets/Instructor3.png"
import { Link } from 'react-router-dom';
function DetailTab() {
    const cont={
    margin: 10,
    borderRadius: 10,
    padding: 10,
    height: "90%",
    };
    const tab={
        fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 500,
    };

      const box = {
          fontFamily: "Montserrat",
          padding: 10,
          background: "#007BBC4D",
          color:'#000',
          textDecoration: 'none',
          marginRight:10,
          marginBottom:10
      }
    return (
        <div style={cont} className="d-flex flex-column">
            <Tabs defaultActiveKey="Description" id="uncontrolled-tab-example" className="mb-3 " style={tab}>
  <Tab eventKey="Description" title="Description" >
    <p style={{padding:20}}><i>Dummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy Text Dummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy Text
        Dummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy TextDummy Text Dummy.</i></p>
    <h5 style={{padding:20}}><b>About Company</b></h5>
    <p style={{padding:20}}><i>Dummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy Text Dummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy Text
        Dummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy TextDummy Text Dummy Text Dummy TextDummy Text Dummy.</i></p>
    <h5 style={{padding:20}}><b>What you will learn?</b></h5>
      <ol>
          <li>Dummy Text about what student will learn.</li>
          <li>Dummy Text about what student will learn.</li>
          <li>Dummy Text about what student will learn.</li>
          <li>Dummy Text about what student will learn.</li>
          <li>Dummy Text about what student will learn.</li>
      </ol>
    <h5 style={{padding:20}}><b>Skill you will gain</b></h5>
      <div className="d-flex flex-column" style={{paddingLeft:20}} >
          <div className="d-flex flex-row text-center" >
              <Link to="/" style={box} ><b>Data Visualization</b></Link>
              <Link to="/" style={box} ><b>Data Visualization</b></Link>
          </div>
          
          <div className="d-flex flex-row text-center">
          <Link to="/" style={box} ><b>Data Visualization</b></Link>
          <Link to="/" style={box} ><b>Data Visualization</b></Link>
          </div>
      </div>
      
  </Tab>
  <Tab eventKey="Projects" title="Projects">
  <Projects/>
  </Tab>
  <Tab eventKey="Instructors" title="Instructors">
  <Instructor 
    image={Inst}
    name="Jessica D’souza"
  />
  <Instructor 
    image={Inst1}
    name="Ram dz"
    />
  <Instructor 
    image={Inst2}
    name="Ravi Kumar"
  />
  </Tab>
</Tabs>
        </div>
    )
}

export default DetailTab
