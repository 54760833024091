import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import API from '../../Routes/api';
import "../../App.css"

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' , color: "#fff"}} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function QueriesDashaccordion() {
  const [expanded, setExpanded] = React.useState(' ');
  const [queries,setQueries]                = React.useState([]);

  React.useEffect(() => {
    fetchDashboardQueries();
  }, [])
  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const fetchDashboardQueries = async() =>{    
      await API.get('queries?page=dashboard').then(res=>{
        setQueries(res.data.data.data);
      })
  }

  const drop = {
    backgroundColor: "#0B5ED7" ,
    color: "#fff"
  }
  return (
    <div>
      {
      
        queries.length>0?queries.map((val)=>
        <Accordion expanded={expanded === val.id} onChange={handleChange(val.id)}>
          <AccordionSummary aria-controls={'panel1d-content'+val.id} id={'panel1d-header'+val.id} style={drop}>
            <Typography variant="h6" className='rowtwo_accor'>{val.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="h6" className='rowtwo_accor'>
              {val.answer!==null?val.answer:'Not Answered Yet'}
            </Typography>
          </AccordionDetails>
        </Accordion>
        ):'No Queries Added Yet'
      }
      
      
    </div>
  );
}
