import React from 'react'

function JobMain() {
    return (
        <div>
            Job
        </div>
    )
}

export default JobMain
