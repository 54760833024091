import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";
import Image from "../../Assets/logo.png";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import API from '../../Routes/api';

function Navbar() {
  const [click, steClick] = useState(false);
  const destroyTokenRedirect  = (e) => {
    e.preventDefault();
    API.post('logout');
    localStorage.removeItem("token");
    localStorage.removeItem("video_file")
    localStorage.removeItem("batch_id")
    localStorage.removeItem("fileType")
    localStorage.removeItem("course_id")
    localStorage.removeItem("video_id")
    localStorage.removeItem("enrollment_id")
    localStorage.removeItem("video_title")
    localStorage.removeItem("dashboardToLmsBatchId")
    window.location=process.env.REACT_APP_HOME_URL;
  }
  const handleClick = () => steClick(!click);

  const logo = {
    paddingBottom: 25,
    display: "flex",
    flexDirection: "column",
  };
  const text = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
    marginRight: 20,
  };
  const text1 = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 800,
    marginRight: 35,
  };
  const pro = {
    textDecoration: "none",
  };

  return (
    <>
      <div className="navbar">
      <div className="nav-logo">
      <Link
          exact
          to="/dashboard"
          //  activeClassName="active"
          // className="nav-logo"
        >
          <img
          
            src={Image}
            className="img-fluid"
            alt="logo"
            height="80"
            width="200"
            style={logo}
          />
        </Link>
      </div>
        
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          {/* <NavLink
            exact
            to="/notification"
            className="nav-links"
            onClick={handleClick}
            style={text}
          > */}
          <div style={text1}>
          <i className="fal fa-bell"></i>

          </div>
          {/* </NavLink> */}
          {/* <NavLink
            exact
            to="/message"
            className="nav-links"
            onClick={handleClick}
            style={text}
          > */}
          <div style={text}>
          <i className="fal fa-comment-alt" ></i>
          </div>
            
          {/* </NavLink> */}
          <NavLink
            exact
            to="#"
            className="nav-links"
            onClick={handleClick}
            style={text}
          >
            <li className="nav-item dropdown d-flex justify-content-center" >
              <Link
                className="nav-link dropdown-toggle d-flex"
                to="#"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ color: "#000" }}
              >
              <div style={{marginTop: "-8px", marginRight: "10px"}} >
              <h6>{localStorage.getItem("name")}</h6>
              </div>
                
              </Link>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink
                  exact
                  to="/profile"
                  className=""
                  onClick={handleClick}
                  style={pro}
                >
                  <Link Name="dropdown-item" to="/profile" href="/profile">
                  <p style={{fontWeight: 500, marginLeft: 10}}>
                  <PersonIcon /> Profile
                  </p>
                  
                  </Link>
                </NavLink>
                <div className="dropdown-divider"></div>

                <NavLink
                  exact
                  to="/transaction"
                  className=""
                  onClick={handleClick}
                  style={pro}
                >
                  <Link Name="dropdown-item" to="/transaction" href="/transaction">
                  <p style={{fontWeight: 500, marginLeft: 10}}>
                  <AccountBalanceWalletIcon /> Transaction
                  </p>
                  
                  </Link>
                </NavLink>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" href="" onClick={destroyTokenRedirect}>
                  <i className="fas fa-sign-out-alt"></i> Logout
                </Link>
              </div>
            </li>
          </NavLink>
        </ul>
        <div className="nav-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </div>
    </>
  );
}

export default Navbar;
