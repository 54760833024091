import React, { useState, useEffect } from "react";
import LMSsidebar from './LMSside/LMSsidebar'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VideoPlayer from './LMSside/VideoPlayer';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import ProjectData from './../Data/ProgramData';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Link } from 'react-router-dom';

import { Button, Accordion, ProgressBar } from 'react-bootstrap';
import API from '../../Routes/api';
import DownloadIcon from '@mui/icons-material/Download';
import jquery from "jquery";


function LmsMain() {
  // document.addEventListener('vooPlayerReady', vooPlayerReady, false);
  // var playButton = document.getElementById("playButton");
  // playButton.onclick = handlePlay;
  // function vooPlayerReady(){
  //   // spotlightrAPI(playerId, 'getDuration', null, getDurationCallback);
  //   spotlightrAPI(playerId, 'getTime', ['3','6'], getTimeCallback)
 
  // }
  const [batch, setBatch] = useState([])
  const [selectedBatch, setSelectedBatch] = useState([])
  const [videos, setVideos] = useState([])
  const [batchTitle, setBatchTitle] = useState([])
  const [videoFile, setVideoFile] = useState("https://media.w3.org/2010/05/sintel/trailer_hd.mp4");
  const [enrollmentId, setEnrollmentId] = useState([]);
  const [progress, setProgress] = useState([]);
  const [previousVideoObject, setPreviousVideoObject] = useState([]);
  const [nextVideoObject, setNextVideoObject] = useState([]);
  const [playingPlayerId, setPlayingPlayerId] = useState(btoa(1437346));
  useEffect(() => {
    fetchMyBatches();
    if (localStorage.getItem('video_file') !== undefined && localStorage.getItem('video_file') !== null || localStorage.getItem('spotlightr_id') !== undefined && localStorage.getItem('spotlightr_id') !== null ) {
      playLoadedVideo();
    }else{
      localStorage.setItem('spotlightr_id',1437346)
      playLoadedVideo();
    }
    document.getElementById('nextButton').disabled = true
    document.getElementById('previousButton').disabled = true
    checkScreenSize()
  }, [])
 
  const vooPlayerReady=()=>{
    window.spotlightrAPI(playingPlayerId, 'getDuration', null, getDurationCallback);
  }
  const play=()=>{
    window.spotlightrAPI(playingPlayerId, 'play')
  }

  const pause=()=>{
    window.spotlightrAPI(playingPlayerId, 'pause')
  }
  const getDurationCallback=(duration)=>{
    document.getElementById('durationDisplay').innerText=duration
  }
  useEffect(() => {

    const script = document.createElement('script');  
    script.src ='https://videos.cdn.spotlightr.com/assets/spotlightr.js';

    document.body.appendChild(script);
    script.addEventListener('load',function(){
      document.addEventListener('vooPlayerReady', vooPlayerReady, false);

    })
    return () => {
      document.body.removeChild(script);
    }

  }, []);
  jquery(window).resize(function () {
    checkScreenSize()
  });

  window.addEventListener("load", doStartup, false);

  function doStartup(event) {
    document.fullscreenElement = document.fullscreenElement || document.mozFullscreenElement
      || document.msFullscreenElement || document.webkitFullscreenDocument;
    document.exitFullscreen = document.exitFullscreen || document.mozExitFullscreen
      || document.msExitFullscreen || document.webkitExitFullscreen;

    document.addEventListener("keypress", handleKeypress, false);
  }

  function handleKeypress(event) {
    if (event.keyCode === 13) {
      toggleFullscreen();
    }
  }


  function toggleFullscreen() {
    let elem = document.getElementById("videoPlayer");

    elem.requestFullscreen = elem.requestFullscreen || elem.mozRequestFullscreen
      || elem.msRequestFullscreen || elem.webkitRequestFullscreen;

    if (!document.fullscreenElement) {
      elem.requestFullscreen().then({}).catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }
  function checkScreenSize() {
    var w = window.innerWidth;
    let elem = document.getElementById("videoPlayer");

    elem.requestFullscreen = elem.requestFullscreen || elem.mozRequestFullscreen || elem.msRequestFullscreen || elem.webkitRequestFullscreen;
    if (!document.fullscreenElement) {
      if (w < 600) {
        jquery(".bottom").after(jquery(".top"));
      } else {
        jquery(".top").after(jquery(".bottom"));
      }
    }
  }

  function getFileNamewithExtension(associated_file_url) {
    const split_string = associated_file_url.split("/");
    var filename = split_string[(split_string.length) - 1];
    //console.log(filename);
    return filename
  }

  const handleDomainClassChange = async (e) => {
    fetchVideosFromBatch(e.target.value);
    setSelectedBatch(e.target.value);
    sessionStorage.removeItem('playingVideoParent');
    localStorage.removeItem('video_file');
    clearVideoSource();
  }
  function clearVideoSource() {
    var video = document.getElementById('videoPlayer');
    var source = document.getElementById('videoSource');
    video.pause();
    video.currentTime = 0;
    source.setAttribute('src', '');
    document.getElementById("video-chapter-title").innerHTML = '';
    video.load();
  }
  const fetchMyBatches = async () => {
    await API.get('schedule/batches').then(res => {
      setBatch(res.data.data.batches);
      if (localStorage.getItem('dashboardToLmsBatchId') !== undefined && localStorage.getItem('dashboardToLmsBatchId') !== null) {
        fetchVideosFromBatch(localStorage.getItem('dashboardToLmsBatchId'));
        setSelectedBatch(localStorage.getItem('dashboardToLmsBatchId'));
      } else {
        fetchVideosFromBatch(res.data.data.first_batch_id);
        setSelectedBatch(res.data.data.first_batch_id)
      }
    })
  }
  const fetchVideosFromBatch = async (id) => {
    await API.get('lms/videos?batch_id=' + id).then(res => {
      setVideos(res.data.data.videos);
      setBatchTitle(res.data.data.batch);
      setEnrollmentId(res.data.data.enrollment_id);
      setProgress(res.data.data.progress);
    })
    localStorage.removeItem('dashboardToLmsBatchId')
  }

  const updateProgressBar = async (id) => {
    await API.get('lms/videos?batch_id=' + id).then(res => {
      setProgress(res.data.data.progress);
    })
  }

  function setLoadedVideo(video_id, course_id, video_title, enrollment_id, batch_id, fileType, video_file,spotlightr_id) {
    localStorage.setItem('video_id', video_id)
    localStorage.setItem('course_id', course_id)
    localStorage.setItem('video_title', video_title)
    localStorage.setItem('enrollment_id', enrollment_id)
    localStorage.setItem('batch_id', batch_id)
    localStorage.setItem('fileType', fileType)
    localStorage.setItem('video_file', video_file)
    localStorage.setItem('spotlightr_id', spotlightr_id)
  }
  function playLoadedVideo() {
    //console.log('Data from Even '+e.target.getAttribute('data-video'))
    var video_id = localStorage.getItem('video_id')
    var course_id = localStorage.getItem('course_id')
    var video_title = localStorage.getItem('video_title')!==null && localStorage.getItem('video_title')!==undefined && localStorage.getItem('video_title')!== "" ? localStorage.getItem('video_title') : ""
    var video_file = localStorage.getItem('video_file')
    var enrollment_id = localStorage.getItem('enrollment_id')
    var batch_id = localStorage.getItem('batch_id')
    var spotlightr_id= localStorage.getItem('spotlightr_id')!== null && localStorage.getItem('spotlightr_id') !== undefined && localStorage.getItem('spotlightr_id')!== "" ? localStorage.getItem('spotlightr_id') : "1437346";
    updateWatchedVideoCount(video_id, course_id, enrollment_id, batch_id);
    const playerId= btoa(spotlightr_id);
    const videoHost= 'https://videos.cdn.spotlightr.com/';
    const iframeSrc = videoHost+"watch/"+ playerId;
    const iframeSrcFallback = videoHost+"watch/"+ playerId + "?fallback=true";
    var video = document.getElementById('videoPlayer');
    var source = document.getElementById('videoPlayer');
    setVideoFile(video_file)
    source.setAttribute("src", iframeSrcFallback)
    source.setAttribute('data-playerid',playerId)
    document.getElementById('video-chapter-title').innerHTML = video_title;
    var fileType = video_file != null ? video_file.split('.').pop() : '';
    // video.setAttribute("type", "video/" + fileType);
    // video.load();
    // video.play();
    updateProgressBar(batch_id);
    setLoadedVideo(video_id, course_id, video_title, enrollment_id, batch_id, fileType, video_file,spotlightr_id)
    //console.log('LMS Main '+videoFile);
   // const splapi = window.spotlightr;
   
    
  }
  const startPolling = () => {
    const iframe = document.getElementById("videoPlayer");
    iframe.onload = () => {
      if (iframe.querySelector("...")) {
        console.log('react is ready')
        //return;
      }
    }
  }
  
  function setVideoHighlight(id) {
    //console.log(id)

    var getPlayingVideoParent = sessionStorage.getItem('playingVideoParent')
    if (getPlayingVideoParent !== null && getPlayingVideoParent !== undefined && getPlayingVideoParent !== '') {
      if (document.getElementById(getPlayingVideoParent) !== undefined && document.getElementById(getPlayingVideoParent) !== null) {
        document.getElementById(getPlayingVideoParent).style.color = 'black';
        document.getElementById(id).style.color = 'blue';
        sessionStorage.setItem('playingVideoParent', id);
      } else {
        document.getElementById(id).style.color = 'blue';
        sessionStorage.setItem('playingVideoParent', id);
      }
    } else {
      document.getElementById(id).style.color = 'blue';
      sessionStorage.setItem('playingVideoParent', id);
    }
  }
  function openQuiz(link){
    if(link !== '' || link !== null ){
      window.open(link,'_blank');
     // window.location.href = link;
    }
  }
  function setPlayingVideo(e) {
    //console.log('Data from Even '+e.target.getAttribute('data-video'))
    // var element = document.getElementsByClassName("playingVideo")
    // console.log(element);
    // if(element.length > 0){
    //   element.classList.remove("playingVideo");
    // }
    if (e.target.parentNode.classList.contains('rewatch')) {
      setVideoHighlight(e.target.parentNode.parentNode.parentNode.getAttribute('id'))
    } else {
      setVideoHighlight(e.target.parentNode.getAttribute('id'))
    }
    var video_id = e.target.getAttribute('data-video_id');
    var course_id = e.target.getAttribute('data-course_id');
    var video_title = e.target.getAttribute('data-title');
    var video_file = e.target.getAttribute('data-video');
    var spotlightr_id = e.target.getAttribute('data-sp_video_id');
    var previous_video_object = e.target.getAttribute('data-previous_video_object')
    setPreviousVideoObject(previous_video_object)
    var next_video_object = e.target.getAttribute('data-next_video_object')
    setNextVideoObject(next_video_object)
    //console.log(next_video_object,previous_video_object)
    var enrollment_id = enrollmentId;
    var batch_id = selectedBatch;
    updateWatchedVideoCount(video_id, course_id, enrollment_id, batch_id);
    const playerId= btoa(spotlightr_id);
    const videoHost= 'https://videos.cdn.spotlightr.com/';
    const iframeSrc = videoHost+"watch/"+ playerId;
    const iframeSrcFallback = videoHost+"watch/"+ playerId + "?fallback=true";
    var video = document.getElementById('videoPlayer');
    var source = document.getElementById('videoPlayer');
    setVideoFile(e.target.getAttribute('data-video'))
    //source.setAttribute("src", e.target.getAttribute('data-video'))
    source.setAttribute("src", iframeSrcFallback)
    document.getElementById('video-chapter-title').innerHTML = video_title;
    var fileType = e.target.getAttribute('data-video').split('.').pop();
    // video.setAttribute("type", "video/" + fileType);
    // video.load();
    // video.play();
    updateProgressBar(batch_id);
    setLoadedVideo(video_id, course_id, video_title, enrollment_id, batch_id, fileType, video_file,spotlightr_id)
    updateNextPreviousButtons(previous_video_object, next_video_object);
    //console.log('LMS Main '+videoFile);
    
    updatePlayerId(playerId)
    console.log(playerId)
    triggerVideoPlayerPlay()
  }

  const updatePlayerId = (playerId) =>{
    setPlayingPlayerId(playerId)
  } 

  const triggerVideoPlayerPlay = () => {
    play()
  }

  const updateWatchedVideoCount = async (video_id, course_id, enrollment_id, batch_id) => {
    await API.put('lms/videos/watched?batch_id=' + batch_id + '&course_id=' + course_id + '&enrollment_id=' + enrollment_id + '&video_id=' + video_id).then(res => {
      //console.log('updated')
    })
  }

  const updateNextPreviousButtons = (previous_video_object, next_video_object) => {
    //console.log("NVB"+next_video_object)
    //console.log("PVB"+previous_video_object)
    var next_button = document.getElementById('nextButton')
    var previous_button = document.getElementById('previousButton')
    if (previous_video_object === null) {
      previous_button.disabled = true;
    } else {
      previous_button.disabled = false;
    }

    if (next_video_object === null) {
      next_button.disabled = true;
    } else {
      next_button.disabled = false;
    }
  }
  const renderQuizButton = (parent_object) =>{
    if(parent_object.quiz_link !== '' && parent_object.quiz_link !== undefined && parent_object.quiz_link !== null){
      return (
              <div className="row">
                <div className="col-md-8">
                  <h5>{parent_object.quiz_title}</h5>
                  <p style={sub}>{parent_object.quiz_subtitle}</p>
                </div>
                <div className="col-md-4 d-flex justify-content-end">
                                  
                  <Button variant="outline-primary" onClick={()=>{openQuiz(parent_object.quiz_link)}}>Attempt Quiz</Button>
                </div>
              </div>)
    } 
  }
  const getPrevious = () => {
    var previous_video = JSON.parse(previousVideoObject)
    var data_id = previous_video.video_id
    var data_spotlightr_id = previous_video.sp_video_id
    //console.log(previous_video.video_id)
    document.querySelector('[data-video_id="' + data_id + '"]').click();
    document.querySelector('[data-sp_video_id"' + data_spotlightr_id + '"]').click();
  }
  const getNext = () => {
    var next_video = JSON.parse(nextVideoObject);
    var data_id = next_video.video_id
    var data_spotlightr_id = next_video.sp_video_id
    //console.log(next_video.video_id)
    document.querySelector('[data-video_id="' + data_id + '"]').click();
    document.querySelector('[data-sp_video_id"' + data_spotlightr_id + '"]').click();
  }
  const bar = {
    fontSize: 8,
    // width: 250,
    marginBottom: 20,
    color: "#103FAB",
  };
  const sub = {
    fontSize: "12px",
    fontWeight: 600,
  }
  const watched = {
    marginRight: "10px",
    color: "#157347",
    fontWeight: 600,
  }
  const reupload = {
    color: "#FFC107",
    fontWeight: 600,
  }

  const playingVideo = {
    color: "#00BFFF"
  }
  const now = progress;
  return (
    <div>
      <div className="row m-0" id='desk'>
        <div className="col-md-4 top" style={{ height: "80vh", overflow: "hidden", overflowY: "scroll" }}>
        <div className="back_program">
        <div onClick={() => {
            window.location.pathname = "/"}}>
        <ArrowBackIcon />Program Page
        </div>
        </div>
          <div>
            <div className="row">
              <div className="col-md-12">
                <Box sx={{ minWidth: 120, maxWidth: 250, marginTop: 1, marginBottom: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                      Domain
                    </InputLabel>
                    <NativeSelect
                      defaultValue={30}
                      inputProps={{
                        name: 'course',
                        id: 'uncontrolled-native',
                      }}
                      onChange={handleDomainClassChange}
                      value={selectedBatch}
                    >
                      {
                        batch.map((val) =>
                          <option value={val.id}>{val.title}</option>
                        )
                      }

                    </NativeSelect>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div>
                      <h4>{batchTitle}</h4>
                      <ProgressBar now={now} label={`${Math.round(now)}%`} style={bar} className="d-flex flex-column dash_bar" />
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {videos.map((parent) => {
                      return (
                        <Accordion defaultActiveKey="1">
                          <Accordion.Item eventKey={parent.id}>
                            <Accordion.Header style={{ color: '#36394D' }}><h5>{parent.title}</h5></Accordion.Header>
                            <Accordion.Body>
                              <div>
                                {
                                  parent.children.map((child, index) => {
                                    var previous_index = index - 1;
                                    var next_index = index + 1;
                                    //console.log(child.internship_course_video_title.replace(/ /g,"_").toLowerCase()+"_"+index);
                                    return (
                                      <div>
                                        <div>
                                          <a href="#" style={{ textDecoration: "none", color: "#36394D" }} id={child.internship_course_video_title.replace(/ /g, "_").toLowerCase() + "_" + index}>
                                            <h5 onClick={setPlayingVideo} data-previous_video_object={previous_index !== -1 ? JSON.stringify(parent.children[previous_index]) : null} data-next_video_object={JSON.stringify(parent.children[next_index])} data-video_id={child.id} data-sp_video_id={child.sp_video_id}  data-course_id={child.course_id} data-video={child.video_exact_url} data-title={child.internship_course_video_title}>{child.internship_course_video_title} {child.associated_file_url !== null ? <a style={{ float: "right" }} href={process.env.REACT_APP_LMS_FILE_URL+'?file_url='+child.associated_file_url+'&file_name='+child.original_filename} target="_blank" data-toggle="tooltip" data-placement="left" title={"Required " + child.associated_file_title} download><DownloadIcon style={{ color: "blue" }} /></a> : ''}</h5>
                                            {/* <h5><PictureAsPdfIcon />PDF Notes</h5>*/}
                                            {/* { child.quiz_link ? <Button variant="outline-primary" onClick={()=>{openQuiz(child.quiz_link)}}>Attempt Quiz</Button> : ''} */}
                                            <div className="row m-0 p-0">
                                              <div className="col-md-4 d-flex justify-content-start">
                                                <p style={sub}>VIDEO . {child.running_time}</p>
                                              </div>
                                              <div className="col-md-8 d-flex justify-content-end rewatch">
                                                <div style={watched} onClick={setPlayingVideo} data-previous_video_object={previous_index === -1 ? null : parent.children.previous_index} data-next_video_object={parent.children.next_index} data-video_id={child.id} data-sp_video_id={child.sp_video_id} data-course_id={child.course_id} data-video={child.video_exact_url} data-title={child.internship_course_video_title}>{child.video_watched === true ? 'Watched' : ''} &nbsp; {child.video_watched === true ? 'Rewatch' : ''}</div>
                                                {/* {child.video_watched === true? <p style={watched}>Watched</p> : ''} */}
                                                {child.reuploaded_video === true ? <p style={reupload}>Reuploaded</p> : ''}
                                              </div>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                                {renderQuizButton(parent)}                                
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      )
                    })
                    }
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

            </div>
          </div>
        </div>
        <div className="col-md-8 bottom">
          <h5 id="video-chapter-title"></h5>
          <VideoPlayer />
          <div className='d-flex text-center justify-content-center'>
            <Button variant="warning" id="previousButton" style={{ marginRight: 10 }} onClick={getPrevious}><ArrowBackIcon /> Previous</Button>
            <Button variant="success" id="nextButton" onClick={getNext}>Next <ArrowForwardIcon /></Button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default LmsMain