import React from 'react'

function Instructor(prop) {
    const sub_contain={
        paddingTop: 15,
        paddingLeft: 20
    };
    const contain={
        marginLeft: 50,
        paddingBottom: 20
    }
    return (
        <div className="d-flex text-center" style={contain}>
            <img src={prop.image} alt="instructor" />
            <div style={sub_contain}>
            <h3 >{prop.name}</h3>
            <h6>Trainer, Languify</h6>
            </div>
        </div>
    )
}

export default Instructor
