import React from 'react'
import { Table,Card } from 'react-bootstrap';
import Sidebar from "./../Sidebar/Sidebar";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import DeleteIcon from '@mui/icons-material/Delete';
import RefferalTable from './RefferalTable';
import Clipboard from './Clipboard';

function RefferalMain() {
    const heading = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        paddingTop: 20,
      };
      const add = {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        margin:20,
        marginLeft: 0
      };
      const remove = {
        textAlign:"center"
      };
  return (
      
        <div className="row m-0 p-0">
            <div className="col-2 col-lg-2 col-md-1 col-xs-1 side">
            <Sidebar />
            </div>
        <div className="col-10 col-lg-10 col-md-11 col-xs-11">
        <h4 style={heading}>Referral</h4>
        <div
          className="modal"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Refferal Link
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={window["close"]}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                <Clipboard 
                field="https://forms.gle/zbjAccMJMQydmf2CA"
              />
                </div>
              </div>
              
                <div className='d-flex justify-content-between p-4'>
                    <a href="http://instagram.com/_u/{USERNAME}/" target="_blank" rel="noopener noreferrer"><InstagramIcon style={{color: "#8a3ab9"}}/></a>
                    <a href="#" target="_blank" rel="noopener noreferrer"><LinkedInIcon style={{color: "#0e76a8"}}/></a>
                    <a href="#" target="_blank" rel="noopener noreferrer"><TwitterIcon style={{color: "#00acee"}}/></a>
                    <a href="#" target="_blank" rel="noopener noreferrer"><FacebookIcon style={{color: "#3b5998"}}/></a>
                    <a href="#" target="_blank" rel="noopener noreferrer"><WhatsAppIcon style={{color: "#075e54"}}/></a>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={window["close"]}
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
                  Save changes
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <button
              type="button"
              className="btn btn-outline-primary"
              style={add}
              data-toggle="modal"
              data-target="#exampleModalCenter"
              onClick={window["notes"]}
            >
              + Add Referral
            </button>
            <Card>
              <RefferalTable />
            </Card>
        {/* <Card>
            <Table striped bordered hover>
            <thead>
                <tr>
                <th>#</th>
                <th>Name</th>
                <th>Department</th>
                <th>College Name</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Remove Member</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Mech</td>
                <td>Oxford</td>
                <td><a href="tel:+918546213126">8546213126</a></td>
                <td><a href="mailto:mark@email.com">mark@email.com</a></td>
                <td style={remove}><button className="btn btn-danger"><DeleteIcon/></button></td>
                </tr>
                <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Comp</td>
                <td>Chirst</td>
                <td><a href="tel:+919874561236">9874561236</a></td>
                <td><a href="mailto:jocob@email.com">jocob@email.com</a></td>
                <td style={remove}><button className="btn btn-danger"><DeleteIcon/></button></td>
                </tr>
                <tr>
                <td>3</td>
                <td>Larry</td>
                <td>Civil</td>
                <td>DU</td>
                <td><a href="tel:+918484867661">8484867661</a></td>
                <td><a href="mailto:larry@email.com">larry@email.com</a></td>
                <td style={remove}><button className="btn btn-danger"><DeleteIcon/></button></td>
                </tr>
            </tbody>
            </Table>
        </Card> */}
        </div>
        </div>
  )
}

export default RefferalMain