import React,{useState,useEffect} from "react";
import {Link} from "react-router-dom"
import TrendingData from "../Data/TrendingData";
import TrendingCard from "./TrendingCard";
import API from '../../Routes/api';

function Trending() {
  const [InterSideData,setSideData]               =   useState([])
  const [MyProjectData,setProjectData]               =   useState([])
  
    useEffect(() => {
        fetchTrendingProjects();
        fetchMyProjects();
    }, [])

    const fetchTrendingProjects=async()=>{
        await API.get('projects/trending').then(res=>{
            setSideData(res.data.data)  ;
        })
    }      
    const fetchMyProjects=async()=>{
      await API.get('projects').then(res=>{
          setProjectData(res.data.data)  ;
      })
  }      
  const cont = {
    padding: 12,
    backgroundColor: "#fff",
    margin: 10,
    marginLeft: 0,
    borderRadius: 10,
    width: "100%",
    height: "90%",
    paddingBottom: 40
  };
  const heading = {
    // fontFamily: "Montserrat",
    // fontStyle: "normal",
    // fontWeight: 800,
    // paddingBottom: 10,
    // fontSize: 25,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
}
  const p = {
    height: "90%",
  };
  return (
    <div style={cont}>
      <div >
        <h4 style={heading}>Trending Projects amongst your peers.</h4>
      </div>
      <div className="overflow-auto scroll" style={p}>
      {/* <Link to="/detail" style={{color: "#000" , textDecoration: "none"}}> */}
      {
        InterSideData.map((val) =>
        <TrendingCard 
            course_slug={val.course_slug}
            icon={val.banner_image_link}
            course={val.display_title}
            company_name={val.company_name}
        />
        )
      }
      {/* </Link> */}
      </div>
    </div>
  );
}

export default Trending;
