import React,{useEffect, useState} from 'react'
import Profile from './Profile'
import API from '../../Routes/api'


function ProfileMain(props) {
   
    return (
        <div>
            <Profile />
        </div>
    )
}




export default ProfileMain
