import React,{useEffect, useState, useCallback} from 'react'
import './Profile.css';
import Image from "../../Assets/user.png";
import Sidebar from './../Sidebar/Sidebar';
import API from '../../Routes/api';
import Swal from 'sweetalert2';
import PasswordModal from '../Modal/PasswordModal';
import MyEditor from './MyEditor';
import Cropper from 'react-easy-crop'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Croppie from 'croppie';
import getCroppedImg from './cropImage'
import jquery from 'jquery';

function Profile() {
  
    const [user,setUsers]               =   useState([])
    const [bank,setBank]                =   useState([])
    const [info,setInfo]                =   useState([])
    const [password_details,setPassword] =  useState([])
    const [profilepic, setProfilePic]   =   useState(null)
    const [imgData, setImgData]         =   useState(null);
    const [originalImage,setOriginalImage] = useState(null);
    const [crop, setCrop]               =   useState({ x: 0, y: 0 })
    const [initialCroppedAreaPixels,setInitialCroppedAreaPixels] = useState({})
    const [zoom, setZoom]               =   useState(1)
    const [modalShow, setModalShow]     =   useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage]           = useState(null)
    const [profileStatus,setProfileStatus]      = useState(1);
    const [passwordStatus,setPasswordStatus]    = useState(1);
    const [other_course,setOtherCourses]        = useState(false);
    const [other_gradyear,setOtherGradYear]     = useState(false);
    const [customZoom,setCustomZoom] = useState(1)
    const [uploadButtonFlag,setUploadButtonFlag] = useState('disabled');

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
        
    }, [])
        
    jquery('#cropComplete').on('click',function(){
        document.getElementById('is_cropped').value=1;
    })
    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imgData,
                croppedAreaPixels,
            )
            //console.log('donee', croppedImage)
            setCroppedImage(croppedImage)
            setModalShow(false)
            setUploadButtonFlag('')
        }catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    useEffect(() => {
        setInfo(initialOtherInfo);
        setBank(initialBankData);
        fetchUsers();
        checkIfImageExists();
    }, [])

    const checkIfImageExists = () =>{
        var ImageElement = document.getElementById('resizer-demo');
        if(ImageElement.src === null || ImageElement.src === undefined){
            ImageElement.src = Image;
        }
    }

    const defaultImage=async(e)=>{
        e.target.onerror = null;
        e.target.src = Image;
        e.target.style= "height: 27px; width: 60px; margin-right: 10px;margin-top:10px";
    }

    // useEffect(()=>{
    //     checkAndLoad()
    // })
    const checkAndLoad = () => {
        // console.log(croppedAreaPixels)
        // console.log(imgData)
        if(croppedAreaPixels!==null && imgData!==null){
            showCroppedImage();
            setUploadButtonFlag('')
        }
        
    }
    const onZoomChange  =(value) =>{
        var convertedZoomValue = (parseFloat(value)*1.5)/3
        setCustomZoom(convertedZoomValue)
        //console.log(value)
    }
    //console.log(customZoom)
    const fetchUsers=()=>{
        API.get('auth/user').then(res=>{
            setUsers(res.data.data[0]);
            setBank(res.data.data[0].bank_details?res.data.data[0].bank_details:initialBankData);
            setInfo(res.data.data[0].other_details?res.data.data[0].other_details:initialOtherInfo);
            setCroppedAreaPixels(res.data.data[0].other_details.picture_attributes)
            setImgData(res.data.data[0].other_details.original_picture_link);
            loadCroppedImage(res.data.data[0].other_details.profile_pic_link,JSON.parse(res.data.data[0].other_details.picture_attributes));             
            setPasswordStatus(res.data.data[0].other_details.password_status)
            setProfileStatus(res.data.data[0].other_details.profile_status)
            if(res.data.data[0].other_details.grad_subject === 'others'){
                setOtherCourses(true)
            }
            if(res.data.data[0].other_details.grad_year === 'others'){
                setOtherGradYear(true)
            }
        })
    }      

    const loadCroppedImage = (profile_pic,pixel_details)=>{
        if(profile_pic!=='' && profile_pic!== undefined && profile_pic!== null){
            //const croppedImage = await getCroppedImg(profile_pic,pixel_details);
            setCroppedImage(profile_pic)
            setUploadButtonFlag('');
        }else{
            document.getElementById('resizer-demo').setAttribute('src',Image)
            setUploadButtonFlag('disabled')
        }
        // console.log('done', { croppedImage })
        
        loadZoomAndCrop(pixel_details)        
    }

    const loadZoomAndCrop = async(pixel_details)  =>{
        if(pixel_details.zoom !== null && pixel_details.zoom !== undefined && pixel_details.zoom!==''){
            var calculatedPixel;
            if(pixel_details.zoom===0){
                calculatedPixel=1;
            }else{
                calculatedPixel=(pixel_details.zoom)*3;
                
            }
            setZoom(calculatedPixel)
        }
         
        if(pixel_details.x !== null && pixel_details.x !== undefined && pixel_details.x!=='' && pixel_details.y !== null && pixel_details.y !== undefined && pixel_details.y!==''){                      
            const cropCoordinates = {};           
            cropCoordinates.width = pixel_details.width;
            cropCoordinates.height = pixel_details.height;
            cropCoordinates.x = pixel_details.x; 
            cropCoordinates.y = pixel_details.y;    
            setInitialCropAreaLoadState(cropCoordinates)
        }
    }

    const setInitialCropAreaLoadState = (pixel_details) => {
        console.log(pixel_details)
        setInitialCroppedAreaPixels(pixel_details)
    }
  //console.log(crop)
    const initialFormData ={
        name                : "",
        email               : "",
        city                : "",
        college_name        : "",
        grad_subject        : "",
        grad_year           : "",
        whatsapp_number     : "",
        mobile              : "",
        gender              : "",
        bank_name           : "",
        accountholder_name  : "",
        account_number      : "",
        ifsc_code           : "",
        upi                 : "",
    };
    
    const initialOtherInfo ={
        city                :"",
        college_city        :"",
        college_id          :"",
        college_name        :"",
        created_at          :"",
        grad_subject        :"",
        grad_year           :"",
        id                  :"",
        is_ca               :"",
        is_mail_sent        :"",
        is_student          :"",
        linkedin_profile    :"",
        other_subject       :"",
        other_year          :"",
        popup_viewed        :"",
        profile_pic         :"",
        skills              :"",
        student_id          :"",
        updated_at          :"",
        user_id             :""
    };

    const initialBankData = {
        account_number: "",
        accountholder_name: "",
        bank_detail_id:  "",
        bank_name: "",
        id:  "",
        ifsc_code:  "",
        record_id:  "",
        record_type:  "",
        upi: ""
    }
    const [formData, updateFormData]    =   useState(initialFormData)

    const handleUser = (e) => {
        setUsers(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        );
    }
    const handleBank = (e) => {
        setBank(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        );
    }
    const handleInfo = (e) => {
        if(e.target.name === "grad_subject" && e.target.value ==="others"){
            setOtherCourses(true);
        }else if(e.target.name === "grad_subject" && e.target.value !=="others"){
            setOtherCourses(false);
        }
        if(e.target.name === "grad_year" && e.target.value ==="others"){
            setOtherGradYear(true);
        }else if(e.target.name === "grad_subject" && e.target.value !=="others"){
            setOtherGradYear(false);
        }
        setInfo(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        );

    }

    const handlePassword = (e)=>{
        setPassword(
            prevState => ({
                ...prevState,
                [e.target.name]:e.target.value
            })
        )
    }

    const handleImage = (e) =>{
        if (e.target.files[0]) {
            //console.log("picture: ", e.target.files);
            var profile_pic = URL.createObjectURL(e.target.files[0])
            setProfilePic(e.target.files[0])
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgData(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
            //setInitialAreaOnImgUpload();
            //console.log(reader)
            setUploadButtonFlag('')
        }
    }

    const setInitialAreaOnImgUpload = ()=>{
        const cropCoordinates = {};
        cropCoordinates.zoom = 1;
        cropCoordinates.width = '200px';
        cropCoordinates.height = '200px';
        cropCoordinates.x = 50; 
        cropCoordinates.y = 50;
        setInitialCroppedAreaPixels(cropCoordinates)
    }
    
    function navigate(href, newTab) {
        var a = document.createElement('a');
        a.href = href;
        if (newTab) {
           a.setAttribute('target', '_blank');
        }
        a.click();
    }
    
    const handleSubmit = async(e) => {
        //console.log(document.getElementById("resizer-demo"))
        e.preventDefault()
        if(validateUserInput()===true){
            user['bank_details']=bank;
            user['other_details']=info;
            let blob = await fetch(profilepic).then(r => r.blob());
            const formData = new FormData();
            formData.append("user", JSON.stringify(user));
            formData.append('image',document.getElementById("img").files[0]);
            formData.append('cropped_image',document.getElementById("resizer-demo").src);
            formData.append('is_cropped',document.getElementById('is_cropped').value);
            //var setzoom = {};
            console.log('customZoon '+customZoom)
            var setzoom = customZoom === 1 ? {zoom : (parseFloat(1)*1.5)/3 } : {zoom : customZoom };
            const cropCoordinates = {};           
            cropCoordinates.width = 42
            cropCoordinates.height = 42;
            cropCoordinates.x = 123 
            cropCoordinates.y = 29;
            var cropAreaObject = typeof croppedAreaPixels === 'object' && croppedAreaPixels !== null ? croppedAreaPixels : cropCoordinates;
            console.log('CAP '+cropAreaObject)
            console.log('Zoom '+ JSON.stringify(setzoom))
            let imageAttributeObject = Object.assign(setzoom,cropAreaObject)
            console.log(imageAttributeObject)
            formData.append('imageAttributes',JSON.stringify(imageAttributeObject))
            //formData.append('imageAttributes',JSON.stringify(croppedAreaPixels))
            API.post('auth/user/update',formData).then(res=>{
                if(res.data.code===200){
                    Swal.fire({
                        title: res.data.status,
                        text: res.data.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        var redirectToResume = localStorage.getItem('redirect_to_resume')
                        var redirectToResumeURL = localStorage.getItem('redirect_to_resume_url')
                        if(redirectToResume!==undefined && redirectToResume!== null && redirectToResume!==''){
                            navigate(redirectToResumeURL,true)
                            localStorage.removeItem('redirect_to_resume')
                            localStorage.removeItem('redirect_to_resume_url')
                        }else{
                            if(profileStatus===0){
                                window.location.href="/dashboard";
                            }else{
                                window.location.href="/profile";
                            }
                        }
                    })
                }
            }).catch(err=>{
                if(err.code==='ERR_BAD_REQUEST'){
                    Swal.fire({
                        title: err.response.data.status,
                        text: err.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                } 
            }) 
        }
        
    };

    const handlePasswordReset = (e) => {
        e.preventDefault();
        if(validatePasswordInput()===true){
            var param = passwordStatus === 0 ? '?password_status=1' : '';
            API.put('auth/user/password/update'+param,password_details).then(res=>{
                if(res.data.code===200){
                    Swal.fire({
                        title: res.data.status,
                        text: res.data.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if(res.data.data.userpass_redirect==='dashboard'){
                            window.location.href = "/dashboard"
                        }else{
                            window.location.reload();
                        }            
                    });
                }      
            }).catch(err=>{
                if(err.code==='ERR_BAD_REQUEST'){
                    Swal.fire({
                        title: err.response.data.status,
                        text: err.response.data.message,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                } 
            }); 
        }
    };

    function phonenumber(number) {
        var reg = /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/;
        if(reg.test(number) === false) {
          return false;
        }else {
          return true;
        }
    }
    function emailValidation(email){
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(email) === false){
            return false;
        }else{
            return true;
        }
    }
    const validateUserInput = () =>{
        var user_fullname = document.getElementById("user_fullname").value;
        var user_gender = document.getElementById("user_gender").value;
        var user_mobile = document.getElementById("user_mobile").value;
        var user_whatsapp = document.getElementById("user_whatsapp").value;
        var user_email = document.getElementById("user_email").value;
        var user_city = document.getElementById("user_city").value;
        var user_college = document.getElementById("user_college").value;
        var user_college_city = document.getElementById("user_college_city").value;
        var user_department_branch = document.getElementById("user_department_branch").value;
        var user_year_pursuing = document.getElementById("user_year_pursuing").value;
        var user_other_department_branch = document.getElementById("user_other_department_branch").value;
        var user_other_year_pursuing = document.getElementById("user_other_year_pursuing").value;
        var user_fullname_validation = false;
        var user_gender_validation = false;
        var user_mobile_validation = false;
        var user_whatsapp_validation = false;
        var user_email_validation = false;
        var user_city_validation = false;
        var user_college_validation = false;
        var user_college_city_validation = false;
        var user_department_branch_validation = false;
        var user_year_pursuing_validation = false;
        var user_other_department_branch_validation = false;
        var user_other_year_pursuing_validation = false;
        var validation = false;


        if(user_fullname === '' || user_fullname === null || user_fullname === undefined){
            document.getElementById("user_fullname_err").innerHTML = "Name can't be Empty!"
            user_fullname_validation = false;
        }else{
            document.getElementById("user_fullname_err").innerHTML = ''
            user_fullname_validation = true;
        }

        if(user_gender === '' || user_gender === null || user_gender === undefined || user_gender === "0"){
            document.getElementById("user_gender_err").innerHTML = "Gender can't be Empty!"
            user_gender_validation = false;
        }else{
            document.getElementById("user_gender_err").innerHTML = ''
            user_gender_validation = true;
        }

        if(user_mobile === '' || user_mobile === null || user_mobile === undefined){
            document.getElementById("user_mobile_err").innerHTML = "Mobile Number can't be Empty!"
            user_mobile_validation = false;
        }else if(user_mobile!=='' && phonenumber(user_mobile)===false){
            document.getElementById("user_mobile_err").innerHTML = "Invalid Mobile Number format"
            user_mobile_validation=false
        }else{
            document.getElementById("user_mobile_err").innerHTML = ''
            user_mobile_validation = true;
        }
        
        if(user_whatsapp === '' || user_whatsapp === null || user_whatsapp === undefined){
            document.getElementById("user_whatsapp_err").innerHTML = "Whatsapp Number can't be Empty!"
            user_whatsapp_validation = false;
        }else if(user_whatsapp!=='' && phonenumber(user_whatsapp)===false){
            document.getElementById("user_whatsapp_err").innerHTML = "Invalid Whatsapp Number format"
            user_whatsapp_validation=false
        }else{
            document.getElementById("user_whatsapp_err").innerHTML = ''
            user_whatsapp_validation = true;
        }

        if(user_email === '' || user_email === null || user_email === undefined){
            document.getElementById("user_email_err").innerHTML = "Email can't be Empty!"
            user_email_validation = false;
        }else  if(user_email!=='' && emailValidation(user_email)===false){
            document.getElementById("user_email_err").innerHTML = "Invalid Email format"
            user_email_validation=false
        }else{
            document.getElementById("user_email_err").innerHTML = ''
            user_email_validation = true;
        }
        
        if(user_city === '' || user_city === null || user_city === undefined){
            document.getElementById("user_city_err").innerHTML = "City can't be Empty!"
            user_city_validation = false;
        }else{
            document.getElementById("user_city_err").innerHTML = ''
            user_city_validation = true;
        }
        
        if(user_college === '' || user_college === null || user_college === undefined){
            document.getElementById("user_college_err").innerHTML = "College Name can't be Empty!"
            user_college_validation = false;
        }else{
            document.getElementById("user_college_err").innerHTML = ''
            user_college_validation = true;
        }

        if(user_college_city === '' || user_college_city === null || user_college_city === undefined){
            document.getElementById("user_college_city_err").innerHTML = "College City can't be Empty!"
            user_college_city_validation = false;
        }else{
            document.getElementById("user_college_city_err").innerHTML = ''
            user_college_city_validation = true;
        }

        if(user_department_branch === '' || user_department_branch === null || user_department_branch === undefined){
            document.getElementById("user_department_branch_err").innerHTML = "Branch/Department can't be Empty!"
            user_department_branch_validation = false;
        }else{
            document.getElementById("user_department_branch_err").innerHTML = ''
            user_department_branch_validation = true
        }

        if(user_year_pursuing === '' || user_year_pursuing === null || user_year_pursuing === undefined){
            document.getElementById("user_year_pursuing_err").innerHTML = "Year Pursuing can't be Empty"
            user_year_pursuing_validation = false;
        }else{
            document.getElementById("user_year_pursuing_err").innerHTML = ''
            user_year_pursuing_validation = true
        }

        if(user_department_branch === 'others' && (user_other_department_branch==='' || user_other_department_branch === null || user_other_department_branch === undefined)){
            document.getElementById("user_other_department_branch_err").innerHTML = "Other Branch/Department can't be Empty!"
            user_other_department_branch_validation = false;
        }else{
            document.getElementById("user_other_department_branch_err").innerHTML = ''
            user_other_department_branch_validation = true
        }

        if(user_year_pursuing === 'others' && (user_other_year_pursuing ==='' || user_other_year_pursuing === null || user_other_year_pursuing === undefined)){
            document.getElementById("user_other_year_pursuing_err").innerHTML = "Other Year Pursuing can't be Empty"
            user_other_year_pursuing_validation = false;
        }else{
            document.getElementById("user_other_year_pursuing_err").innerHTML = ''
            user_other_year_pursuing_validation = true
        }

        if(user_fullname_validation === true && user_gender_validation === true && user_mobile_validation === true && user_whatsapp_validation === true && user_email_validation === true && user_city_validation === true && user_college_validation === true && user_college_city_validation === true && user_department_branch_validation === true && user_year_pursuing_validation === true && user_other_department_branch_validation === true && user_other_year_pursuing_validation===true){
            validation = true;
        }else{
            validation = false;
        }
        return validation;
    }
    function validatePassword(passwordparam) {
        var length_err = true;
        var letter_err = true;
        var digit_err = true;
        if (passwordparam.length < 8) {
            return (["false","Your password must be at least 8 characters"]);
            length_err = false
        }
        if (passwordparam.search(/[a-z]/i) < 0) {
            return (["false","Your password must contain at least one letter."]); 
            letter_err = false;
        }
        if (passwordparam.search(/[0-9]/) < 0) {
            return (["false","Your password must contain at least one digit."]);
            digit_err = false
        }
        if (length_err===true &&length_err===true && digit_err===true) {  
            return (["true",""]);
        }
    }

    const validatePasswordInput = () =>{
        //console.log('triggered')
        var old_password = document.getElementById("old_password").value;
        var new_password = document.getElementById("new_password").value;
        var confirm_password = document.getElementById("confirm_password").value;
        var old_password_validation = false;
        var new_password_validation = false;
        var confirm_password_validation = false;
        var validation = false;

        if(old_password === '' || old_password === null || old_password === undefined){
            document.getElementById("old_password_err").innerHTML = "Old Password can't be Empty";
            old_password_validation = false;
        }else{
            document.getElementById("old_password_err").innerHTML = "";
            old_password_validation = true;
        }

        if(new_password === '' || new_password === null || new_password === undefined){
            document.getElementById("new_password_err").innerHTML = "Password can't be Empty, Please Enter a Password";
            new_password_validation = false;
        }else if (new_password !== ''){
            var passwordValidationResponse = validatePassword(new_password)
            //console.log(passwordValidationResponse[0],passwordValidationResponse[1])
            if(passwordValidationResponse[0] === "false"){
                document.getElementById("new_password_err").innerHTML = passwordValidationResponse[1];
                new_password_validation = false                
            }else{
                document.getElementById("new_password_err").innerHTML = "";
                new_password_validation = true;
            }
        }

        if(new_password !== '' && (confirm_password === '' || confirm_password === null || confirm_password === undefined)){
            document.getElementById("confirm_password_err").innerHTML = "Please Confirm your Password";
            confirm_password_validation = false;
        }else {
            if(confirm_password!== '' && confirm_password !== new_password){
                document.getElementById("confirm_password_err").innerHTML = "Your new password and the confirmed password should match";
                confirm_password_validation = false;
            }else{
                document.getElementById("confirm_password_err").innerHTML = "";
                confirm_password_validation = true;
            }
        }
        // console.log('old_password '+old_password)
        // console.log('new_password '+new_password)
        // console.log('confirm_password '+confirm_password)
        // console.log('old_password_validation '+old_password_validation)
        // console.log('new_password_validation '+new_password_validation)
        // console.log('confirm_password_validation '+confirm_password_validation)
        // console.log('validation '+validation)

        if(old_password_validation === true && new_password_validation === true && confirm_password_validation === true){
            validation = true;
        }else{
            validation = false;
        }
        return validation;
    }

    const heading={
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        paddingTop: 20
      };
      const cont = {
        
        backgroundColor: "#fff",
        margin: 10,
        marginLeft: 0,
        borderRadius: 10,
        width: "100%",
      };
      const form = {
          padding:12,  
      }
      const btn ={
          marginTop: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
      }
    return (
        <div>
            <div className="row">
                <div className="col-2 col-xs-1 side" >
                    <Sidebar/>
                </div>
                <div className="col-10 col-xs-11">
                    <h4 style={heading}>Profile</h4>
                    <div style={cont} id="profile">
                        <form action="" method="post" id="profile-edit-form"  className="standard-form profile-info" novalidate enctype="multipart/form-data" onSubmit={handleSubmit}>
                            <div className="row" >
                                <div className="col-sm-auto col-md-3 " id="nav">
                                    <ul className="button-nav clearfix" aria-label="Profile field groups" role="navigation">
                                        <li className="current"><a href="#" onClick={window['profile']}>Edit Profile</a></li>
                                    </ul>
                                    <ul className="button-nav-n clearfix" aria-label="Profile field groups" role="navigation">
                                        <li className="current"><a href="#" id="password_panel" onClick={window['pass']}>Change Password</a></li>
                                    </ul>
                                </div> 
                                <div className="col-md-9" style={form} >
                                    <input type="hidden" id="is_cropped"/>
                                    <div className="card card-static" style={{paddingLeft:'10%',paddingRight:'10%',paddingTop:'20px',paddingBottom:'20px'}}>                    
                                        <img src={croppedImage===null?imgData:croppedImage} className="img-fluid" style={{alignSelf:'center',justifyContent:'center',padding:'10px',height:'150px',width:'150px',borderRadius:'75px'}} alt="logo" height='80' width='200' id="resizer-demo" onError={defaultImage}/>                                      
                                        <label style={{alignSelf:'center',justifyContent:'center'}} className="profile"></label><br />
                                        <div className='row'>
                                            <div className="col-md-6 float-end">
                                                <input type="file" id="img" onChange={handleImage} accept="image/*"/>    
                                            </div>
                                            <div className="col-md-6 float-start">
                                                <Button size="sm" style={{width:'100px'}} disabled={uploadButtonFlag} onClick={() => setModalShow(true)}>Resize Image</Button>
                                            </div>                                                                                       
                                        </div>
                                        <div className="row">
                                            <h4 style={{color:'#000'}}>Basic Info</h4>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">Name<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_fullname" placeholder="Full Name" value={user.name}  name="name"  onChange={handleUser} />
                                                        
                                                <div className="text-danger" id="user_fullname_err"></div>
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">Gender<span style={{color: "red"}}>*</span></label>
                                                    <select  className="form-control" id="user_gender" value={user.gender} name="gender"  onChange={handleUser} >
                                                    <option value="0">Please choose your gender</option>
                                                    <option value="1" >Male</option>
                                                    <option value="2" >Female </option>
                                                    <option value="3" >Others</option>
                                                </select>
                                                <div className="text-danger" id="user_gender_err"></div>
                                                
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">Phone Number<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_mobile" placeholder="Phone number" value={user.mobile} name="mobile" onChange={handleUser} />
                                                <div className="text-danger" id="user_mobile_err"></div>
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">Whatsapp Number<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_whatsapp" placeholder="Whatsapp" value={user.whatsapp_number} name="whatsapp_number" onChange={handleUser} />
                                                <div className="text-danger" id="user_whatsapp_err"></div>
                                                
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">Email Id<span style={{color: "red"}}>*</span></label>
                                                <input type="email" className="form-control" id="user_email" placeholder="Email" value={user.email} name="email" onChange={handleUser} disabled/>
                                                <div className="text-danger" id="user_email_err"></div>
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">LinkedIn</label>
                                                <input type="text" className="form-control" id="user_linkedin" placeholder="LinkedIn" value={info.linkedin_profile} name="linkedin_profile" onChange={handleInfo} />
                                            </div>
                                        </div>
                    
                                        <h4>Bank Account Details <span style={{fontSize: 15}}> (Applicable in case if you are earning the referral fee)</span></h4>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">Name as per bank</label>
                                                <input type="text" className="form-control" id="user_name_as_per_bank" placeholder="Name as per bank" value={bank.accountholder_name} name="accountholder_name" onChange={handleBank} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">Bank Name</label>
                                                <input type="text" className="form-control" id="inputPassword4" placeholder="Bank Name" value={bank.bank_name} name="bank_name" onChange={handleBank} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">Account Number</label>
                                                <input type="text" className="form-control" id="inputEmail4" placeholder="Account Number" value={bank.account_number} name="account_number" onChange={handleBank} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">IFSC Code</label>
                                                <input type="text" className="form-control" id="inputPassword4" placeholder="IFSC" value={bank.ifsc_code} name="ifsc_code" onChange={handleBank} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">UPI</label>
                                                <input type="text" className="form-control" id="inputEmail4" placeholder="UPI" value={bank.upi} name="upi" onChange={handleBank} />
                                            </div>
                                        </div>
                                        <h4>Info</h4>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">City(Hometown)<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_city" placeholder="Your City" value={info.city} name="city" onChange={handleInfo} />
                                                <div className="text-danger" id="user_city_err"></div>
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">College<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_college" placeholder="College Name" value={info.college_name} name="college_name" onChange={handleInfo} />
                                                <div className="text-danger" id="user_college_err"></div>
                                                
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">Your Skills</label>
                                                <input type="text" className="form-control" id="user_skills" placeholder="Skills you have" value={info.skills} name="skills" onChange={handleInfo} />
                                                
                                                
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">College city<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_college_city" placeholder="College city" value={info.college_city} name="college_city" onChange={handleInfo} />                                                
                                                <div className="text-danger" id="user_college_city_err"></div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">Department/Branch<span style={{color: "red"}}>*</span></label>
                                                <select  className="form-control" id="user_department_branch" value={info.grad_subject} name="grad_subject" onChange={handleInfo} >
                                                    <option >--Select Course --</option>
                                                    <option value="BBA" >BBA</option>
                                                    <option value="MBA" >MBA </option>
                                                    <option value="MCA" >MCA</option>
                                                    <option value="BCA" >BCA </option>
                                                    <option value="LLB" >LLB </option>
                                                    <option value="BA" >BA </option>
                                                    <option value="Polytechnic" >Polytechnic</option>
                                                    <option value="B.E." >B.E.</option>
                                                    <option value="B.Sc" >B.Sc</option>
                                                    <option value="B.Tech" >B.Tech</option>
                                                    <option value="M.Tech" >M.Tech</option>
                                                    <option value="B.Com" >B.Com</option>
                                                    <option value="Others" >Others</option>
                                                </select>
                                                <div className="text-danger" id="user_department_branch_err"></div>
                                            </div>
                                            
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">Year Pursuing<span style={{color: "red"}}>*</span></label>
                                                <select  className="form-control" id="user_year_pursuing" value={info.grad_year} name="grad_year" onChange={handleInfo}  >
                                                    <option >--Select Year --</option>
                                                    <option value="1st" >1st</option>
                                                    <option value="2nd " >2nd </option>
                                                    <option value="3rd" >3rd</option>
                                                    <option value="4th" >4th </option>
                                                    <option value="5th" >5th </option>                                            
                                                    <option value="Others" >Others</option>
                                                </select>
                                                <div className="text-danger" id="user_year_pursuing_err"></div>
                                            </div>                                               
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-md-6" style={{display : other_course === true ? 'block':'none' }}>
                                                <label for="inputEmail4">Other Department/Branch<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_other_department_branch" placeholder="Other Department" value={info.other_subject} name="other_subject" onChange={handleInfo} />
                                                <div className="text-danger" id="user_other_department_branch_err"></div>
                                            </div>      
                                            <div className="form-group col-md-6" style={{display : other_gradyear === true ? 'block':'none' }}>
                                                <label for="inputPassword4">Other Year Pursuing<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_other_year_pursuing" placeholder="Other Year Pursuing" value={info.other_year} name="other_year" onChange={handleInfo} />
                                                <div className="text-danger" id="user_other_year_pursuing_err"></div>
                                            </div>                                   
                                        </div>
                                        {/* <div className="row">
                                            <div className="form-group col-md-6">
                                                <label for="inputEmail4">Department/Branch<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_department_branch" placeholder="Department" value={info.grad_subject} name="grad_subject" onChange={handleInfo} />
                                                <div className="text-danger" id="user_department_branch_err"></div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label for="inputPassword4">Year Pursuing<span style={{color: "red"}}>*</span></label>
                                                <input type="text" className="form-control" id="user_year_pursuing" placeholder="Year" value={info.grad_year} name="grad_year" onChange={handleInfo} />
                                                <div className="text-danger" id="user_year_pursuing_err"></div>
                                            </div>
                                        </div> */}
                                        <button type="submit" className="btn btn-primary" onClick={handleSubmit} style={btn}>Submit</button>
                                    </div>
                                </div>
                            </div> 
                        </form>        
                    </div>
                    <div style={cont} id="pass">    
                        <div className="row">
                            <div className="col-sm-auto col-md-3 " id="nav">
                                <ul className="button-nav-n clearfix" aria-label="Profile field groups" role="navigation">
                                    <li className="current"><a href="#" onClick={window['profile']} >Edit Profile</a></li>                                        
                                </ul>
                                <ul className="button-nav clearfix" aria-label="Profile field groups" role="navigation">
                                    <li className="current"><a href="#" onClick={window['pass']}>Change Password</a></li>                                        
                                </ul>
                            </div> 
                            <div className="col-md-9" style={form}>
                                <div className="card card-static" style={{paddingLeft:'10%',paddingRight:'10%',paddingTop:'20px',paddingBottom:'20px'}}>                                        
                                    {/* <img src={Image} className="img-fluid" style={{alignSelf:'center',justifyContent:'center',padding:'10px',height:'150px',width:'130px'}} alt="logo" height='80' width='200' /> */}
                                    <div className="row">
                                        <h4 style={{color:'#000'}}>Change Password</h4>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputAddress">Old Password</label>
                                        <input type="password" className="form-control" id="old_password" name="old_password" onChange={handlePassword} />
                                        <div className="text-danger" id="old_password_err"></div>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputAddress">New Password</label>
                                        <input type="password" className="form-control" id="new_password"  name="password" onChange={handlePassword}/>
                                        <div className="text-danger" id="new_password_err"></div>
                                    </div>
                                    <div className="form-group">
                                        <label for="inputAddress">Confirm Password</label>
                                        <input type="password" className="form-control" id="confirm_password" name="password_confirmation" onChange={handlePassword} />
                                        <div className="text-danger" id="confirm_password_err"></div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-md-6 d-flex justify-content-end">
                                            <button type="submit" className="btn btn-primary" style={btn}>Cancel</button>
                                        </div>
                                        <div className="col-md-6">
                                            <button type="submit" className="btn btn-primary" style={btn} onClick={handlePasswordReset}>Change Password</button>
                                        </div>
                                    </div>                                                                
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
                <PasswordModal/>
                <Modal show={modalShow} onHide={() => setModalShow(false)} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>Resize Image</Modal.Header>
                    <Modal.Body>
                        <div className="crop-container">
                            <Cropper
                            initialCroppedAreaPixels={initialCroppedAreaPixels}
                            image={imgData}
                            crop={crop}
                            zoom={zoom}
                            cropShape='round'
                            aspect={1 / 1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            />
                        </div>
                        <div className="controls">
                            <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                onZoomChange(e.target.value)
                                setZoom(e.target.value)
                            }}
                            className="zoom-range"
                            />
                        </div>
                    </Modal.Body>
                    <div className="d-flex justify-content-center text-center">
                        <Modal.Footer>
                            <Button onClick={showCroppedImage} id="cropComplete">Save</Button>
                        </Modal.Footer>
                    </div>
                </Modal>                
            </div>
        </div>
    
    );
}

export default Profile
