import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from './Components/Login_Signup/Login';
import Signup from './Components/Login_Signup/Signup';
import Navbar from './Components/Navbar/Navbar';
import DashboardMain from "./Components/Dashboard/DashboardMain"
import ProjectMain from './Components/Project/ProjectMain';
import WorkshopMain from './Components/Workshop/WorkshopMain';
import ResumeMain from './Components/Resume/ResumeMain';
import JobMain from './Components/Job/JobMain';
import Cart from './Components/NavPages/Cart';
import Notification from './Components/NavPages/Notification';
import Message from './Components/NavPages/Message';
import DetailMain from './Components/Details/DetailMain';
import QueriesMain from './Components/Queries/QueriesMain';
import InternMain from './Components/Internship/InternMain';
import InterProjectMain from './Components/Internship/InternProject/InterProjectMain';
import InternSide from './Components/Internship/InternSide';
import ProfileMain from './Components/Profile/ProfileMain';
import Refferal from './Components/Refferal/RefferalMain';
import CertificateMain from './Components/Internship/InternProject/CertificateMain';
import Transaction from './Components/Profile/Transaction';
import TransactionMain from './Components/Profile/TransactionMain';
import ForgotPass from './Components/Login_Signup/ForgotPass';
import LmsMain from './Components/LMS/LmsMain';
import ProcessMain from './Components/Process/ProcessMain';
import StartModal from './Components/Modal/StartModal';
import TeleModal from './Components/Modal/TeleModal';
import { handelRightClick } from './Util/Helper';

function App() {
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <div className="App">
     <Router>
        <Navbar />
        <Switch>
        {/* <Route exact path="/" component={Login} />
        <Route exact path="/forgot" component={ForgotPass} /> */}
        <Route exact path="/" component={DashboardMain} />
          <Route exact path="/dashboard" component={DashboardMain} />
          <Route exact path="/projects" component={ProjectMain} />
          <Route exact path="/workshop" component={WorkshopMain} />
          <Route exact path="/resume"  component={ResumeMain} />
          <Route exact path="/job" component={JobMain} />
          <Route exact path="/profile" component={ProfileMain}/>
          <Route exact path="/notification" component={Notification}/>
          <Route exact path="/cart" component={Cart}/>
          <Route exact path="/message" component={Message}/>
          <Route exact path="/detail" component={DetailMain}/>
          <Route exact path="/queries" component={QueriesMain}/>
          <Route exact path="/intern" component={InternMain}/>
          <Route exact path="/internproject" component={InterProjectMain}/>
          <Route exact path="/referral" component={Refferal}/>
          <Route exact path="/lms" component={LmsMain}/>
          <Route exact path="/certificate" component={CertificateMain }/>
          <Route exact path="/transaction" component={TransactionMain }/>
          <Route exact path="/processLogin" component={ProcessMain}/>
          <Route exact path="/start" component={StartModal }/>
          <Route exact path="/tele" component={TeleModal }/>


        </Switch>
      </Router> 
      {/* <Signup /> */}
      {/* <Login /> */}
      {/* <DetailMain /> */}
    </div>
  );
}

export default App;
