import React from 'react'
import Sidebar from "./../Sidebar/Sidebar";
import CartDetails from './CartDetails';

function Cart() {
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    paddingTop: 20,
  };
  return (
    <div className="row">
      <div className="col-2 col-xs-1 side">
        <Sidebar />
      </div>
      <div className="col-10 col-xs-11">
        <h4 style={heading}>Shopping Cart</h4>
        <CartDetails />
      </div>
    </div>
  );
}

export default Cart
