import React from 'react'
import LoaderMain from '../Loader/LoaderMain';
import Sidebar from '../Sidebar/Sidebar'
import InternDrop from './InternDrop';
import InternInprogress from './InternInprogress';
import InternTab from './InternTab';

function InternMain() {
    
    return (
      <div className="row p-0">
        <div className="col-2 col-lg-2 col-md-1 col-xs-1 side" >
          <Sidebar />
        </div>
        {/* <InternTab /> */}
        <div className="col-10 col-lg-10 col-md-11 col-xs-11">
          <InternInprogress />
        </div>
        {/* <LoaderMain /> */}
      </div>
    
      
    )
}

export default InternMain

