import React from "react";
import QueriesDashboard from "../Queries/QueriesDashboard";
import Stats from "./Stats";
import Player from "../Extra/VideoPlayer"
import VideoPlayer from "../Extra/VideoPlayer";

function RowTwo() {
  return (
    <div className="row m-0">
      <div className="col-md-12">
        {/* <Stats /> */}
        {/* <Player /> */}
        <VideoPlayer />

      </div>
      <div className="col-md-12" style={{marginTop: 15, marginBottom: 10}}>
        <QueriesDashboard />
      </div>
    </div>
  );
}

export default RowTwo;
