import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import ScheduleDrop from "./ScheduleDrop";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import Refund from "../../Assets/Refund and Cancellation Policy-Inmovidu.pdf";
import ScheduleTable from "./ScheduleTable";
import API from '../../Routes/api';
import moment from 'moment';
import { Table } from 'react-bootstrap'

function Calander() {
  const [value, onChange]                 = useState(new Date());
  const [batch,setBatch]                  = useState([])
  const [schedule,setSchedule]            = useState([])
  const [selectedBatch,setSelectedBatch]  = useState();
  useEffect(() => {
    fetchMyBatches();
  }, [])

  const classStatus = (status,link,classDate) => {
    var text = '';
    switch(status){
      case 0  : //if(classDate)
                let today = getToday();
                let classDay = classDate.split(/\s+/);              
                text = today===classDay[0]?"<a href='"+link+"' target='_blank' rel='noreferrer' style='color:blue'>Join</a>":"<div class='muted' style='cursor:pointer' data-toggle='tolltip' title='Link will be enabled on the Scheduled Date'>Join</a>";
                break;
      case 1  : text = "Completed";
                break;
      case 2  : text = "Cancelled"; 
                break;
      default : text = "TBD";
    }
    return text;
  }

  const getToday = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' +mm + '-' +dd;
    return today;
  }
  const handleDomainClassChange = async (e)=>{
      fetchScheduleFromBatch(e.target.value);
      setSelectedBatch(e.target.value);
  }

  const fetchMyBatches=async()=>{
    await API.get('schedule/batches').then(res=>{
        setBatch(res.data.data.batches)  ;
        fetchScheduleFromBatch(res.data.data.first_batch_id);
        setSelectedBatch(res.data.data.first_batch_id)
    })
  }     
  
  const fetchScheduleFromBatch = async(id) => {
    await API.get('schedules?batch_id='+id).then(res=>{
      setSchedule(res.data.data)  ;
    })
  }
 
  const cont = {
    // padding: 10,
    backgroundColor: "#f8f9fa",
    height: "80%",
    borderRadius: 10,
    width: "100%",
    marginBottom: 10,
  };
  const heading = {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 10
    // marginBottom: 30,
  };
  const calander = {
    height: "90%",
    marginBottom: 30,
  };
  const cari = {
    padding: "20px",
    fontWeight: 600,
  };
  const zoom = {
    padding: "20px",
    // textDecoration: "underline",
    fontWeight: 800,
  };
  const headStyle ={
    backgroundColor: "#3E7DC7",
    padding: "5px 5px 5px 5px",
    color: "#fff",
    borderRadius: 10,
    

}
  const innerContain={
    padding: 10
  }

  return (
    <div style={cont}>
      <div className="row m-0 p-0">
        <div className="col-md-12 ">
        <div style={headStyle}>
          <h4 style={heading}>My Calander</h4>
        </div>
        </div>
      </div>
      <div style={innerContain}>
      <div className="row m-0 p-0">
        <div className="col-md-7">
          <div style={{ marginBottom: 30 }}>
          <Box sx={{ minWidth: 80 ,maxWidth: 250, marginBottom: 1 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Domain
              </InputLabel>
              <NativeSelect
                defaultValue={30}
                inputProps={{
                  name: 'mentor',
                  id: 'uncontrolled-native',                  
                }}
                onChange={handleDomainClassChange}
                value={selectedBatch}
              >
                <option value="0">Select Batch</option>
              {
                batch.map((val)=>
                <option value={val.id}>{val.title}</option>
                )
              }
                
              </NativeSelect>
            </FormControl>
          </Box>
          </div>
        </div>
        {/* <div className="col-md-5 d-flex">
          <div>
            Curriculum{" "}
            <Link to={Refund} target="_blank" style={{ marginLeft: "10px" }}>
              <DownloadRoundedIcon />
            </Link>
          </div>
        </div> */}
      </div>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Date & Time / Username:password</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {
          schedule?.length >0?
          schedule.map((val)=>{
            return(
              <tr>
                <td style={{"border":"none"}} >{moment(val.class_datetime).format('DD/MM/YYYY h:mm A')} <br/> Username : {val.username} <br/> Password : {val.password}</td>
                <td style={{"border":"none"}} >
                <div dangerouslySetInnerHTML={{__html : classStatus(val.class_status,val.class_link,val.class_datetime)}} />
                </td>
              </tr>
            )})
            :
            <tr >
              <td colSpan={2}>'Your Schedule will appear here'</td>
            </tr>
        }
        </tbody>
      </Table>
      </div>
      <div></div>
    </div>

  );
}

export default Calander;
